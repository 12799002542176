import { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Alert, AlertIcon, AlertTitle, AlertDescription, Skeleton, Box, Button, useDisclosure } from '@chakra-ui/react';
import Tree from 'components/react-tree/Tree';
import cloneDeep from 'lodash/cloneDeep';
import { useIntl, FormattedMessage } from 'react-intl';
import styled from '@emotion/styled/macro';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import {
    findPortfolioByPropertiesId,
    filterNodesByName,
    getPortfolios,
    updatePortfolio,
    updateNode,
    renameNode,
    updateNodeIsMaster,
    updateNodeCoords,
    updateNodeOwnshershipPercentage,
    deletePortfolio,
    removeItemFromPortfolio,
    removeNodeByParentId,
    replaceNodeProperties,
    createPortfolio,
    movePortfolioToPortfolio,
    getNodeAncestors,
    findCopiedNode,
    removeNodesFromTree,
    extentMap,
    getChildPortfolioNodeIds,
    getNodeSubTree,
} from '../services/portfolios';
import { downloadPortfolioFlatFiles } from '../services/restorationInputExtract';
import useCommonToast from '../hooks/useCommonToast';
import { onHasUnsavedChanges } from '../store/helpers/helpersSlice';

import Portfolio from '../components/portfolios/Portfolio';
import PortfolioHoverLabelIcons from '../components/portfolios/PortfolioHoverLabelIcons';
import PortfolioItemHoverLabelIcons from '../components/portfolios/PortfolioItemHoverLabelIcons';
import PortfolioTreeWrapper from '../components/portfolios/PortfolioTreeWrapper';
import PortfolioItem from '../components/portfolios/PortfolioItem';
import PortfolioMap from '../components/portfolios/PortfolioMap';
import MovePortfolioModal from '../components/portfolios/MovePortfolioModal';
import CopyPortfolioModal from '../components/portfolios/CopyPortfolioModal';
import AddItemsPortfolioModal from '../components/portfolios/AddItemsPortfolioModal';
import PortfolioNodeIcon from '../components/portfolios/PortfolioNodeIcon';
import PortfolioArrowIcon from '../components/portfolios/PortfolioArrowIcon';

import ConfirmationModal from '../components/modal/ConfirmationModal';

import LayoutWrapper from 'components/layout/LayoutWrapper';
import LayoutSidebar from 'components/layout/LayoutSidebar';
import WrapperEmptyState from 'components/layout/WrapperEmptyState';

import FilteredWrapper from '../components/tree/FilteredWrapper';

import portfolioPlaceholder from '../images/portfolio-unselected.png';

const Portfolios = () => {
    const addModal = useDisclosure();
    const moveModal = useDisclosure();
    const deleteModal = useDisclosure();
    const removeItemModal = useDisclosure();
    const copyModal = useDisclosure();
    const confirmationModal = useDisclosure();

    const { toast } = useCommonToast();
    const intl = useIntl();
    const { search } = useLocation();
    const dispatch = useDispatch();

    const mapProps = useRef();
    const afterDiscardHandler = useRef();

    const isSidebarVisible = useSelector((state) => state.helpers.isSidebarVisible);
    const itemTypes = useSelector((state) => state.item.itemTypes);
    const hasUnsavedChanges = useSelector((state) => state.helpers.hasUnsavedChanges);
    const canUseRestoration = useSelector((state) => state.helpers.canUseRestoration);

    const [isDisabled, setIsDisabled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [loadingError, setLoadingError] = useState(false);
    const [portfolios, setPortfolios] = useState([]);
    const [metadata, setMetadata] = useState({
        books: [],
        portfolioTypes: [],
        dispatchTypes: [],
    });
    const [filteredData, setFilteredData] = useState([]);
    const [selected, setSelected] = useState(null);
    const [expanded, setExpanded] = useState([]);
    const [focused, setFocused] = useState(null);
    const [searchName, setSearchName] = useState('');
    const [temporaryCopyNode, setTemporaryCopyNode] = useState(null);

    const [temporaryNode, setTemporaryNode] = useState(null);
    const [portfoliosBeforeMove, setPortfoliosBeforeMove] = useState([]);
    const [mapExpanded, setMapExpanded] = useState(false);
    const [nextNode, setNextNode] = useState(null); // next node on unsaved changes flow

    const query = new URLSearchParams(search);
    const portfolioIdFromUrlParam = query.get('portfolioId');

    useEffect(() => {
        setIsLoading(true);

        getPortfolios()
            .then((data) => {
                const portfolioNodes = data?.portfolios;
                const portfoliosLength = portfolioNodes?.length;
                const topExpandedArray = portfolioNodes?.map((node) => node.id);
                setPortfolios(portfolioNodes);
                setFilteredData(portfolioNodes);
                setMetadata(data.metadata);
                setFocused(portfoliosLength > 0 ? portfolioNodes[0].id : null);
                setExpanded(topExpandedArray);
                // select top root portfolio
                setSelected(portfoliosLength > 0 ? portfolioNodes[0] : null);

                // check if we have a portfolio passed from dashboard study in query string param and select if found
                const isNumber = !isNaN(Number(portfolioIdFromUrlParam));
                if (!portfolioIdFromUrlParam || !portfoliosLength || !isNumber) return;

                const parsePortfolioId = Number.parseFloat(portfolioIdFromUrlParam);
                const targetNode = findPortfolioByPropertiesId(portfolioNodes, parsePortfolioId);

                if (targetNode) {
                    const ancestorsIds = getNodeAncestors(portfolioNodes, targetNode);
                    const ancestorsExpandedArray = ancestorsIds || [];
                    const childrenExpandedArray = targetNode?.nodes?.map((node) => node.id) || [];
                    const expand = [...ancestorsExpandedArray, targetNode?.id, ...childrenExpandedArray];

                    setFocused(targetNode?.id);
                    setExpanded(expand);
                    // select portfolio from url param
                    setSelected(targetNode);
                } else if (isNumber && !targetNode) {
                    //Portfolio Id is a valid number but the node was not found in portfolios tree
                    toast({
                        status: 'error',
                        message: intl.formatMessage(
                            { id: 'portfolios_portfolio_id_not_found_error' },
                            { id: parsePortfolioId }
                        ),
                    });
                }
            })
            .catch(() => setLoadingError(true))
            .finally(() => setIsLoading(false));
    }, [intl, portfolioIdFromUrlParam, toast]);

    useEffect(() => {
        const term = searchName.trimStart().toLowerCase();

        if (term && term.length >= 2) {
            const data = filterNodesByName(portfolios, term);
            const ids = getChildPortfolioNodeIds(data);

            setFilteredData(data);
            setFocused(data.length > 0 ? data[0].id : null);
            setExpanded(ids);
        } else {
            setFilteredData(portfolios);
            setFocused(portfolios.length > 0 ? portfolios[0].id : null);
            setExpanded(portfolios.map((node) => node.id));
        }
    }, [searchName]); // eslint-disable-line

    useEffect(() => {
        if (selected?.properties.parentId || selected?.properties.portfolioId) {
            setMapExpanded(false);
        } else {
            setMapExpanded(true);
        }
    }, [selected]);

    const onSelect = useCallback((node) => {
        if (node && (node.isFolder || node.nodes.length === 0)) {
            setSelected(node);
        }
    }, []);

    const onFormSubmit = async (values) => {
        // Chakra's toast is probably portalled outside of IntlProvider so we have to
        // format the message outside the toast's `render` method.
        const successMessage = intl.formatMessage({ id: 'portfolios_save_changes_success' });

        setIsDisabled(true);

        try {
            const valuesToUpdate = {
                name: values.name,
                description: values.description,
                portfolioTypeId: values.portfolioTypeId,
                dispatchTypeId: values.dispatchTypeId,
                properties: values.updatedProperties,
                bookId: values.bookId,
                isMaster: values.isMaster, // needed for the sidebar updates
            };

            await updatePortfolio(selected.properties.id, {
                ...valuesToUpdate,

                parentId: selected.properties.parentId,
                locationId: selected.properties.locationId,
                generationTypeId: selected.properties.generationTypeId,
                securityId: values.isMaster, // the BE expects securityId property instead of isMaster
            });

            setPortfolios((prev) => updateNode(prev, selected.properties.id, valuesToUpdate));
            setFilteredData((prev) => updateNode(prev, selected.properties.id, valuesToUpdate));

            toast(successMessage);
        } finally {
            setIsDisabled(false);
        }
    };

    const onDownload = useCallback(
        async (node) => {
            try {
                await downloadPortfolioFlatFiles(node.properties.id);

                toast({
                    message: intl.formatMessage({ id: 'portfolios_download_flat_files_success' }),
                });
            } catch (error) {
                if (error.response.status !== 401) {
                    toast({
                        status: 'error',
                        message: error.response.data.error,
                    });
                }
            }
        },
        [intl, toast]
    );

    const secureChangingSelectedNode = useCallback(
        (node) => {
            const isExpandable = node.nodes.length > 0;
            const canBeSelected = node.isFolder || !isExpandable;
            let result = false;

            if (canBeSelected) {
                if (hasUnsavedChanges && selected !== node) {
                    setNextNode(node);
                    confirmationModal.onOpen();
                } else {
                    setFocused(node.id);
                    setSelected(node);
                    setNextNode(null);
                    result = true;
                }
            }

            return result;
        },
        [confirmationModal, hasUnsavedChanges, selected]
    );

    const onCopyModalOpen = useCallback(
        async (node) => {
            setTemporaryCopyNode(node);
            copyModal.onOpen();
        },
        [] // eslint-disable-line
    );

    const onCopySuccess = useCallback(
        async (mappedCopies) => {
            copyModal.onClose();
            setTemporaryCopyNode(null);

            const copiedNode = findCopiedNode(portfolios, mappedCopies);

            setPortfolios(mappedCopies);
            setFilteredData(mappedCopies);

            if (copiedNode) {
                const changeResult = secureChangingSelectedNode(copiedNode);

                const postChangeHandler = (isConfirmed) => {
                    let ancestorsIds = [];

                    if (isConfirmed) {
                        ancestorsIds = getNodeAncestors(mappedCopies, copiedNode);
                        ancestorsIds = ancestorsIds.length ? ancestorsIds : [copiedNode.id];
                    } else {
                        const subTree = getNodeSubTree(mappedCopies, selected);
                        const newSelectedNode = subTree[subTree.length - 1];

                        ancestorsIds = subTree.slice(0, -1).map((item) => item.id);
                        setFocused(newSelectedNode.id);
                        setSelected(newSelectedNode);
                    }

                    setExpanded(_.uniq(mappedCopies.map((node) => node.id).concat(ancestorsIds)));
                    afterDiscardHandler.current = null;
                };

                if (changeResult) {
                    postChangeHandler(true);
                } else {
                    afterDiscardHandler.current = postChangeHandler;
                }
            }
        },
        [portfolios, secureChangingSelectedNode] // eslint-disable-line
    );

    const expandPortfolio = useCallback(
        (node) => {
            const ids = node.nodes.filter((n) => n.nodes.length > 0).map((n) => n.id);
            onSelect(node);
            setFocused(node.id);
            setExpanded((prev) => prev.concat(ids, node.id));
        },
        [onSelect]
    );

    const addNewPortfolio = useCallback(
        async (node) => {
            const newPortfolioValues = {
                parentId: node.properties.id,
                bookId: node.properties.bookId,
                // It isn't decided how we can set properties before portfolio creation so they are hardcoded for now.
                // Then focus/select is set on new portfolio in order to be able to edit the properties.
                name: intl.formatMessage({ id: 'portfolios_properties_new_portfolio_initial_name' }), // name: New Portfolio
                description: null,
                locationId: 1, // locationName: World
                portfolioTypeId: 1, // portfolioNameType: Standard Portfolio
                dispatchTypeId: 1, // dispatchTypeName: Economic Dispatch
            };

            const newPortfolio = await createPortfolio(newPortfolioValues, node.path + ' > ' + node.properties.name);
            const updatedNode = { ...node, nodes: [...node.nodes, newPortfolio] };

            setPortfolios((prev) => replaceNodeProperties(prev, node.properties.id, updatedNode));
            setFilteredData((prev) => replaceNodeProperties(prev, node.properties.id, updatedNode));

            setExpanded((prev) => [...prev, node.id]);
            onSelect(newPortfolio);
            setFocused(newPortfolio.id);
        },
        [intl, onSelect]
    );

    const onMoveModalOpen = useCallback(
        (node) => {
            setTemporaryNode(node);

            // @todo there's a hidden bug here: we are forced to do setPortfoliosBeforeMove() here
            // instead of in the more semantically correct place - `onMoveSuccess`
            // some of our operations might be silently mutating `portfoliosBeforeMove` or `temporaryNode`
            setPortfoliosBeforeMove(cloneDeep(portfolios));

            moveModal.onOpen();
        },
        // moveModal causes unnecessary rerender
        [portfolios] // eslint-disable-line
    );

    const onMoveSuccess = (data, movedPortfolio) => {
        moveModal.onClose();

        updateMovedPortfolios(data, movedPortfolio);
        refreshMapLayer({ node: movedPortfolio });
        toast({
            message: intl.formatMessage({ id: 'portfolios_move_changes_success' }),
            slot: (
                <Button textTransform="capitalize" ml={2} variant="secondary" onClick={undoMovePortfolio}>
                    {intl.formatMessage({ id: 'common_undo' })}
                </Button>
            ),
        });
    };

    const onMoveError = (message) => {
        toast({
            status: 'error',
            message: message,
        });
    };

    const undoMovePortfolio = async () => {
        // Find the initial parent before moving
        const initialParentNode = findPortfolioByPropertiesId(portfoliosBeforeMove, temporaryNode.properties.parentId);

        // Update the id of the moved portfolio
        const movedPortfolio = {
            ...temporaryNode,
            properties: {
                ...temporaryNode.properties,
                parentId: initialParentNode ? initialParentNode.properties.id : null,
            },
        };

        try {
            // -1 should be used when we want to set portfolio as root
            await movePortfolioToPortfolio(
                movedPortfolio.properties.id,
                initialParentNode ? initialParentNode.properties.id : -1
            );

            updateMovedPortfolios(portfoliosBeforeMove, movedPortfolio);
        } catch {
            toast({
                status: 'error',
                message: intl.formatMessage({ id: 'common_generic_saving_error' }),
            });
        }
    };

    const updateMovedPortfolios = (data, moved) => {
        setPortfolios(data);
        setFilteredData(data);
        setExpanded((prev) => [...prev, ...getNodeAncestors(data, moved).map((a) => a.id)]);
        setFocused(moved.id);
        onSelect(moved);
    };

    const onDeletePortfolio = async () => {
        let updatedNodes = [];

        deleteModal.onClose();

        const successMessage = intl.formatMessage({ id: 'common_delete_success' }, { item: 'portfolio' });

        await deletePortfolio(selected.properties.id);

        // Remove deleted node from parent portfolio
        if (selected.properties.parentId) {
            updatedNodes = removeNodeByParentId(portfolios, selected);
        } else {
            updatedNodes = portfolios.filter((el) => el.id !== selected.id);
        }

        setPortfolios(updatedNodes);
        setFilteredData(updatedNodes);
        setSelected(null);
        setFocused(updatedNodes[0].id);
        setMapExpanded(false);

        toast(successMessage);

        dispatch(onHasUnsavedChanges(false));
    };

    const closeAddModal = () => {
        addModal.onClose();
        setTemporaryNode(null);
    };

    const onAddModalOpen = useCallback(
        (node) => {
            setTemporaryNode(node);

            addModal.onOpen();
        },
        // addModal causes unnecessary rerender
        [] // eslint-disable-line
    );

    const getAddItemSuccessMessage = (updatedPortfolio, ids) => {
        if (ids.length === 1) {
            for (const group of updatedPortfolio.nodes) {
                const item = group.nodes.find((item) => item.id === ids[0]);

                if (item) {
                    return intl.formatMessage(
                        { id: 'common_add_item_success_message' },
                        { item: item.properties.name, destination: updatedPortfolio.properties.name }
                    );
                }
            }
        }

        return intl.formatMessage(
            { id: 'portfolios_add_items_success_message' },
            { n: ids.length, portfolioName: updatedPortfolio.properties.name }
        );
    };

    const onAddSuccess = (updatedPortfolio, itemsAdded) => {
        setExpanded((prev) => (prev.includes(updatedPortfolio.id) ? prev : prev.concat(updatedPortfolio.id)));
        setPortfolios((prev) => replaceNodeProperties(prev, updatedPortfolio.properties.id, updatedPortfolio));
        setFilteredData((prev) => replaceNodeProperties(prev, updatedPortfolio.properties.id, updatedPortfolio));

        toast(getAddItemSuccessMessage(updatedPortfolio, itemsAdded));
        refreshMapLayer({ node: updatedPortfolio });
        closeAddModal();
    };

    const onAddError = (error) => {
        if (error.response.status !== 401) {
            if (error.response.status === 403) {
                toast({
                    status: 'error',
                    message: error.response.data.error,
                });
            } else {
                toast({
                    status: 'error',
                    message: intl.formatMessage({ id: 'common_generic_saving_error' }),
                });
            }
            return;
        }
    };

    const onRemoveItemFromPortfolioModalOpen = useCallback(
        (node) => {
            setTemporaryNode(node);
            removeItemModal.onOpen();
        },
        // removeItemModal causes unnecessary rerender
        [] // eslint-disable-line
    );

    const onRemoveItemFromPortfolio = async () => {
        removeItemModal.onClose();

        try {
            const itemParentPortfolio = findPortfolioByPropertiesId(portfolios, temporaryNode.properties.portfolioId);

            await removeItemFromPortfolio(itemParentPortfolio.properties.id, temporaryNode.properties.id);

            const updatedPortfolio = {
                ...itemParentPortfolio,
                nodes: itemParentPortfolio.nodes.filter((node) => {
                    node.nodes = node.nodes.filter((n) => n.id !== temporaryNode.id);

                    return node.isFolder || node.nodes.length > 0;
                }),
            };

            setPortfolios((prev) => replaceNodeProperties(prev, updatedPortfolio.properties.id, updatedPortfolio));
            setFilteredData((prev) => replaceNodeProperties(prev, updatedPortfolio.properties.id, updatedPortfolio));

            refreshMapLayer({ node: updatedPortfolio });

            toast(intl.formatMessage({ id: 'common_remove_success' }, { item: temporaryNode.properties.name }));
        } finally {
            setTemporaryNode(null);
            setSelected(null);
            setFocused(null);
            setMapExpanded(false);
        }
    };

    const onNodeRename = (name) => {
        setPortfolios((prev) => renameNode(prev, selected.properties.id, name));
    };

    const onNodeChangeIsMaster = useCallback(
        (isMaster) => {
            setPortfolios((prev) => updateNodeIsMaster(prev, selected.properties.id, isMaster));
        },
        [selected?.properties.id]
    );

    const onCoordsUpdate = (lat, long, objectRefId) => {
        if (temporaryNode) {
            setPortfolios((prev) => updateNodeCoords(prev, temporaryNode.properties.id, lat, long, objectRefId));
            setFilteredData((prev) => updateNodeCoords(prev, temporaryNode.properties.id, lat, long, objectRefId));
        } else {
            setPortfolios((prev) => updateNodeCoords(prev, selected.properties.id, lat, long, objectRefId));
            setFilteredData((prev) => updateNodeCoords(prev, selected.properties.id, lat, long, objectRefId));
        }
    };

    const onNodeOwnershipPercentageUpdate = (ownershipPercentage) => {
        setPortfolios((prev) => updateNodeOwnshershipPercentage(prev, selected.id, ownershipPercentage));
    };

    const onNodeItemDelete = () => {
        setPortfolios((prev) => removeNodesFromTree(prev, selected.properties.id));
        setFilteredData((prev) => removeNodesFromTree(prev, selected.properties.id));
        setSelected(null);
        setMapExpanded(false);

        // when deleting an item this sets the currently focused element to the first node in the tree
        // and also makes it the activeElement of the document
        setFocused(filteredData[0].id);
    };

    const onFocusChange = useCallback(({ id }) => {
        setFocused(id);
    }, []);

    const onExpandChange = useCallback(({ id }) => {
        setExpanded((prev) => (prev.includes(id) ? prev.filter((node) => node !== id) : prev.concat(id)));
    }, []);

    const renderLabel = useCallback(
        (node) => {
            return (
                <div
                    className={`${node?.isExpandable ? '' : 'non-expandable '}portfolio-label-wrapper tree-node-target`}
                >
                    {node?.isExpandable && (
                        <span
                            onClick={() => {
                                setExpanded((prev) =>
                                    prev.includes(node.id) ? prev.filter((id) => id !== node.id) : prev.concat(node.id)
                                );
                                setFocused(node.id);
                            }}
                        >
                            <PortfolioArrowIcon isExpanded={node?.isExpanded} isMaster={node.properties.isMaster} />
                        </span>
                    )}

                    <div
                        onClick={(event) => {
                            if (event.shiftKey && node?.isExpandable) {
                                setExpanded((prev) =>
                                    prev.includes(node.id)
                                        ? prev.filter((nodeId) => nodeId !== node.id)
                                        : prev.concat(node.id)
                                );
                            } else {
                                secureChangingSelectedNode(node);
                            }
                        }}
                        className="portfolio-label"
                    >
                        <FilteredWrapper>
                            <PortfolioNodeIcon
                                isFolder={node.isFolder}
                                isExpanded={node?.isExpanded}
                                isMaster={node.properties.isMaster}
                                typeId={node.properties.typeId}
                            />

                            {searchName.trimStart().length >= 2 ? (
                                <div className="path-wrapper">
                                    <span className="path">{node.path}</span>

                                    <span>{node.properties.name}</span>
                                </div>
                            ) : (
                                <span>{node.properties.name}</span>
                            )}
                        </FilteredWrapper>
                    </div>

                    {
                        //root portfolio
                        node.isFolder && node.properties?.parentId == null && node.properties?.id === 1 && (
                            <PortfolioHoverLabelIcons
                                selected={node}
                                onDownload={canUseRestoration ? onDownload : undefined}
                                onCopy={onCopyModalOpen}
                                onExpandChange={expandPortfolio}
                                onAddPortfolio={addNewPortfolio}
                                onMovePortfolio={undefined}
                                onAddItems={undefined}
                            />
                        )
                    }

                    {node.isFolder && node.properties?.parentId != null && (
                        <PortfolioHoverLabelIcons
                            selected={node}
                            onDownload={canUseRestoration ? onDownload : undefined}
                            onCopy={onCopyModalOpen}
                            onExpandChange={expandPortfolio}
                            onAddPortfolio={addNewPortfolio}
                            onMovePortfolio={onMoveModalOpen}
                            onAddItems={onAddModalOpen}
                        />
                    )}

                    {!node.isFolder && !node?.isExpandable && (
                        <PortfolioItemHoverLabelIcons selected={node} onRemove={onRemoveItemFromPortfolioModalOpen} />
                    )}
                    <div className="tree-node-tooltip">{node?.properties?.name}</div>
                </div>
            );
        },
        [
            onDownload,
            onCopyModalOpen,
            expandPortfolio,
            addNewPortfolio,
            onMoveModalOpen,
            onAddModalOpen,
            onRemoveItemFromPortfolioModalOpen,
            searchName,
            canUseRestoration,
            secureChangingSelectedNode,
        ]
    );

    const onSearchChange = (value) => {
        setSearchName(value);
    };

    const refreshMapLayer = async ({ long, lat, node, objectRefId }) => {
        // The params are passed from a ref object in the corresponding item since setSelected is not finished
        // on time ( due to waiting for /sub-item)
        if (!mapProps.current) return;

        const { view, layer } = mapProps.current;
        if (layer) {
            await layer.refresh();
        }

        if (node || objectRefId) {
            extentMap(node || objectRefId, layer, view);
        }

        // Update portfolio tree | long && lat could be null
        if (long !== undefined && lat !== undefined) {
            onCoordsUpdate(lat, long, objectRefId);
        }
    };
    const noSearchResults = useMemo(
        () => searchName.trim().length > 0 && filteredData.length === 0,
        [searchName, filteredData]
    );

    return (
        <LayoutWrapper
            hasFooter={false}
            minSidebarWidth={500}
            sidebar={
                isSidebarVisible && (
                    <LayoutSidebar
                        itemType={'portfolio'}
                        selected={selected}
                        titleId={'portfolios-title'}
                        titleMessage={intl.formatMessage({ id: 'portfolios_title' })}
                        accordionPlaceholderId={'portfolios_select_portfolio'}
                        searchPlaceholderId={'common_search_portfolios'}
                        searchCallback={onSearchChange}
                    >
                        <PortfolioTreeWrapper isDisabled={isDisabled}>
                            {isLoading ? (
                                <Skeleton mx={6}>
                                    <Box h="34px">
                                        <FormattedMessage id="common_loading" />
                                    </Box>
                                </Skeleton>
                            ) : loadingError ? (
                                <Box mx={6}>
                                    <Alert status="error">
                                        <AlertIcon />

                                        <Box>
                                            <AlertTitle textTransform="capitalize">
                                                <FormattedMessage id="common_error" />
                                            </AlertTitle>

                                            <AlertDescription>
                                                <FormattedMessage
                                                    id="common_loading_error"
                                                    values={{ items: 'portfolios' }}
                                                />
                                            </AlertDescription>
                                        </Box>
                                    </Alert>
                                </Box>
                            ) : noSearchResults ? (
                                <Box px={6}>
                                    <Alert status="info">
                                        <AlertIcon />

                                        <FormattedMessage id="common_no_search_results" />
                                    </Alert>
                                </Box>
                            ) : (
                                <Tree
                                    aria-labelledby="portfolios-title"
                                    nodes={filteredData}
                                    focused={focused}
                                    onFocusChange={onFocusChange}
                                    expanded={expanded}
                                    onExpandChange={onExpandChange}
                                    selected={selected ? selected.id : null}
                                    onSelectChange={secureChangingSelectedNode}
                                    renderLabel={renderLabel}
                                    expandOnSelect={false}
                                />
                            )}
                        </PortfolioTreeWrapper>
                    </LayoutSidebar>
                )
            }
            content={
                <>
                    {confirmationModal.isOpen && (
                        <ConfirmationModal
                            isOpen
                            hasExtraStep
                            onClose={() => {
                                afterDiscardHandler.current && afterDiscardHandler.current(false);
                                confirmationModal.onClose();
                            }}
                            onConfirm={() => {
                                setSelected(nextNode);
                                confirmationModal.onClose();
                                dispatch(onHasUnsavedChanges(false));
                                afterDiscardHandler.current && afterDiscardHandler.current(true);
                            }}
                            header={<FormattedMessage id="common_discard_changes" />}
                            content={<FormattedMessage id="common_confirmation_explanation" />}
                        />
                    )}

                    {isLoading && (
                        <WrapperEmptyState
                            imgSrc={portfolioPlaceholder}
                            messages={{
                                header: <FormattedMessage id="run_a_study_loading_portfolios_header" />,
                                body: <FormattedMessage id="run_a_study_loading_portfolios_body" />,
                            }}
                        />
                    )}

                    {selected && (
                        <PortfolioMap
                            selected={selected}
                            toggleMap={() => setMapExpanded((mapExpanded) => !mapExpanded)}
                            mapExpanded={mapExpanded}
                            onPowersimLayerLoaded={(layer, map, view) => (mapProps.current = { layer, map, view })}
                        />
                    )}

                    <PortfolioWrapper mapExpanded={mapExpanded}>
                        {selected && selected.isFolder ? (
                            <Portfolio
                                portfolio={selected}
                                metadata={metadata}
                                onSubmit={onFormSubmit}
                                onDelete={deleteModal.onOpen}
                            />
                        ) : selected ? (
                            <PortfolioItem
                                item={selected}
                                itemTypes={itemTypes}
                                onNodeRename={onNodeRename}
                                onNodeChangeIsMaster={onNodeChangeIsMaster}
                                onNodeOwnershipPercentageUpdate={onNodeOwnershipPercentageUpdate}
                                onNodeItemDelete={onNodeItemDelete}
                                refreshMapLayer={refreshMapLayer}
                            />
                        ) : (
                            <WrapperEmptyState imgSrc={portfolioPlaceholder} page="portfolio" noun="properties" />
                        )}
                    </PortfolioWrapper>
                </>
            }
        >
            {copyModal.isOpen && (
                <CopyPortfolioModal
                    onClose={copyModal.onClose}
                    source={temporaryCopyNode}
                    portfolios={portfolios}
                    onCopySuccess={onCopySuccess}
                />
            )}

            {moveModal.isOpen && (
                <MovePortfolioModal
                    onClose={moveModal.onClose}
                    source={temporaryNode}
                    portfolios={portfolios}
                    onMoveSuccess={onMoveSuccess}
                    onMoveError={onMoveError}
                />
            )}

            {addModal.isOpen && (
                <AddItemsPortfolioModal
                    onClose={closeAddModal}
                    source={temporaryNode}
                    onAddSuccess={onAddSuccess}
                    onAddError={onAddError}
                />
            )}

            {deleteModal.isOpen && (
                <ConfirmationModal
                    isOpen
                    onClose={deleteModal.onClose}
                    header={<FormattedMessage id="common_delete_modal_heading" values={{ item: 'Portfolio' }} />}
                    hasExtraStep
                    content={
                        <FormattedMessage id="common_delete_modal_msg" values={{ item: selected.properties.name }} />
                    }
                    confirmText={
                        <Box as="span" textTransform="capitalize">
                            <FormattedMessage id="common_delete" />
                        </Box>
                    }
                    onConfirm={onDeletePortfolio}
                />
            )}

            {removeItemModal.isOpen && (
                <ConfirmationModal
                    isOpen
                    onClose={removeItemModal.onClose}
                    header={<FormattedMessage id="common_remove_modal_heading" values={{ item: 'Item' }} />}
                    hasExtraStep
                    content={
                        <FormattedMessage
                            id="common_remove_modal_msg"
                            values={{ item: temporaryNode.properties.name }}
                        />
                    }
                    confirmText={
                        <Box as="span" textTransform="capitalize">
                            <FormattedMessage id="common_remove" />
                        </Box>
                    }
                    onConfirm={onRemoveItemFromPortfolio}
                />
            )}
        </LayoutWrapper>
    );
};

// Conditional rendering will cause refetching data on component mount, thats why display: none is justified here.
const PortfolioWrapper = styled('div', { shouldForwardProp: (prop) => prop !== 'mapExpanded' })`
    display: ${(props) => props.mapExpanded && 'none'};
    height: 100%;
`;

export default Portfolios;
