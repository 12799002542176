import { useEffect, useCallback, useState, useRef, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { HttpStatusCode } from 'axios';

import {
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    Box,
    Button,
    Divider,
    Flex,
    Heading,
    SimpleGrid,
    Spacer,
    Text,
    useDisclosure,
    IconButton,
} from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';

import {
    createNewUnit,
    deleteSubThermal,
    getThermalData,
    updateThermal,
    copyUnit,
    mapDefinitionsConfig,
    getThermalMetadata,
    addBulkFieldsToSubThermals,
    getThermalFilters,
} from '../../services/thermals';

import {
    checkItemInputEvent,
    flatItemDescriptions,
    getNodeIcon,
    deleteItem,
    isFilterApplied,
} from '../../services/items';
import mappedItemTypes from '../../services/items/mappedItemTypes';

import { validateLongLat } from '../../services/portfolios';
import { isNumberCellEmpty } from '../../services/items';

import ThermalDefinitions from './ThermalDefinitions';
import AddNewUnitModal from './AddNewUnitModal';
import TransitionMatrixModal from './TransitionMatrixModal';
import SubThermalData from './SubThermalData';
import ThermalFilters from './ThermalFilters';

import useCommonToast from '../../hooks/useCommonToast';
import useHistoryPush from '../../hooks/useHistoryPush';
import useBulkUpdate from './hooks/useBulkUpdate';

import ExpandableItemWrapper from '../layout/ExpandableItemWrapper';
import ShowHideSidebarButton from '../layout/ShowHideSidebarButton';

import ConfirmationModal from '../modal/ConfirmationModal';

import Tooltip from '../utils/Tooltip';
import ActionBar from '../utils/ActionBar';
import DeleteRecordModal from '../utils/DeleteRecordModal';
import DefinitionsSkeleton from '../utils/DefinitionsSkeleton';
import ItemPropertiesModal from '../utils/ItemPropertiesModal';
import SecondaryButton from '../utils/SecondaryButton';
import FiltersButton from '../utils/FiltersButton';
import CustomItemIcon from '../utils/CustomItemIcon';
import PromptWrapper from '../utils/PromptWrapper';
import ErrorAccordion from '../utils/ErrorAccordion';

import SortOrderProvider from '../grid/utils/SortOrderContext';

import InputFormikField from '../forms/InputFormikField';
import ComboboxFormikField from '../forms/ComboboxFormikField';
import CheckboxFormikField from '../forms/CheckboxFormikField';
import AutomaticFormikDirtyCheck from '../forms/AutomaticFormikDirtyCheck';

import { onHasUnsavedChanges } from '../../store/helpers/helpersSlice';

import { ReactComponent as SaveIcon } from '../../icons/save.svg';
import { ReactComponent as DeleteIcon } from '../../icons/delete.svg';
import { ReactComponent as EditIcon } from '../../icons/edit-circled.svg';
import { ReactComponent as FileIcon } from '../../icons/file.svg';
import { ReactComponent as TransitionIcon } from '../../icons/transition.svg';
import SubThermalSection from './SubThermalSection';

import { FEATURE_FLAG_NAMES } from '../../constants/featureFlags';
import { useIsFeatureActive } from '../../hooks/features-flags';

const FILTERS_STORAGE_NAMESPACE = 'thermalDsm';

const Thermal = ({
    type,
    typeId,
    thermalItem,
    onItemEdit,
    onNodeRename,
    onNodeChangeIsMaster,
    onItemDelete,
    onNodeItemDelete,
    onNodeOwnershipPercentageUpdate,
    refreshMapLayer,
    helpPageUrlItemTypeId = null,
}) => {
    const intl = useIntl();
    const { toast } = useCommonToast();
    const dispatch = useDispatch();

    const deleteItemModal = useDisclosure();
    const deleteSubItemModal = useDisclosure();
    const addSubItemModal = useDisclosure();
    const propertiesModal = useDisclosure();
    const transitionMatrixModal = useDisclosure();
    const filterDrawer = useDisclosure();

    const historyPush = useHistoryPush();
    const { thermalId } = useParams();
    const itemId = Number(thermalId || thermalItem.properties.id);
    const itemTypeId = typeId || thermalItem.properties.typeId;

    const selectedItemRef = useRef(null);

    const bulkUpdate = useBulkUpdate();

    const [metadata, setMetadata] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [loadingError, setLoadingError] = useState(false);
    const [selectedThermal, setSelectedThermal] = useState(null);
    const [thermalDefinitions, setThermalDefinitions] = useState(null);
    const [editDescriptionsConfig, setEditDescriptionsConfig] = useState(null);
    const [subItemForDeletion, setSubItemForDeletion] = useState(null);
    const [accordionIndex, setAccordionIndex] = useState([0]);
    const [forceGridRefresh, setForceGridRefresh] = useState(false);
    const [filters, setFilters] = useState(() => getThermalFilters(FILTERS_STORAGE_NAMESPACE));
    const [isFormDirty, setIsFormDirty] = useState(false);
    const isCAMSliceOfDayVisible = useIsFeatureActive(FEATURE_FLAG_NAMES.CAM_SLICEOFDAY_FIELDS_VISIBLE);

    const Icon = getNodeIcon(itemTypeId);

    const initialFormValues = {
        thermalItem: {
            ...selectedThermal,
            itemId: itemId,
            description: selectedThermal?.description,
            bookId: selectedThermal?.bookId,
            pricingPointId: selectedThermal?.pricingPointId,
            itemTypeId: typeId || thermalItem.properties.typeId,
            latitude: selectedThermal?.latitude !== null ? selectedThermal?.latitude : '',
            longitude: selectedThermal?.longitude !== null ? selectedThermal?.longitude : '',
            securityId: selectedThermal?.securityId,
            gasBasisId: selectedThermal?.gasBasisId,
            cO2BasisId: selectedThermal?.cO2BasisId,
        },
        subThermals: thermalDefinitions?.initialFormValues,
    };

    const formLabels = useMemo(
        () => ({
            book: intl.formatMessage({ id: 'common_book' }),
            pricingPoint: intl.formatMessage({ id: 'common_electric_pricing_point' }),
            selectPlaceholder: intl.formatMessage({ id: 'common_forms_select_option' }),
            unitType: intl.formatMessage({ id: 'unit_type' }),
            dsmLoad: intl.formatMessage({ id: 'common_dsm_load' }),
            latitude: intl.formatMessage({ id: 'latitude' }),
            longitude: intl.formatMessage({ id: 'longitude' }),
            master: intl.formatMessage({ id: 'common_master' }),
            gasBasis: intl.formatMessage({ id: 'common_gas_pricing_point' }),
            co2Basis: intl.formatMessage({ id: 'common_CO2_pricing_point' }),
        }),
        [intl]
    );

    const fetchData = useCallback(() => {
        const clearBulkUpdateState = bulkUpdate.clearBulkUpdateState;

        return Promise.all([getThermalData(itemId), getThermalMetadata()])
            .then(([data, metadata]) => {
                setMetadata(metadata);
                setSelectedThermal(data.thermalItem);
                selectedItemRef.current = data.thermalItem;
                setThermalDefinitions({
                    definitions: data,
                    initialFormValues: mapDefinitionsConfig(
                        data.subThermals,
                        itemId,
                        itemTypeId,
                        isCAMSliceOfDayVisible
                    ),
                });
                setEditDescriptionsConfig(flatItemDescriptions(data.subThermals, itemId));

                // This is needed to show results after previous error
                setLoadingError(false);

                clearBulkUpdateState();
            })
            .catch(() => setLoadingError(true))
            .finally(() => setIsLoading(false));
    }, [itemId, itemTypeId, bulkUpdate.clearBulkUpdateState, isCAMSliceOfDayVisible]);

    useEffect(() => {
        setIsLoading(true);
        fetchData();
    }, [fetchData]);

    useEffect(() => {
        const result = Object.keys(bulkUpdate.changedBulkUpdateFields).length > 0 || isFormDirty;
        dispatch(onHasUnsavedChanges(result));
    }, [bulkUpdate.changedBulkUpdateFields, dispatch, isFormDirty]);

    const onEditDescription = (itemId) => {
        if (!editDescriptionsConfig[itemId].penDisabled) {
            setEditDescriptionsConfig((prevState) => {
                return {
                    ...prevState,
                    [itemId]: { isActive: !prevState[itemId].isActive, penDisabled: false },
                };
            });
        }
    };

    const validateDescription = (value, itemId, isSubItem = false) => {
        const trimmed = value.trim();
        const maxLength = isSubItem ? 50 : 100;

        let errorMessage = '';

        if (trimmed.length === 0) {
            errorMessage = intl.formatMessage({ id: `${type}_form_validation_required_name` });
        } else if (trimmed.length > maxLength) {
            errorMessage = intl.formatMessage({ id: `${type}_form_validation_name_length` }, { length: maxLength });
        }

        if (errorMessage !== '') {
            setEditDescriptionsConfig((prev) => {
                return { ...prev, [itemId]: { ...prev[itemId], penDisabled: true } };
            });
        } else {
            setEditDescriptionsConfig((prev) => {
                return { ...prev, [itemId]: { ...prev[itemId], penDisabled: false } };
            });
        }

        return errorMessage;
    };

    const validate = (values) => {
        let longitude = validateLongLat(values.thermalItem.longitude, 'long', values.thermalItem.latitude);
        let latitude = validateLongLat(values.thermalItem.latitude, 'lat', values.thermalItem.longitude);

        if (!longitude && !latitude) {
            return null;
        }

        return {
            thermalItem: {
                longitude: longitude ? longitude : null,
                latitude: latitude ? latitude : null,
            },
        };
    };

    const handleUpdateThermal = (resultResponse, hasChangedBulkUpdateFields, requestFailed = false) => {
        let errorInfo = [];
        let errorFound = requestFailed; //true if API call returned with anything other than a 200 OK
        let hasWarnings = false;
        if (hasChangedBulkUpdateFields) {
            for (const subThermalResponse of resultResponse) {
                if (Array.isArray(subThermalResponse?.returnValue) && subThermalResponse.returnValue.length > 0) {
                    const subIdKey = subThermalResponse.returnValue[0].parentRecordId;
                    const tabNamesList = Object.keys(bulkUpdate.changedBulkUpdateFields[subIdKey]);

                    for (const tabNameKey of tabNamesList) {
                        const tabChangedColumns = Object.keys(bulkUpdate.changedBulkUpdateFields[subIdKey][tabNameKey]);
                        const columnsWithIssues = subThermalResponse.returnValue
                            .filter((x) => x.tabName.toUpperCase() === tabNameKey.toUpperCase())
                            ?.flatMap((x) => x.columnsWithIssues)
                            ?.flatMap((x) => x.columnValidationResult);

                        if (!Array.isArray(columnsWithIssues)) {
                            continue;
                        }

                        for (const colIssue of columnsWithIssues) {
                            const colErrors = colIssue?.validationMessages
                                .filter((x) => x.severity.toUpperCase() === 'ERROR')
                                ?.flatMap((x) => x.message);
                            const colWarnings = colIssue?.validationMessages
                                .filter((x) => x.severity.toUpperCase() === 'WARNING')
                                ?.flatMap((x) => x.message);

                            if (Array.isArray(colErrors) && colErrors.length > 0) {
                                errorFound = true;
                            }
                            if (Array.isArray(colWarnings) && colWarnings.length > 0) {
                                hasWarnings = true;
                            }

                            const tabErrors = tabChangedColumns
                                .filter((x) => x.toUpperCase() === colIssue.name.toUpperCase())
                                ?.map((col) => ({
                                    colName: col,
                                    error: colErrors,
                                    warning: colWarnings,
                                    subThermalId: subIdKey,
                                }));
                            errorInfo = [...errorInfo, ...tabErrors];
                        }
                    }
                }
            }
            bulkUpdate.setServerErrors(errorInfo);
        }
        if (!errorFound && !hasWarnings) {
            // Clear bulk update (varied by date) fields values only if update is successful and there are no warnings
            bulkUpdate.clearBulkUpdateState();
        }
        return errorFound;
    };

    const onFormSubmit = async (values) => {
        const successMessage = intl.formatMessage({ id: 'common_generic_item_change_success' });
        const arcGISError = intl.formatMessage({ id: 'common_item_arcgis_lat_long_save_failed' });
        //Clear errors
        bulkUpdate.setServerErrors([]);
        let errorFound = false;
        let arcGisIssueFound = '';

        const bulkUpdateSubIdsList = Object.keys(bulkUpdate.changedBulkUpdateFields);
        const hasChangedBulkUpdateFields = bulkUpdateSubIdsList?.length > 0;

        const subThermals = hasChangedBulkUpdateFields
            ? addBulkFieldsToSubThermals(values.subThermals, bulkUpdate.changedBulkUpdateFields, metadata)
            : values.subThermals;

        if (isCAMSliceOfDayVisible) {
            //PCDSPercent (NumberInputFormikField) can return '' if it had a value and the value was deleted
            subThermals
                ?.filter((sub) => Number.isNaN(Number.parseFloat(sub?.pcdsPercent)))
                ?.forEach((sub) => {
                    sub.pcdsPercent = null;
                });
        }

        const payload = {
            subThermals,
            thermalItem: {
                ...values.thermalItem,
                longitude: values.thermalItem.longitude !== '' ? values.thermalItem.longitude : null,
                latitude: values.thermalItem.latitude !== '' ? values.thermalItem.latitude : null,
            },
        };

        try {
            await updateThermal(payload)
                .then((result) => {
                    const { data, arcGisIssue } = result;
                    arcGisIssueFound = arcGisIssue;

                    errorFound = handleUpdateThermal(data, hasChangedBulkUpdateFields);

                    if (errorFound) {
                        toast({
                            status: 'error',
                            message: intl.formatMessage({ id: 'common_bulk_update_request_failed' }),
                        });
                    }
                })
                .catch((error) => {
                    if (error.response.status < HttpStatusCode.InternalServerError) {
                        errorFound = handleUpdateThermal(error.response.data, hasChangedBulkUpdateFields, true);

                        if (errorFound) {
                            toast({
                                status: 'error',
                                message: intl.formatMessage({ id: 'common_bulk_update_request_failed' }),
                            });
                        }
                    } else {
                        toast({
                            status: 'error',
                            message: intl.formatMessage({ id: 'common_generic_saving_error' }),
                        });
                    }
                });

            if (!errorFound) {
                await fetchData();

                hasChangedBulkUpdateFields && setForceGridRefresh(true);

                if (!thermalItem) {
                    onItemEdit({
                        id: selectedThermal.id,
                        description: values.thermalItem.description,
                        bookId: values.thermalItem.bookId,
                        pricintPointId: values.thermalItem.pricingPointId,
                        securityId: values.thermalItem.securityId,
                    });
                } else {
                    onNodeRename(values.thermalItem.description);
                    onNodeChangeIsMaster(values.thermalItem.securityId);
                }

                toast({
                    message: successMessage,
                    slot: arcGisIssueFound && (
                        <ErrorAccordion message={arcGISError} details={arcGisIssueFound} status={'warn'} />
                    ),
                    duration: arcGisIssueFound && 20000,
                });
            }
        } finally {
            if (typeof refreshMapLayer === 'function') {
                refreshMapLayer({
                    long: values.thermalItem.longitude,
                    lat: values.thermalItem.latitude,
                    objectRefId: selectedItemRef.current.objectRefId,
                });
            }

            setForceGridRefresh(false);
        }
    };

    const onDeleteItem = async (item) => {
        try {
            await deleteItem(item.id);

            dispatch(onHasUnsavedChanges(false));

            //check whether the Thermal item is deleted from Thermal/DSM or Portfolios
            if (thermalItem) {
                onNodeItemDelete();
            } else {
                onItemDelete(item);
                historyPush(`/${type === 'generation_asset' ? 'thermal' : type}`);
            }

            toast(intl.formatMessage({ id: `${type}_delete_success` }));
        } catch (error) {
            if (error.response.status !== HttpStatusCode.Unauthorized) {
                let serverError;

                if (error.response.status !== HttpStatusCode.InternalServerError) {
                    serverError = error.response.data.error || error.response.data.title;
                }

                toast({
                    status: 'error',
                    message: serverError || intl.formatMessage({ id: 'common_generic_saving_error' }),
                });
            }
        }
    };

    const onDeleteSubItemModalOpen = (id, description) => {
        setSubItemForDeletion({
            id,
            description,
        });

        deleteSubItemModal.onOpen();
    };

    const onDeleteSubItemModalClose = () => {
        deleteSubItemModal.onClose();
        setSubItemForDeletion(null);
    };

    const onSubItemDelete = async (id) => {
        onDeleteSubItemModalClose();

        try {
            setIsLoading(true);

            await deleteSubThermal(id, { suppressErrorMessageHandler: true });
            await fetchData();

            toast(intl.formatMessage({ id: 'unit_delete_unit_success' }));
        } catch (error) {
            if (error.response.status !== HttpStatusCode.Unauthorized) {
                let serverError;

                if (error.response.status !== HttpStatusCode.InternalServerError) {
                    serverError = error.response.data.error || error.response.data.title;
                }

                toast({
                    status: 'error',
                    message: serverError || intl.formatMessage({ id: 'common_generic_saving_error' }),
                });
            }
        } finally {
            setIsLoading(false);
        }
    };

    const updateThermals = (
        { id, description, unitTypeId, dsmLoadId, deliverabilityStatusId, pcdsPercent },
        itemId
    ) => {
        const parentItemId = parseInt(itemId);
        initialFormValues.subThermals.push({
            subThermalId: id,
            unitTypeId,
            dsmLoadId,
            description,
            deliverabilityStatusId,
            pcdsPercent: isNumberCellEmpty(pcdsPercent),
            itemId: parentItemId,
        });

        if (!thermalDefinitions?.definitions?.subThermals.find((x) => x.id === id)) {
            thermalDefinitions.definitions.subThermals.push({
                id,
                unitTypeId,
                dsmLoadId,
                description,
                deliverabilityStatusId,
                pcdsPercent: isNumberCellEmpty(pcdsPercent),
                itemId: parentItemId,
            });
        }

        setEditDescriptionsConfig((prev) => {
            return {
                ...prev,
                [id]: { isActive: false, penDisabled: false },
            };
        });
    };

    const onCopyUnit = async (subThermalId) => {
        toast({ status: 'info', message: intl.formatMessage({ id: 'unit_copy_btn_progress_message' }) });

        const unit = await copyUnit(subThermalId);

        const units = [
            ...thermalDefinitions.initialFormValues,
            ...mapDefinitionsConfig([unit], itemId, itemTypeId, isCAMSliceOfDayVisible),
        ];
        if (!thermalDefinitions?.definitions?.subThermals.find((x) => x.id === unit.id)) {
            thermalDefinitions.definitions.subThermals.push(unit);
        }

        setThermalDefinitions((prev) => ({
            ...prev,
            initialFormValues: units,
        }));

        setEditDescriptionsConfig((prev) => {
            return {
                ...prev,
                [unit.id]: { isActive: false, penDisabled: false },
            };
        });

        setAccordionIndex(units.length - 1);
    };

    const onApplyFilters = useCallback(
        (data) => {
            setFilters(data);
            filterDrawer.onClose();
        },
        [filterDrawer]
    );

    const renderPanelContent = useCallback(
        ({ idx, subThermalId, isExpanded, helpPageUrlItemTypeId }) => (
            <>
                <ThermalDefinitions
                    idx={idx}
                    itemTypeId={itemTypeId}
                    formLabels={formLabels}
                    data={thermalDefinitions}
                    type={type}
                    helpPageUrlItemTypeId={helpPageUrlItemTypeId}
                />

                <SortOrderProvider>
                    <SubThermalData
                        subThermalId={subThermalId}
                        defaultItemId={itemId}
                        type={type}
                        metadata={metadata}
                        isExpanded={isExpanded}
                        bulkUpdate={bulkUpdate}
                        forceGridRefresh={forceGridRefresh}
                        filters={filters}
                        helpPageUrlItemTypeId={helpPageUrlItemTypeId}
                    />
                </SortOrderProvider>
            </>
        ),
        [itemTypeId, itemId, formLabels, thermalDefinitions, type, metadata, bulkUpdate, forceGridRefresh, filters]
    );

    return (
        <>
            <PromptWrapper />

            <ExpandableItemWrapper>
                {isLoading ? (
                    <DefinitionsSkeleton />
                ) : loadingError ? (
                    <Box m={6}>
                        <Alert status="error">
                            <AlertIcon />
                            <Box>
                                <AlertTitle textTransform="capitalize">
                                    <FormattedMessage id="common_error" />
                                </AlertTitle>
                                <AlertDescription>
                                    <FormattedMessage id="common_generic_loading_error" />
                                </AlertDescription>
                            </Box>
                        </Alert>
                    </Box>
                ) : (
                    <>
                        <Formik
                            enableReinitialize
                            initialValues={initialFormValues}
                            onSubmit={onFormSubmit}
                            validate={validate}
                        >
                            {({ isSubmitting, values }) => (
                                <Form>
                                    <AutomaticFormikDirtyCheck
                                        isFormDirty={isFormDirty}
                                        onFormDirtyChange={setIsFormDirty}
                                    />

                                    <Flex pt={3} pl={6} align="center">
                                        <CustomItemIcon isMaster={selectedThermal.securityId} icon={<Icon />} />

                                        {editDescriptionsConfig && !editDescriptionsConfig[itemId]?.isActive ? (
                                            <Heading px={2} as="h2" variant="h2">
                                                {values.thermalItem.description}
                                            </Heading>
                                        ) : (
                                            <InputFormikField
                                                px={3}
                                                name="thermalItem.description"
                                                type="text"
                                                flex="1"
                                                maxW="500px"
                                                validate={(value) => validateDescription(value, itemId)}
                                                onKeyDown={checkItemInputEvent}
                                                isFastField
                                            />
                                        )}

                                        <Box display="inline-block" mr={4}>
                                            <Tooltip
                                                label={intl.formatMessage({
                                                    id: 'common_edit_btn_tooltip_and_label',
                                                })}
                                            >
                                                <IconButton
                                                    aria-label={intl.formatMessage({
                                                        id: 'common_edit_btn_tooltip_and_label',
                                                    })}
                                                    variant="circular-icon"
                                                    leftIcon={<EditIcon />}
                                                    onClick={() => onEditDescription(itemId)}
                                                />
                                            </Tooltip>
                                        </Box>
                                    </Flex>

                                    <ActionBar>
                                        <ShowHideSidebarButton />

                                        <SecondaryButton
                                            leftIcon={<FileIcon />}
                                            size="sm"
                                            variant="secondary"
                                            onClick={propertiesModal.onOpen}
                                            flexShrink={0}
                                        >
                                            <FormattedMessage id="portfolio_item_properties" />
                                        </SecondaryButton>

                                        <SecondaryButton
                                            leftIcon={<TransitionIcon />}
                                            size="sm"
                                            variant="secondary"
                                            onClick={transitionMatrixModal.onOpen}
                                            flexShrink={0}
                                        >
                                            <FormattedMessage id="common_transition_matrix" />

                                            <Text as="span" ml={2} color="gray.500">
                                                <FormattedMessage id="common_optional" />
                                            </Text>
                                        </SecondaryButton>

                                        <FiltersButton
                                            hasIndicator={isFilterApplied(FILTERS_STORAGE_NAMESPACE)}
                                            onClick={filterDrawer.onOpen}
                                        />

                                        <Spacer display={{ base: 'none', xl: 'block' }} />

                                        <Button
                                            leftIcon={<DeleteIcon />}
                                            type="button"
                                            variant="special"
                                            size="sm"
                                            onClick={deleteItemModal.onOpen}
                                        >
                                            <FormattedMessage id="common_delete_item_btn" />
                                        </Button>

                                        <Button
                                            leftIcon={<SaveIcon />}
                                            isLoading={isSubmitting}
                                            type="submit"
                                            variant="primary-success"
                                            size="sm"
                                        >
                                            <FormattedMessage id="common_save_changes_button" />
                                        </Button>
                                    </ActionBar>

                                    <Divider />

                                    <SimpleGrid pt={7} pb={5} px={6} columns={{ xl: 4 }} spacingX={6} spacingY={4}>
                                        <ComboboxFormikField
                                            id="thermalItem.bookId"
                                            name="thermalItem.bookId"
                                            label={formLabels.book}
                                            options={thermalDefinitions.definitions.books}
                                            valueKey="id"
                                            labelKey="name"
                                            isFastField
                                            isRequired
                                        />

                                        <ComboboxFormikField
                                            id="thermalItem.pricingPointId"
                                            name="thermalItem.pricingPointId"
                                            label={formLabels.pricingPoint}
                                            info={<FormattedMessage id="common_pricing_point_empty_tooltip" />}
                                            options={thermalDefinitions.definitions.pricingPoints}
                                            labelKey="description"
                                            valueKey="id"
                                            showPlaceholder={true}
                                            placeholderValue={null}
                                            isFastField
                                        />

                                        {itemTypeId !== mappedItemTypes.DSM.id && (
                                            <>
                                                <ComboboxFormikField
                                                    id="thermalItem.gasBasisId"
                                                    name="thermalItem.gasBasisId"
                                                    label={formLabels.gasBasis}
                                                    options={thermalDefinitions.definitions.gasBasis}
                                                    labelKey="description"
                                                    valueKey="id"
                                                    showPlaceholder={true}
                                                    placeholderValue={null}
                                                    isFastField
                                                />

                                                <ComboboxFormikField
                                                    id="thermalItem.cO2BasisId"
                                                    name="thermalItem.cO2BasisId"
                                                    label={formLabels.co2Basis}
                                                    options={thermalDefinitions.definitions.cO2Basis}
                                                    labelKey="description"
                                                    valueKey="id"
                                                    showPlaceholder={true}
                                                    placeholderValue={null}
                                                    isFastField
                                                />
                                            </>
                                        )}

                                        <InputFormikField
                                            id="thermalItem.latitude"
                                            name="thermalItem.latitude"
                                            label={formLabels.latitude}
                                            type="number"
                                            isFastField
                                        />
                                        <InputFormikField
                                            id="thermalItem.longitude"
                                            name="thermalItem.longitude"
                                            label={formLabels.longitude}
                                            type="number"
                                            isFastField
                                        />
                                        <CheckboxFormikField
                                            id="thermalItem.securityId"
                                            name="thermalItem.securityId"
                                            mt={5}
                                        >
                                            {formLabels.master}
                                        </CheckboxFormikField>
                                    </SimpleGrid>

                                    <SubThermalSection
                                        definitions={initialFormValues.subThermals}
                                        onAdd={addSubItemModal.onOpen}
                                        accordionIndex={accordionIndex}
                                        setAccordionIndex={setAccordionIndex}
                                        editDescriptionsConfig={editDescriptionsConfig}
                                        formValues={values}
                                        validateDescription={validateDescription}
                                        onEditDescription={onEditDescription}
                                        onCopyUnit={onCopyUnit}
                                        onDelete={onDeleteSubItemModalOpen}
                                        renderPanelContent={renderPanelContent}
                                        helpPageUrlItemTypeId={helpPageUrlItemTypeId}
                                    />
                                </Form>
                            )}
                        </Formik>

                        {propertiesModal.isOpen && (
                            <ItemPropertiesModal
                                isOpen
                                onClose={propertiesModal.onClose}
                                itemId={selectedThermal.id}
                                portfolio={thermalItem}
                                onNodeOwnershipPercentageUpdate={onNodeOwnershipPercentageUpdate}
                            />
                        )}

                        {deleteItemModal.isOpen && (
                            <ConfirmationModal
                                isOpen
                                onClose={deleteItemModal.onClose}
                                header={<FormattedMessage id="common_delete_modal_heading" values={{ item: 'Item' }} />}
                                hasExtraStep
                                content={
                                    <FormattedMessage
                                        id="common_delete_modal_msg"
                                        values={{ item: selectedThermal.description }}
                                    />
                                }
                                confirmText={
                                    <Box as="span" textTransform="capitalize">
                                        <FormattedMessage id="common_delete" />
                                    </Box>
                                }
                                onConfirm={() => {
                                    deleteItemModal.onClose();
                                    onDeleteItem(selectedThermal);
                                }}
                            />
                        )}

                        {transitionMatrixModal.isOpen && (
                            <TransitionMatrixModal
                                metadata={metadata}
                                selectedThermal={selectedThermal}
                                subThermals={thermalDefinitions?.definitions.subThermals}
                                onClose={transitionMatrixModal.onClose}
                            />
                        )}

                        {deleteSubItemModal.isOpen && (
                            <DeleteRecordModal
                                onClose={onDeleteSubItemModalClose}
                                headerItem={intl.formatMessage({ id: 'common_unit' })}
                                contentMessageItem={subItemForDeletion.description}
                                onConfirm={() => {
                                    onSubItemDelete(subItemForDeletion.id);
                                }}
                            />
                        )}

                        {addSubItemModal.isOpen && (
                            <AddNewUnitModal
                                isOpen
                                thermalItemId={thermalId || thermalItem.properties.id}
                                unitTypes={thermalDefinitions.definitions.unitTypes}
                                dsmLoads={thermalDefinitions.definitions.dsmLoads}
                                updateThermals={updateThermals}
                                onSubmit={createNewUnit}
                                onClose={addSubItemModal.onClose}
                                type={type}
                                itemTypeId={itemTypeId}
                                deliverabilityStatusOptions={
                                    thermalDefinitions.definitions?.deliverabilityStatusOptions
                                }
                            />
                        )}

                        {filterDrawer.isOpen && (
                            <ThermalFilters
                                header={
                                    <FormattedMessage
                                        id="common_filter_heading"
                                        values={{
                                            item: intl.formatMessage({
                                                id: type === 'dsm' ? 'dsm_title' : 'generation_asset_title',
                                            }),
                                        }}
                                    />
                                }
                                onApplyFilters={onApplyFilters}
                                filters={filters}
                                onClose={filterDrawer.onClose}
                            />
                        )}
                    </>
                )}
            </ExpandableItemWrapper>
        </>
    );
};

export default Thermal;
