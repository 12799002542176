import { useMemo } from 'react';
import { SimpleGrid } from '@chakra-ui/react';

import useAutoformTableMetadata from './hooks/useAutoformTableMetadata';
import AutoformInputFormikField from './AutoformInputFormikField';
import { parseColumnSettings, parseDateFormat, parseNumberFormat } from './utils/autoformUtils';
import { DATE_FORMATS_WITHOUT_TIME } from '../../constants/fields';
import { Box } from '@chakra-ui/layout';

const defaultConfig = {};
/**
 * Renders available inputs for visible columns in a table.
 *
 * @param {string} tableId - Id of the table.
 * @param {callback} getInputFormName - Function to generate the input name for forms.
 * @param {'edit'|'add'} mode - Is the input used for editing or creating a record.
 * @param {boolean} skipDescriptionColumnName - In some cases only columns other than
 * the description column need to be displayed.
 * @param {object} config - containing config for this and nested components
 *
 */
const AutoformItemFields = ({
    tableId,
    getInputFormName,
    mode = 'edit',
    skipDescriptionColumnName = true,
    config = defaultConfig,
}) => {
    const { sortedColumns, selectedItem, dropdownOptions, metadata, descriptionColumnName } =
        useAutoformTableMetadata(tableId);
    const { onChange } = config;

    const visibleColumns = useMemo(
        () =>
            sortedColumns
                .filter((cols) => cols.name !== descriptionColumnName || !skipDescriptionColumnName)
                .map((column) => {
                    const settings = parseColumnSettings(column.settings);
                    const dateFormat = parseDateFormat(column);
                    const numberFormat = parseNumberFormat(column);
                    return (
                        <AutoformInputFormikField
                            inputName={getInputFormName(tableId, selectedItem, column.name)}
                            key={column.name}
                            dataType={column.dataTypeDescription}
                            inputType={column.inputTypeDescription}
                            inputLabel={column.displayName}
                            placeholder={column.displayName}
                            dropdownOptions={dropdownOptions[column.id]}
                            settings={settings}
                            formats={{
                                dateFormat,
                                numberFormat,
                            }}
                            showTimeInput={!DATE_FORMATS_WITHOUT_TIME.includes(column.formatCode)}
                            isDisabled={column.isReadOnly || (!metadata.canUpdate && mode === 'edit')}
                            isRequired={column.isRequired}
                            onChange={onChange && onChange(column)}
                        />
                    );
                }),
        [
            sortedColumns,
            dropdownOptions,
            getInputFormName,
            metadata.canUpdate,
            mode,
            selectedItem,
            skipDescriptionColumnName,
            descriptionColumnName,
            tableId,
            onChange,
        ]
    );
    return (
        <>
            {visibleColumns.length > 0 ? (
                <SimpleGrid pt={7} pb={5} columns={{ xl: 3 }} spacingX={6} spacingY={4}>
                    {visibleColumns}
                </SimpleGrid>
            ) : (
                <Box display={{ base: 'block', xl: 'none' }} pb={5} />
            )}
        </>
    );
};

export default AutoformItemFields;
