import { useState } from 'react';
import { Button, Box, Text, Flex } from '@chakra-ui/react';

import { FormattedMessage, useIntl } from 'react-intl';

import { deleteStudies } from '../../services/studies';

import useCommonToast from '../../hooks/useCommonToast';

import MainModal from '../modal/MainModal';

import CustomCheckbox from '../../components/forms/CustomCheckbox';

import { ReactComponent as DeleteIcon } from '../../icons/delete.svg';
import { ReactComponent as RocketIcon } from '../../icons/rocket.svg';

const DeleteStudiesModal = ({ studies, fetchStudies, onClose, onStudiesChange, defaultBooks }) => {
    const [confirmDeletion, setConfirmDeletion] = useState(false);
    const [allowDeletion, setAllowDeletion] = useState(false);
    const [errorOnConfirmDeletion, setErrorOnConfirmDeletion] = useState(false);
    const [errorOnAllowDeletion, setErrorOnAllowDeletion] = useState(false);

    const intl = useIntl();
    const { toast } = useCommonToast();

    const emptyFieldErrorMessage = intl.formatMessage({ id: 'delete_studies_empty_field_error_message' });

    const studiesWithNoFlag = studies.filter((study) => study.doNotDelete === false);
    const studiesWithFlag = studies.filter((study) => study.doNotDelete === true);

    const onConfirmDeletionChange = (event) => {
        const isChecked = event.target.checked;
        setConfirmDeletion(isChecked);
        setErrorOnConfirmDeletion(!isChecked ? true : false);
    };

    const onAllowDeletionChange = (event) => {
        const isChecked = event.target.checked;
        setAllowDeletion(isChecked);
        setErrorOnAllowDeletion(!isChecked ? true : false);
    };

    const onStudiesDelete = async () => {
        const studiesIds = studies.map((study) => study.studyId);

        await deleteStudies(studiesIds);

        onStudiesChange([]);
        fetchStudies({ localBooks: defaultBooks });

        const successMessage = intl.formatMessage({
            id: studiesIds.length === 1 ? 'delete_study_item_success_message' : 'delete_studies_delete_success_message',
        });
        toast(successMessage);

        onClose();
    };

    return (
        <MainModal
            size="4xl"
            scrollBehavior="inside"
            isOpen
            onClose={onClose}
            header={<FormattedMessage id="delete_studies_delete_studies_heading" />}
            content={
                <>
                    <Text size="sm" color="gray.800">
                        <FormattedMessage id="delete_studies_delete_confirmation_message" />
                    </Text>

                    <Box mt={2} px={5}>
                        {studiesWithNoFlag.map(({ studyId, name }) => (
                            <Flex key={studyId} pt={3}>
                                <RocketIcon />
                                <Text size="sm" pl={3}>
                                    {name}
                                </Text>
                            </Flex>
                        ))}
                    </Box>

                    {studiesWithFlag.length > 0 && (
                        <>
                            <Box mt={3}>
                                <Text size="sm" color="text-error">
                                    <FormattedMessage
                                        id="delete_studies_studies_with_flag"
                                        values={{ n: studiesWithFlag.length }}
                                    />
                                </Text>

                                <Box
                                    mt={3}
                                    px={5}
                                    pt={3}
                                    pb={5}
                                    background={errorOnAllowDeletion ? 'red.200' : 'gray.100'}
                                    borderRadius="md"
                                >
                                    {studiesWithFlag.map(({ studyId, name }) => (
                                        <Flex key={studyId} pt={3}>
                                            <RocketIcon />
                                            <Text size="sm" pl={3}>
                                                {name}
                                            </Text>
                                        </Flex>
                                    ))}

                                    <Flex mt={5}>
                                        <CustomCheckbox isChecked={allowDeletion} onChange={onAllowDeletionChange}>
                                            <Text as="span" size="sm" color="black">
                                                <FormattedMessage id="delete_studies_allow_deletion" />
                                            </Text>
                                        </CustomCheckbox>
                                    </Flex>
                                </Box>
                            </Box>

                            {errorOnAllowDeletion && (
                                <Text size="xs" color="text-error" variant="regular" mt={1}>
                                    {emptyFieldErrorMessage}
                                </Text>
                            )}
                        </>
                    )}

                    <Flex
                        mt={5}
                        px={5}
                        py={3}
                        background={errorOnConfirmDeletion ? 'red.200' : 'gray.100'}
                        borderRadius="md"
                    >
                        <CustomCheckbox isChecked={confirmDeletion} onChange={onConfirmDeletionChange}>
                            <Text as="span" size="sm" color="black">
                                <FormattedMessage id="delete_studies_confirm_deletion" />
                            </Text>
                        </CustomCheckbox>
                    </Flex>

                    {errorOnConfirmDeletion && (
                        <Text size="xs" color="text-error" variant="regular" mt={1}>
                            {emptyFieldErrorMessage}
                        </Text>
                    )}
                </>
            }
            footerRightSlot={
                <Button
                    leftIcon={<DeleteIcon />}
                    type="button"
                    variant="special"
                    textTransform="capitalize"
                    onClick={onStudiesDelete}
                    ml={3}
                    isDisabled={!confirmDeletion || (studiesWithFlag.length > 0 && !allowDeletion)}
                >
                    <FormattedMessage id="common_delete" />
                </Button>
            }
        />
    );
};

export default DeleteStudiesModal;
