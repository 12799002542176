import styled from '@emotion/styled/macro';
import { Button } from '@chakra-ui/react';

import letter from '../../../icons/letter-p.svg';

const TableButton = ({ colDef, value, data }) => {
    const colId = Number(colDef.field); // don't use column.colId as it shows the ones in the current viewport

    return (
        <StyledButton
            type="button"
            isChecked={!!value}
            variant="batteries-bidding"
            _dark={{ _disabled: { border: '1px solid', borderColor: 'gray.600' } }}
            onClick={() => {
                colDef.cellRendererParams.onBidChange(data, colId);
            }}
            // if strategy has template other than the 'custom' applied, all values have to be disabled & checked values - grayed out
            disabled={colDef.cellRendererParams.disabled}
        />
    );
};

const StyledButton = styled(Button, { shouldForwardProp: (prop) => prop !== 'isChecked' })`
    background: ${(props) =>
        props.isChecked ? `var(--chakra-colors-green-300) url(${letter})` : 'var(--chakra-colors-border-tertiary)'};

    &:disabled {
        background: ${(props) =>
            props.isChecked
                ? `var(--chakra-colors-gray-400) url(${letter})`
                : 'var(--chakra-colors-batteries-button-unchecked-disabled)'};

        &:hover {
            background: ${(props) =>
                props.isChecked
                    ? `var(--chakra-colors-gray-400) url(${letter})`
                    : 'var(--chakra-colors-batteries-button-unchecked-disabled)'};
        }
    }
`;

export default TableButton;
