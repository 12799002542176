import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { Title } from 'react-head';
import { FormattedMessage } from 'react-intl';
import { useMsal } from '@azure/msal-react';

import { setURLConnectionConfig } from './services/connections';
import useAcquireTokenSilent from './hooks/useAcquireTokenSilent';
import useCustomNavigationClient from './hooks/useCustomNavigationClient';
import useConnectionConfigSetup from './hooks/useConnectionConfigSetup';
import useFetchCommonMetaData from './hooks/useFetchCommonMetaData';

import Dashboard from './pages/Dashboard';
import Jobs from './pages/Jobs';
import Connection from './pages/Connection';
import Environment from './pages/Environment';
import Book from './pages/Book';
import Login from './pages/Login';
import Portfolios from './pages/Portfolios';
import Load from './pages/Loads';
import Batteries from './pages/Batteries';
import Renewables from './pages/Renewables';
import HedgesContracts from './pages/HedgesContracts';
import StudyRun from './pages/StudyRun';
import BasisList from './pages/BasisList';
import ForwardItem from './pages/ForwardItem';
import Autoform from './pages/Autoform';
import BasisConfigurator from './pages/BasisConfigurator';
import BasisSetup from './pages/BasisSetup';
import SpotPrice from './pages/SpotPrice';
import ResourcePlanning from './pages/ResourcePlanning';
import HydroProject from './pages/HydroProject';
import PriceFormula from './pages/PriceFormula';
import MainMarket from './pages/MainMarket';
import Thermals from './pages/Thermals';
import Variables from './pages/Variables';
import ChartViewer from './pages/ChartViewer';
import UserManagement from './pages/UserManagement';
import AdminExpressJobs from './pages/AdminExpressJobs';
import ExpressJobs from './pages/ExpressJobs';
import SpotPriceAutoform from './pages/SpotPriceAutoform';
import WeightSchema from './pages/WeightSchema';
import ShapeSet from './pages/ShapeSet';
import RestorationInputExtract from './pages/RestorationInputExtract';
import EconomicReserve from './pages/EconomicReserve';
import BiddingStrategiesAdmin from './pages/BiddingStrategiesAdmin';

import SplashScreen from './components/layout/SplashScreen';
import StatusBar from './components/layout/StatusBar';
import PrivateRoute from './components/utils/PrivateRoute';
import MainRouterOutlet from './components/utils/MainRouterOutlet';
import NavigationBar from './components/layout/NavigationBar';
import useUserPermissions from './hooks/useUserPermissions';
import useAcquireArcGISToken from './hooks/useAcquireArcGISToken';
import PowerBiReports from './pages/PowerBiReports';
import { reset } from './store/powerBi/powerBiSlice';
import { APP_NAME as appName } from './constants/autoforms';
import GlobalConstraints from './pages/GlobalConstraints';
import RealizedValues from './pages/RealizedValues';
import FuelsAndEmissions from './pages/FuelsAndEmissions';
import { DSM_TYPE_KEY, GENERATION_ASSET_TYPE_KEY } from './constants/thermal';

const App = () => {
    const [isConnectionConfigValid, setIsConnectionConfigValid] = useState(false);

    useAcquireTokenSilent();

    // setup client-side routing for Msal
    useCustomNavigationClient();

    // validate and setup UI version and connection config in axios headers & redux store
    // and redirect based on the auth status
    const { isValidatingConnection, version } = useConnectionConfigSetup();

    // if we have a valid connection retrieve common metadata and feature flags
    useFetchCommonMetaData(isConnectionConfigValid);

    const { accounts } = useMsal();
    const { retrieveUserConnectionPermissions } = useUserPermissions();

    const { isLoading, permissions } = useSelector((state) => state.permissions);
    const connectionConfig = useSelector((state) => state.connections);
    const { canUseRestoration, canUseRestorationLoading, canUseInputDataExtract, canUseInputDataExtractLoading } =
        useSelector((state) => state.helpers);
    const { pathname } = useLocation();
    const [restorationInputExtractTitleID, setTitleID] = useState('restoration_input_extract_title');

    const dispatch = useDispatch();

    // retrieve and register ArcGIS OAuth 2.0 token to view web map and feature layers
    useAcquireArcGISToken();

    useEffect(() => {
        const { connection, environment, book } = connectionConfig;

        if (connection && environment && book) {
            // Connection info found
            setIsConnectionConfigValid(true);
            retrieveUserConnectionPermissions();
            // Reset Power BI state to initialState
            dispatch(reset({ isHardReset: true }));
            redirectToSelectedOrDefautVersion();
        }

        if (canUseRestoration && canUseInputDataExtract) {
            setTitleID('restoration_input_extract_title');
        } else if (!canUseRestoration && canUseInputDataExtract) {
            setTitleID('input_extract_title');
        } else if (canUseRestoration && !canUseInputDataExtract) {
            setTitleID('restoration_title');
        }

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [connectionConfig, retrieveUserConnectionPermissions, canUseRestoration, canUseInputDataExtract]);

    const redirectToSelectedOrDefautVersion = () => {
        const { connection, environment, book, schemaGroupId, isDefaultConfig } = connectionConfig;
        const pathname = window.location.pathname;
        // If user wants to go to "/connection" page then do not redirect
        // Connection page will clear localStorage defaults
        if (pathname?.trim().toLowerCase() === '/connection') {
            return;
        }

        // Check if we have a valid connection from either query strings or from localStorage
        if (connection && environment && book && schemaGroupId) {
            const currentUrl = window.location.origin + process.env.PUBLIC_URL;

            if (version?.url && currentUrl.toUpperCase() !== version.url.toUpperCase()) {
                // Redirect to selected or default UI version's url if it is valid and different from current path url
                const versionName = version?.name?.toString();
                let connectionConfigParams = new URLSearchParams({ versionName });

                if (!isDefaultConfig) {
                    connectionConfigParams = new URLSearchParams({
                        connection,
                        environment,
                        book,
                        schemaGroupId,
                        isDefaultConfig,
                        localAccountId: accounts[0].localAccountId,
                        versionName,
                    });
                }

                const newUrl = version.url;
                console.log('redirecting to version... ', versionName, newUrl);

                const gotoUrl = setURLConnectionConfig(connectionConfigParams, newUrl);
                window.location.assign(gotoUrl.href);
            }
        }
    };

    return (
        <>
            <Title>{appName}</Title>

            {isValidatingConnection || isLoading || canUseRestorationLoading || canUseInputDataExtractLoading ? (
                <SplashScreen />
            ) : (
                <MainRouterOutlet>
                    <NavigationBar />

                    <Switch>
                        {/* in case there's a trailing slash in the url when the user loads the page, it'll be removed, the search query params and anchor are also kept */}
                        <Redirect
                            from="/:url*(/+)"
                            to={{
                                pathname: pathname.slice(0, -1),
                                search: window.location.search,
                                hash: window.location.hash,
                            }}
                        />

                        <PrivateRoute exact path="/">
                            <Title>
                                <FormattedMessage id="common_dashboard" /> - {appName}
                            </Title>

                            <Dashboard />
                        </PrivateRoute>

                        <PrivateRoute exact path="/connection">
                            <Title>
                                <FormattedMessage id="common_connection" /> - {appName}
                            </Title>

                            <Connection />
                        </PrivateRoute>

                        <PrivateRoute exact path="/environment">
                            <Title>
                                <FormattedMessage id="common_environment" /> - {appName}
                            </Title>

                            <Environment />
                        </PrivateRoute>

                        <PrivateRoute exact path="/book">
                            <Title>
                                <FormattedMessage id="common_book" /> - {appName}
                            </Title>

                            <Book />
                        </PrivateRoute>

                        <PrivateRoute exact path="/portfolios">
                            <Title>
                                <FormattedMessage id="portfolios_title" /> - {appName}
                            </Title>

                            <Portfolios />
                        </PrivateRoute>

                        <PrivateRoute path="/loads">
                            <Title>
                                <FormattedMessage id="load_title" /> - {appName}
                            </Title>

                            <Load />
                        </PrivateRoute>

                        <PrivateRoute path="/batteries">
                            <Title>
                                <FormattedMessage id="batteries_title" /> - {appName}
                            </Title>

                            <Batteries />
                        </PrivateRoute>

                        <PrivateRoute path="/bidding-strategies">
                            <Title>
                                <FormattedMessage id="bidding_strategies_title" /> - {appName}
                            </Title>

                            <BiddingStrategiesAdmin />
                        </PrivateRoute>

                        <PrivateRoute path="/wind">
                            <Title>
                                <FormattedMessage id="wind_title" /> - {appName}
                            </Title>

                            <Renewables type="wind" key="wind" />
                        </PrivateRoute>

                        <PrivateRoute path="/solar">
                            <Title>
                                <FormattedMessage id="solar_title" /> - {appName}
                            </Title>

                            <Renewables type="solar" key="solar" />
                        </PrivateRoute>

                        <PrivateRoute path="/hedges-contracts">
                            <Title>
                                <FormattedMessage id="hedges_and_contracts_title" /> - {appName}
                            </Title>

                            <HedgesContracts />
                        </PrivateRoute>

                        <PrivateRoute path="/thermal">
                            <Title>
                                <FormattedMessage id="generation_asset_title" /> - {appName}
                            </Title>

                            <Thermals type={GENERATION_ASSET_TYPE_KEY} key={GENERATION_ASSET_TYPE_KEY} />
                        </PrivateRoute>

                        <PrivateRoute path="/dsm">
                            <Title>
                                <FormattedMessage id="dsm_title" /> - {appName}
                            </Title>

                            <Thermals type={DSM_TYPE_KEY} key={DSM_TYPE_KEY} />
                        </PrivateRoute>

                        <PrivateRoute path="/basis">
                            <Title>
                                <FormattedMessage id="basis_title" /> - {appName}
                            </Title>

                            <BasisList />
                        </PrivateRoute>

                        <PrivateRoute path="/basis-configurator/:basisId">
                            <Title>
                                <FormattedMessage id="basis_configurator_title" /> - {appName}
                            </Title>

                            <BasisConfigurator />
                        </PrivateRoute>

                        <PrivateRoute path="/basis-configurator">
                            <Title>
                                <FormattedMessage id="basis_configurator_title" /> - {appName}
                            </Title>

                            <BasisSetup />
                        </PrivateRoute>

                        <PrivateRoute path="/forward-item/:forwardItemId">
                            <Title>
                                <FormattedMessage id="forward_curve_item_title" /> - {appName}
                            </Title>

                            <ForwardItem />
                        </PrivateRoute>

                        <PrivateRoute path="/main-market/:mainMarketId">
                            <Title>
                                <FormattedMessage id="main_market_model_title" /> - {appName}
                            </Title>

                            <MainMarket />
                        </PrivateRoute>

                        <PrivateRoute path="/spot-price/:spotPriceId">
                            <Title>
                                <FormattedMessage id="spot_price_page_title" /> - {appName}
                            </Title>

                            <SpotPrice />
                        </PrivateRoute>

                        <PrivateRoute path="/weighting-schema">
                            <Title>
                                <FormattedMessage id="weight_schema_page_title" /> - {appName}
                            </Title>

                            <WeightSchema />
                        </PrivateRoute>

                        <PrivateRoute path="/price-formula/:priceFormulaId">
                            <Title>
                                <FormattedMessage id="price_formula_page_title" /> - {appName}
                            </Title>

                            <PriceFormula />
                        </PrivateRoute>

                        <PrivateRoute exact path="/run-a-study">
                            <Title>
                                <FormattedMessage id="common_run_a_study" /> - {appName}
                            </Title>

                            <StudyRun />
                        </PrivateRoute>

                        <PrivateRoute path="/jobs">
                            <Title>
                                <FormattedMessage id="jobs_title" /> - {appName}
                            </Title>

                            <Jobs />
                        </PrivateRoute>

                        <PrivateRoute path="/variables">
                            <Title>
                                <FormattedMessage id="variables_title" /> - {appName}
                            </Title>

                            <Variables />
                        </PrivateRoute>

                        <PrivateRoute path="/charts">
                            <Title>
                                <FormattedMessage id="charts_title" /> - {appName}
                            </Title>

                            <ChartViewer />
                        </PrivateRoute>

                        {permissions.includes('POWERSIMM_ADMIN') && (
                            <PrivateRoute path="/admin-express-jobs">
                                <Title>
                                    <FormattedMessage id="admin_express_jobs_title" /> - {appName}
                                </Title>

                                <AdminExpressJobs />
                            </PrivateRoute>
                        )}
                        <PrivateRoute path="/express-jobs">
                            <Title>
                                <FormattedMessage id="express_jobs_title" /> - {appName}
                            </Title>

                            <ExpressJobs />
                        </PrivateRoute>

                        <PrivateRoute path="/autoform/:autoformId">
                            <Autoform />
                        </PrivateRoute>

                        <PrivateRoute path="/resource-planning">
                            <ResourcePlanning />
                        </PrivateRoute>

                        <PrivateRoute path="/economic-reserve">
                            <EconomicReserve />
                        </PrivateRoute>

                        <PrivateRoute path="/hydro-project">
                            <HydroProject />
                        </PrivateRoute>

                        <PrivateRoute path="/user-management">
                            <Title>
                                <FormattedMessage id="user_management_title" /> - {appName}
                            </Title>

                            <UserManagement />
                        </PrivateRoute>

                        <PrivateRoute path="/realized-values">
                            <RealizedValues />
                        </PrivateRoute>

                        <PrivateRoute exact path="/power_bi_reports/:workspaceId">
                            <Title>
                                <FormattedMessage id="power_bi_reports" />
                            </Title>

                            <PowerBiReports />
                        </PrivateRoute>

                        <PrivateRoute path="/spot-price-autoform">
                            <SpotPriceAutoform />
                        </PrivateRoute>

                        {(canUseRestoration || canUseInputDataExtract) && (
                            <PrivateRoute path="/restoration-input-extract">
                                <Title>
                                    <FormattedMessage id={restorationInputExtractTitleID} /> - {appName}
                                </Title>

                                <RestorationInputExtract />
                            </PrivateRoute>
                        )}

                        <PrivateRoute path="/fuels-and-emissions">
                            <FuelsAndEmissions />
                        </PrivateRoute>

                        <Route exact path="/login">
                            <Title>
                                <FormattedMessage id="login_sign_in_title" /> - {appName}
                            </Title>

                            <Login />
                        </Route>

                        <PrivateRoute path="/shape-set">
                            <ShapeSet />
                        </PrivateRoute>

                        <PrivateRoute path="/global-constraints">
                            <GlobalConstraints />
                        </PrivateRoute>

                        <Redirect to={{ pathname: '/', search: window.location.search }} />
                    </Switch>

                    <StatusBar />
                </MainRouterOutlet>
            )}
        </>
    );
};

export default App;
