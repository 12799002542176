import axios from 'axios';
import { format, parseISO, sub, startOfDay } from 'date-fns';

import { getItem, setItem } from '../storage';
import { msalInstance } from '../auth';

import { enUSFormatExcludingSeconds, enUSFormatExcludingTime } from '../items';
import { mapPortfolios } from '../portfolios';

export const getVariables = async () => {
    const response = await axios.get('/variables/variables');
    return response.data;
};

export const getVariablesSources = async () => {
    const response = await axios.get('/variables/sources');
    return response.data;
};

export const createVariableGroup = async (payload) => {
    const response = await axios.post('/variables/groups', payload);
    return response.data;
};

export const getTemplates = async () => {
    const response = await axios.get('/studies/configuration-options');
    return response.data;
};

export const getStudyData = async (id) => {
    const response = await axios.get(`/studies/${id}`);
    return {
        ...response.data,
        portfolios: response.data.portfolios ? mapPortfolios(response.data.portfolios) : [],
    };
};

export const getVariableStatistics = async (variableId, groupId) => {
    const response = await axios.get(`/variables/${groupId}/${variableId}/statistics`);
    return response.data;
};

export const getParentStudyInformation = async (parentStudyId) => {
    const response = await axios.get(`/studies/${parentStudyId}/parent-info`);
    return response.data;
};

export const setVariableStatistics = (payload) => axios.post(`/variables/statistics`, payload);

export const editVariableGroup = async (variableGroupId, payload) => {
    const response = await axios.put(`/variables/${variableGroupId}/groups`, payload);
    return response.data;
};

export const deleteVariableGroup = (variableGroupId) => axios.delete(`/variables/${variableGroupId}/group`);

export const copyVariableGroup = async (variableGroupId) => {
    const response = await axios.post(`/variables/${variableGroupId}/copy-group`);
    return response.data;
};

export const sortVariableGroupsByDescription = (variableGroups) => {
    return variableGroups.slice().sort((a, b) => a.description.localeCompare(b.description));
};

export const getStudyResults = async () => {
    const response = await axios.get('/studies/dropdown-options');
    return response.data;
};

export const getStudyValidationRules = async () => {
    const response = await axios.get('/studies/validation-rules');
    return response.data;
};

export const getStudies = async (payload) => {
    const response = await axios.post(`studies/studies`, payload);
    return {
        totalCount: response.data.length,
        data: response.data,
    };
};

export const getJobs = async (payload) => {
    const response = await axios.post(`studies/jobs`, payload);
    return {
        totalCount: response.data.length,
        data: response.data,
    };
};

export const updateStudy = async (studyId, payload) => {
    await axios.put(`studies/${studyId}`, payload);
};

export const exportStudies = async (payload) => {
    const config = { responseType: 'blob' };
    const response = await axios.post(`/studies/export`, payload, config);
    const filename = 'Studies.xlsx';

    return {
        file: response.data,
        name: filename,
    };
};

export const exportStudiesJobs = async (payload) => {
    const config = { responseType: 'blob' };
    const response = await axios.post(`/studies/jobs/export`, payload, config);
    const filename = 'StudiesJobs.xlsx';

    return {
        file: response.data,
        name: filename,
    };
};

const studyStatuses = [
    {
        label: null,
        style: { badgeVariant: 'gray', color: 'gray.600', darkColor: 'gray.300' },
    },
    {
        label: 'Running...',
        style: { badgeVariant: 'green', color: 'gray.900', darkColor: 'gray.100' },
    },
    {
        label: 'Running',
        style: { badgeVariant: 'green', color: 'gray.900', darkColor: 'gray.100' },
    },
    {
        label: 'Waiting to start/continue',
        style: { badgeVariant: 'blue', color: 'gray.900', darkColor: 'gray.100' },
    },
    {
        label: 'Failed',
        style: { badgeVariant: 'red', color: 'red.900', darkColor: 'red.400' },
    },
    {
        label: 'Failure',
        style: { badgeVariant: 'red', color: 'red.900', darkColor: 'red.400' },
    },
    {
        label: 'Deleted',
        style: { badgeVariant: 'gray', color: 'gray.600', darkColor: 'gray.300' },
    },
    {
        label: 'Cannot continue',
        style: { badgeVariant: 'gray', color: 'gray.600', darkColor: 'gray.300' },
    },
    {
        label: 'Completed',
        style: { badgeVariant: 'solid', colorScheme: 'green', color: 'gray.900', darkColor: 'gray.100' },
    },
    {
        label: 'Stopped',
        style: { badgeVariant: 'gray', color: 'gray.600', darkColor: 'gray.300' },
    },
    {
        label: 'Archived or deleted',
        style: { badgeVariant: 'gray', color: 'gray.600', darkColor: 'gray.300' },
    },
    {
        label: 'Archived',
        style: { badgeVariant: 'gray', color: 'gray.600', darkColor: 'gray.300' },
    },
    {
        label: 'Portfolio Parser Setup',
        style: { badgeVariant: 'gray', color: 'gray.600', darkColor: 'gray.300' },
    },
    {
        label: 'Completed Successfully',
        style: { badgeVariant: 'solid', colorScheme: 'green', color: 'gray.900', darkColor: 'gray.100' },
    },
    {
        label: 'Being Assigned',
        style: { badgeVariant: 'blue', color: 'gray.900', darkColor: 'gray.100' },
    },
    {
        label: 'Pending Validation',
        style: { badgeVariant: 'blue', color: 'gray.900', darkColor: 'gray.100' },
    },
    {
        label: 'Submitted (Low Priority)',
        style: { badgeVariant: 'blue', color: 'gray.900', darkColor: 'gray.100' },
    },
    {
        label: 'Submitted (Below Normal Priority)',
        style: { badgeVariant: 'blue', color: 'gray.900', darkColor: 'gray.100' },
    },
    {
        label: 'Submitted (Above Normal Priority)',
        style: { badgeVariant: 'blue', color: 'gray.900', darkColor: 'gray.100' },
    },
    {
        label: 'Submitted (Normal Priority)',
        style: { badgeVariant: 'blue', color: 'gray.900', darkColor: 'gray.100' },
    },
    {
        label: 'Submitted (High Priority)',
        style: { badgeVariant: 'blue', color: 'gray.900', darkColor: 'gray.100' },
    },
    {
        label: 'Submitted (Uber priority)',
        style: { badgeVariant: 'blue', color: 'gray.900', darkColor: 'gray.100' },
    },
];

export const formatBook = (params, books) => {
    const book = books?.find((item) => item.id === params.value);

    if (book) {
        return book.name ? book.name : book.description ? book.description : '';
    }

    return '';
};

export const getStudyStatusStyle = (status, isDarkMode) => {
    const mappedStatus = studyStatuses.find((s) => s.label === status);

    // apply the gray styles for unknown statuses
    return mappedStatus || studyStatuses.find((s) => s.label === null);
};

export const formatStudyHours = (params) => {
    return parseFloat(params.value).toFixed(2);
};

export const formatStudyName = (params, studies) => {
    const { studyId } = params.node.data;
    const study = studies?.find((item) => item.id === studyId);
    return study ? study.description : '';
};

export const formatModule = (params, modules) => {
    const module = modules?.find((item) => item.id === params.value);
    return module ? module.description : '';
};

export const formatSensitivity = (params, sensitivities) => {
    const sensitivity = sensitivities?.find((item) => item.id === params.value);
    return sensitivity ? sensitivity.description : '';
};

export const sortStudyStatus = (valA, valB, nodeA, nodeB) => {
    if (valA === valB) {
        return nodeA.data.percentComplete - nodeB.data.percentComplete;
    }
    return valA > valB ? 1 : -1;
};

export const setFiltersConfig = (config, item) => {
    const accounts = msalInstance.getAllAccounts();

    if (accounts.length) {
        const filters = getItem(item) || {};

        setItem(item, {
            ...filters,
            [accounts[0].localAccountId]: config,
        });
    }
};

const DefaultScheduledRunDateTimeMonthsFrom = 3;

export const getDefaultScheduledRunDateTimeFrom = () => {
    // Returns default scheduledRunDateTimeFrom
    const todayAtMidnight = startOfDay(new Date());
    // Subtract 3 months from Today 12:00 AM
    const defaultMonthsBackDate = sub(todayAtMidnight, { months: DefaultScheduledRunDateTimeMonthsFrom });
    return defaultMonthsBackDate;
};

export const getFiltersConfig = () => {
    const accounts = msalInstance.getAllAccounts();

    //Returns default scheduledRunDateTimeFrom
    let defaultMonthsBackDate = getDefaultScheduledRunDateTimeFrom();

    if (accounts.length) {
        const accountId = accounts[0].localAccountId;
        const filters = getItem(`studyFilters`);

        if (filters && filters[accountId]) {
            let { scheduledRunDateTimeFrom, scheduledRunDateTimeTo, ...rest } = filters[accountId];

            // <FormattedMessage id="study_tracker_drawer_field_run_date" />
            // If threeMonthsAgo>scheduledRunDateTimeTo, don't set default to the scheduledRunDateTimeFrom
            // scheduledRunDateTimeTo is before defaultMonthsBackDate then we hope user has scheduledRunDateTimeFrom
            if (scheduledRunDateTimeTo && defaultMonthsBackDate > new Date(scheduledRunDateTimeTo)) {
                defaultMonthsBackDate = null;
            }

            // default to three Month Ago if scheduledRunDateTimeFrom is not set in local storage
            scheduledRunDateTimeFrom = scheduledRunDateTimeFrom
                ? parseISO(scheduledRunDateTimeFrom)
                : defaultMonthsBackDate;

            scheduledRunDateTimeTo = scheduledRunDateTimeTo && parseISO(scheduledRunDateTimeTo);

            return { scheduledRunDateTimeFrom, scheduledRunDateTimeTo, ...rest };
        }
    }
    const defaultscheduledRunDateTimeFrom = defaultMonthsBackDate;
    const defaultFilters = { scheduledRunDateTimeFrom: defaultscheduledRunDateTimeFrom };

    return defaultFilters;
};

export const getJobFiltersConfig = () => {
    const accounts = msalInstance.getAllAccounts();

    if (accounts.length) {
        const accountId = accounts[0].localAccountId;
        const filters = getItem(`jobFilters`);

        if (filters && filters[accountId]) {
            return filters[accountId];
        }
    }

    return null;
};

export const deleteStudies = (payload) => axios.delete('/studies', { data: payload });

export const createStudy = async (payload) => {
    const response = await axios.post('/studies/create', payload);
    return response.data;
};

export const getStudyValidationProgress = async (studyId) => {
    const response = await axios.get(`/studies/${studyId}/validation-progress`);
    return response.data;
};

export const skipStudyValidationProgress = (studyId) => axios.get(`/studies/${studyId}/skip-validation-progress`);

export const cancelStudyJobs = async (studyId) => axios.get(`/studies/${studyId}/cancel-study`);

export const getStudyFilterOptions = async () => {
    const response = await axios.get(`studies/study-filters`);
    return response.data;
};

export const getJobFilterOptions = async () => {
    const response = await axios.get(`studies/job-filters`);
    return response.data;
};

export const verifyStudyData = (field, data) => {
    const warnings = [];

    if (field === 'timeIntervals') {
        const creditInterval = data.settings.dateIntervals.find((elem) => elem.description === 'Credit');

        if (data.dateIntervals.includes(creditInterval?.id) && !data.reports.includes('PTDPAYOFF')) {
            warnings.push({ id: 'run_a_study_warning_time_interval_credit' });
        }

        const hourlyInterval = data.settings.dateIntervals.find((elem) => elem.description === 'Hourly');

        if (data.dateIntervals.includes(hourlyInterval?.id)) {
            warnings.push({ id: 'run_a_study_warning_time_interval_hourly' });
        }

        return warnings;
    }

    if (field === 'sensitivity') {
        const baseCaseSensitivity = data.settings.sensitivities.find((elem) => elem.description === 'Base Case');

        if (!data.sensitivities.includes(baseCaseSensitivity?.id)) {
            warnings.push({ id: 'run_a_study_warning_sensitivity_baseCase' });
        }

        return warnings;
    }

    if (field === 'simulations') {
        if (data.simulations > 500) {
            warnings.push({ id: 'run_a_study_warning_simulations' });
        }

        if (data.simulations > data.parentStudySimulations) {
            warnings.push({
                id: 'run_a_study_warning_parent_simulations',
                count: data.parentStudySimulations.toString(),
            });
        }

        return warnings;
    }

    if (field === 'foSimulations') {
        if (data.foSimulations > 500) {
            warnings.push({ id: 'run_a_study_warning_simulations' });
        }

        if (data.foSimulations > data.parentStudyFOSimulations) {
            warnings.push({
                id: 'run_a_study_warning_parent_fo_simulations',
                count: data.parentStudyFOSimulations.toString(),
            });
        }

        return warnings;
    }

    if (field === 'variables') {
        const count = data.variableGroups.map((elem) => elem.variables.length).reduce((count, item) => count + item, 0);

        if (count > 50) {
            warnings.push({ id: 'run_a_study_warning_variables_count', count: count.toString() });
        }

        let allVariables = [];

        for (let group of data.variableGroups) {
            allVariables = allVariables.concat(group.variables);
        }

        const missingMean = allVariables.filter((elem) => !elem.hasMean);

        if (missingMean.length > 0) {
            warnings.push({
                id:
                    missingMean.length > 1
                        ? 'run_a_study_warning_variables_mean_multiple'
                        : 'run_a_study_warning_variables_mean',
                count: missingMean.length.toString(),
            });
        }

        const countIterations = allVariables.filter((elem) => elem.hasIterations);

        if (countIterations.length > 0) {
            warnings.push({
                id:
                    countIterations.length > 1
                        ? 'run_a_study_warning_variables_iterations_multiple'
                        : 'run_a_study_warning_variables_iterations',
                count: countIterations.length.toString(),
            });
        }

        const countMissingStatistics = allVariables.filter((elem) => elem.statisticsCount === 0);

        if (countMissingStatistics.length > 0) {
            warnings.push({
                id:
                    countMissingStatistics.length > 1
                        ? 'run_a_study_warning_variables_no_statistics_multiple'
                        : 'run_a_study_warning_variables_no_statistics',
                count: countMissingStatistics.length.toString(),
            });
        }

        return warnings;
    }

    if (field === 'portfolios') {
        if (data.portfolios.length > 1) {
            warnings.push({ id: 'run_a_study_warning_portfolios_count' });
        }

        return warnings;
    }

    if (field === 'useStudyResults') {
        if (data.parentStudyId === '') {
            warnings.push({ id: 'run_a_study_warning_study_results' });
        }

        return warnings;
    }

    if (field === 'runDate') {
        const dateNow = new Date();
        if ((data.runSchedule - dateNow) / 1000 / 60 > 10) {
            warnings.push({
                id: 'run_a_study_warning_run_date_future',
                date: format(data.runSchedule, enUSFormatExcludingSeconds),
            });
        }

        if (data.runSchedule - dateNow < 0) {
            warnings.push({
                id: 'run_a_study_warning_run_date_past',
                date: format(data.runSchedule, enUSFormatExcludingSeconds),
            });
        }

        return warnings;
    }

    if (field === 'simulationStart') {
        if (data.parentStudyStartDate) {
            const parentDate = new Date(data.parentStudyStartDate);
            const startDate = new Date(data.startDate);
            if (startDate < parentDate) {
                warnings.push({
                    id: 'run_a_study_warning_date_parent',
                    date: format(parentDate, enUSFormatExcludingTime),
                });
            }
        }
    }

    if (field === 'simulationEnd') {
        if (data.parentStudyEndDate) {
            const parentDate = new Date(data.parentStudyEndDate);
            const endDate = new Date(data.endDate);
            if (endDate > parentDate) {
                warnings.push({
                    id: 'run_a_study_warning_date_parent',
                    date: format(parentDate, enUSFormatExcludingTime),
                });
            }
        }
    }

    return warnings;
};

export const getSelectedReportRequirements = (reportRequirements) => {
    const requirements = [];

    if (reportRequirements.ptdPayOff) {
        requirements.push('Prior to Delivery Payoffs Reports');
    }

    if (reportRequirements.simReports) {
        requirements.push('Simulation Validation Reports');
    }

    return requirements.length > 0 ? requirements.join(', ') : '-';
};

export const getSelectedProperties = (properties) => {
    const selectedProperties = properties.reduce((propertyAccumulator, propertyGroup) => {
        propertyGroup.properties.forEach((property) => {
            if (property.selectedValue) {
                const propertyValue =
                    property.type === 'MonthCalendar'
                        ? format(new Date(property.selectedValue), enUSFormatExcludingSeconds)
                        : property.selectedValue;
                propertyAccumulator.push(`${property.name} ${propertyValue}`);
            }
        });

        return propertyAccumulator;
    }, []);

    return selectedProperties;
};

export const getDashboardSummary = async (schemaGroupId) => {
    const response = await axios.get(`/studies/${schemaGroupId}/dashboard-summary`);
    return response.data;
};

export const getTablespaceUsage = async (schemaGroupId) => {
    const response = await axios.get(`/studies/${schemaGroupId}/tablespace-usage`);
    return response.data;
};

export const editJobStatuses = (payload) => axios.put(`/studies/jobs/status`, payload);

export const getDefaultStudySettings = (settings) => {
    const defaultSettings = settings
        .filter((currentSetting) => currentSetting.isSelected)
        .map((currentSetting) => currentSetting.id);
    return defaultSettings;
};

export const formatFilterIds = (filter) => {
    return filter
        .split(',')
        .filter((elem) => elem && !isNaN(elem))
        .map((elem) => parseInt(elem));
};

export const getJobLogs = async (id) => {
    const response = await axios.get(`/job-log/${id}`);
    return response.data;
};

export const getJobParameters = async (id, convertIds = true) => {
    const response = await axios.get(`/studies/jobs/${id}/parameters?convertIds=${convertIds}`);
    return response.data;
};

export const updateJob = async (payload) => await axios.put('/studies/jobs', payload);

export const getStatements = async (id) => {
    const payload = {
        sorting: [],
    };

    const response = await axios.post(`/job-log/${id}/statements`, payload);
    return response.data;
};

export const sortTemplatesChronologically = ([...templates]) => {
    return templates.sort((a, b) => {
        if (a.isTemplate === b.isTemplate) {
            return b.id - a.id;
        } else {
            return a.isTemplate < b.isTemplate ? 1 : -1;
        }
    });
};

export const sortTemplatesAlphabetically = ([...templates]) => {
    return templates.sort((a, b) => {
        if (a.isTemplate === b.isTemplate) {
            return a.description < b.description ? -1 : 1;
        } else {
            return a.isTemplate < b.isTemplate ? 1 : -1;
        }
    });
};

export const getUserSortOrder = () => {
    const accounts = msalInstance.getAllAccounts();

    if (accounts.length) {
        const accountId = accounts[0].localAccountId;
        const sortOrder = getItem(`studyTemplateSortOrder`);

        if (sortOrder && sortOrder[accountId]) {
            return sortOrder[accountId];
        }
    }

    return 'chronological';
};

export const setUserSortOrder = (sortOrder) => {
    const accounts = msalInstance.getAllAccounts();

    if (accounts.length) {
        setItem('studyTemplateSortOrder', {
            [accounts[0].localAccountId]: sortOrder,
        });
    }
};
