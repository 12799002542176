import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { MsalProvider } from '@azure/msal-react';
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';
import axios from 'axios';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import { loadCss } from 'esri-loader';
import { LicenseManager } from 'ag-grid-enterprise';
import { HeadProvider } from 'react-head';

import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';

import { store } from './store';
import en from './lang/en.json';
import theme from './theme/index';
import { msalInstance } from './services/auth';
import AxiosInterceptors from './components/utils/AxiosInterceptors';

import App from './App';

import './index.css';

import './analytics.js';

axios.defaults.baseURL = process.env.REACT_APP_API;

LicenseManager.setLicenseKey(process.env.REACT_APP_GRID_KEY);

loadCss();

const translations = {
    en,
};
ReactDOM.render(
    <React.StrictMode>
        <MsalProvider instance={msalInstance}>
            <Provider store={store}>
                <ChakraProvider theme={theme}>
                    <HeadProvider>
                        <Router basename={process.env.PUBLIC_URL}>
                            <IntlProvider defaultLocale="en" locale="en" messages={translations.en}>
                                <AxiosInterceptors />
                                <ColorModeScript initialColorMode={theme.config.initialColorMode} />
                                <App />
                            </IntlProvider>
                        </Router>
                    </HeadProvider>
                </ChakraProvider>
            </Provider>
        </MsalProvider>
    </React.StrictMode>,
    document.getElementById('root')
);
