//Maps the anchors in Sharepoint help pages to the corresponding help sections
//help anchors for autoforms will reside in the table settings metadata.
//helpAnchors - exact anchor name in the Sharepoint help page. Needs to be updated if any change is made in the Sharepoint help page.

export const helpAnchors = {
    //Basis
    BASIS_PEAK_PERIOD: '#peak-period-tab',
    BASIS_MODEL_DEFINITION: '#model-definition-tab',
    BASIS_FORWARD_PRICE: '#forward-price-tab',
    BASIS_SPOT_PRICE: '#spot-price-tab',
    BASIS_SCALER: '#scalar-tab',
    BASIS_AUTOCREATE: '#autocreate-basis-(power-only)',
    BASIS_SETUP: '#setup-basis',
    //Battery
    BATTERY_DEFINITIONS: '#battery-definitions',
    BATTERY_TIMELINE_EDITOR: '#battery-timeline-editor-tab',
    BATTERY_SCHEDULED_OUTAGES: '#scheduled-outages-tab',
    BATTERY_HISTORICAL_OUTPUT: '#historical-output-tab',
    BATTERY_BIDDING_STRATEGY: '#bidding-strategy-tab',
    BATTERY_NEW_SETUP: '#quick-setup',
    //DSM
    DSM_UNIT_DEFINITIONS: '#individual-unit-resource-pane-(lower-right)',
    DSM_OPERATIONAL_DATES: '#operational-dates-tab',
    DSM_STARTUP_SHUTDOWN: '#start-up-shut-down-tab',
    DSM_RUN_CONSTRAINTS: '#run-constraints-tab',
    DSM_HEAT_RATES: '#heat-rates-tab',
    DSM_FUEL_COST_FUEL_SWITCHING: '#fuel-cost-fuel-switching-tab',
    DSM_MISC_COSTS_EMISSIONS: '#miscellaneous-costs-emissions-tab',
    DSM_GENERATION_CAPACITY: '#generation-capacity-tab',
    DSM_FORCED_OUTAGES: '#forced-outages-tab',
    DSM_ANCILLARY_CONTRIBUTIONS: '#ancillary-contributions-tab',
    DSM_LINKED_RESOURCE: '#linked-resource-tab',
    DSM_SCHEDULED_OUTAGES: '#scheduled-outages-tab-(second-tab-of-lower-pane)',
    DSM_HISTORICAL_OUTPUT: '#historical-output-view',
    //Hedges
    HEDGES_TRANSACTION_SPECIFICS: '#transaction-specifics',
    //Load
    LOAD_DEFINITIONS: '#load-definitions-pane',
    LOAD_DATA: '#data-tab',
    LOAD_FORECAST: '#forecast-tab',
    LOAD_MODEL: '#model-tab',
    LOAD_FILTER_BAR: '#load-filter-bar',
    //Thermal
    THERMAL_UNIT_DEFINITIONS: '#individual-unit-resource-pane-(lower-right)',
    THERMAL_OPERATIONAL_DATES: '#operational-dates-tab%E2%80%8B%E2%80%8B%E2%80%8B%E2%80%8B%E2%80%8B%E2%80%8B%E2%80%8B',
    THERMAL_STARTUP_SHUTDOWN: '#start-up-shut-down-tab',
    THERMAL_RUN_CONSTRAINTS: '#run-constraints-tab',
    THERMAL_HEAT_RATES: '#heat-rates-tab',
    THERMAL_FUEL_COST_FUEL_SWITCHING: '#fuel-cost-fuel-switching-tab',
    THERMAL_MISC_COSTS_EMISSIONS: '#miscellaneous-costs-emissions-tab',
    THERMAL_GENERATION_CAPACITY: '#generation-capacity-tab',
    THERMAL_FORCED_OUTAGES: '#forced-outages-tab',
    THERMAL_ANCILLARY_CONTRIBUTIONS: '#ancillary-contributions-tab',
    THERMAL_LINKED_RESOURCE: '#linked-resource-tab',
    THERMAL_SCHEDULED_OUTAGES: '#scheduled-outages-(second-tab-of-lower-pane)',
    THERMAL_HISTORICAL_OUTPUT: '#historical-output-view',
    //Renewable
    WIND_DEFINITIONS: '#wind-definitions-pane',
    WIND_DATA: '#data-tab',
    WIND_FORECASTS: '#forecast-tab',
    WIND_COSTS: '#costs-tab',
    WIND_ANCILLARIES: '#ancillaries-tab',
    WIND_MODEL: '#model-tab',
    WIND_FILTERING: '#filtering',
};

//List of the unncessary sharepoint tags that need to be removed
export const SHAREPOINT_TAGS = ['SuiteNavWrapper', 'spSiteHeader', 'spCommandBar', 'sp-appBar', 'CommentsWrapper'];

export const SP_COMMENTS_BAR = 'ap_o_ada2ac09 aq_o_ada2ac09';
export const SP_FOOTER = 'Page.SiteFooter.internal.03025612-a400-4804-a78e-e1493200a43b';

export const HELP_SETTINGS_ANCHOR_KEY = 'ANCHOR';
