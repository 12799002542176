import { useEffect, useState } from 'react';
import { Link, Flex } from '@chakra-ui/react';
import { useIntl } from 'react-intl';
import InputFormikField from '../forms/InputFormikField';
import TextareaFormikField from '../forms/TextareaFormikField';
import DateFormikField from '../forms/DateFormikField';
import NumberInputFormikField from '../forms/NumberInputFormikField';
import CheckboxFormikField from '../forms/CheckboxFormikField';
import ComboboxFormikField from '../forms/ComboboxFormikField';
import MultiSelectFormikField from '../forms/MultiSelectFormikField';
import DownloadLogButton from './DownloadLogButton';

import { INPUT_TYPES } from '../../constants/fields';
import { enUSFormatExcludingTime } from '../../services/items';
import { ReactComponent as ExternalLinkIcon } from '../../icons/external-link.svg';

const DynamicFormProperty = ({ groupId, property }) => {
    const {
        id,
        name,
        type,
        propertyValues,
        readOnly,
        minimum,
        maximum,
        increment,
        multiLine,
        minDate,
        maxDate,
        decimalPlaces,
        selectedValue,
        label,
        endpoint,
    } = property;

    const intl = useIntl();
    const [checkListSelectedKeys, setCheckListSelectedKeys] = useState([]);

    useEffect(() => {
        if (type === INPUT_TYPES.CheckListBox) {
            // Initialise selected check list box options for correct initial display
            const initialPropertyValue = selectedValue ? selectedValue.split(',') : [];
            setCheckListSelectedKeys(initialPropertyValue);
        } else {
            setCheckListSelectedKeys([]);
        }
    }, [selectedValue, type]);

    const fieldName = `properties.group_${groupId}.field_${id}`;

    switch (type) {
        case INPUT_TYPES.TextBox:
            return (
                <>
                    {multiLine ? (
                        <TextareaFormikField
                            id={fieldName}
                            name={fieldName}
                            label={name}
                            isReadOnly={readOnly}
                            isDisabled={readOnly}
                        />
                    ) : (
                        <InputFormikField
                            id={fieldName}
                            name={fieldName}
                            label={name}
                            isReadOnly={readOnly}
                            isDisabled={readOnly}
                            type="text"
                        />
                    )}
                </>
            );
        case INPUT_TYPES.ComboBox:
            return (
                <ComboboxFormikField
                    id={fieldName}
                    name={fieldName}
                    label={name}
                    options={propertyValues}
                    labelKey="value"
                    valueKey="key"
                    isDisabled={readOnly}
                />
            );
        case INPUT_TYPES.NumericUpDown:
            return (
                <NumberInputFormikField
                    id={fieldName}
                    name={fieldName}
                    label={name}
                    isDisabled={readOnly}
                    min={minimum || undefined}
                    max={maximum || undefined}
                    step={increment || undefined}
                    precision={decimalPlaces}
                />
            );
        case INPUT_TYPES.CheckBox:
            return (
                <CheckboxFormikField id={fieldName} name={fieldName} isDisabled={readOnly}>
                    {name}
                </CheckboxFormikField>
            );
        case INPUT_TYPES.MonthCalendar:
            return (
                <DateFormikField
                    id={fieldName}
                    name={fieldName}
                    label={name}
                    minDate={minDate}
                    maxDate={maxDate}
                    isDisabled={readOnly}
                    dateFormat={enUSFormatExcludingTime}
                    showTimeInput={false}
                />
            );
        case INPUT_TYPES.LinkLabel:
            const absoluteLink =
                selectedValue && selectedValue.indexOf('http') !== 0 ? `https://${selectedValue}` : selectedValue;
            return (
                <Flex>
                    <ExternalLinkIcon />
                    <Link
                        display="block"
                        size="md"
                        ml={2}
                        target="_blank"
                        rel="external"
                        href={absoluteLink || 'https://www.ascendanalytics.com/'}
                    >
                        {label || selectedValue}
                    </Link>
                </Flex>
            );
        case INPUT_TYPES.CheckListBox:
            return (
                <MultiSelectFormikField
                    id={fieldName}
                    name={fieldName}
                    options={propertyValues}
                    valueKey="key"
                    labelKey="value"
                    showSelectAll={true}
                    isRequired={false}
                    isDisabled={readOnly}
                    label={name}
                    onChange={setCheckListSelectedKeys}
                    multipleSelectedLabel={intl.formatMessage(
                        {
                            id: 'item_properties_multiselect_placeholder',
                        },
                        { count: checkListSelectedKeys?.length }
                    )}
                />
            );
        case INPUT_TYPES.DownloadButton:
            return (
                <DownloadLogButton id={fieldName} label={label} endpoint={endpoint} queryParameters={selectedValue} />
            );
        default:
            return null;
    }
};

export default DynamicFormProperty;
