import { useMemo, useCallback, useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { Flex, Heading, Tabs, TabPanels, TabPanel, TabList, Tab, useDisclosure } from '@chakra-ui/react';

import { getValidRowsWithDates } from '../../services/grid';
import {
    GRID_KEYS,
    getRenewableDataChart,
    createRenewableData,
    getRenewableData,
    updateRenewableData,
    deleteRenewableData,
    deleteAllRenewableData,
    getRenewableDataDataSourceView,
    getRenewableForecastChart,
    createRenewableForecasts,
    getRenewableForecast,
    updateRenewableForecast,
    deleteRenewableForecasts,
    deleteAllRenewableForecasts,
    getRenewableForecastDataSourceView,
    createRenewableCosts,
    getRenewableCosts,
    updateRenewableCosts,
    deleteRenewableCosts,
    deleteAllRenewableCosts,
    getRenewableCostsDataSourceView,
    createRenewableAncillaries,
    getRenewableAncillaries,
    updateRenewableAncillaries,
    deleteRenewableAncillaries,
    deleteAllRenewableAncillaries,
    getRenewableAncillariesDataSourceView,
    createRenewableModels,
    getRenewableModels,
    updateRenewableModels,
    deleteRenewableModels,
    deleteAllRenewableModels,
    getRenewableModelsDataSourceView,
    validateRenewableForecasts,
    validateRenewableData,
} from '../../services/renewables';

import { parseDataFilters, refreshMultisort, exportItemData } from '../../services/items';

import { SortOrderContext } from '../grid/utils/SortOrderContext';

import SubItemData from '../itemData/SubItemData';
import DownloadModal from '../utils/DownloadModal';
import UploadButton from './../itemData/UploadButton';
import Combobox from '../forms/Combobox';
import SecondaryButton from '../../components/utils/SecondaryButton';
import HelpButtonComponent from 'components/utils/HelpButtonComponent';

import { ReactComponent as RefreshIcon } from '../../icons/refresh.svg';
import { ReactComponent as DownloadIcon } from '../../icons/download.svg';

import { helpAnchors } from 'constants/help';

import { FEATURE_FLAG_NAMES, useIsFeatureActive } from '../../hooks/features-flags';

const SubRenewableData = ({ itemLabel, subRenewableId, itemId, itemType, filters, helpPageUrlItemTypeId = null }) => {
    const forecastTypes = useSelector((state) => state.forecast.types);
    const intl = useIntl();
    const downloadModal = useDisclosure();
    const { setSortOrder } = useContext(SortOrderContext);

    const [key, setKey] = useState(0);
    const [selectedTab, setSelectedTab] = useState(0);
    const [loadingQueue, setLoadingQueue] = useState([]); // unique keys represeting which resource is in loading state
    const [forecastType, setForecastType] = useState(forecastTypes.find((type) => type.description === 'Energy').id);

    const isRenewableModelVisible = useIsFeatureActive(FEATURE_FLAG_NAMES.RENEWABLE_MODEL_TAB_VISIBLE);

    const refreshTabs = useCallback(() => {
        setKey((prev) => prev + 1);
        setSortOrder((prev) => ({ ...prev, ...refreshMultisort(Object.values(GRID_KEYS)) }));
    }, [setSortOrder]);

    const onLoadingStart = useCallback((key) => {
        setLoadingQueue((prev) => prev.concat(key));
    }, []);

    const onLoadingEnd = useCallback((key) => {
        setLoadingQueue((prev) => prev.filter((p) => p !== key));
    }, []);

    const dataUnitLabel = intl.formatMessage(
        { id: 'common_item_unit_label' },
        { item: itemLabel.charAt(0).toUpperCase() + itemLabel.slice(1), unit: 'MWh' }
    );

    const dataChartResource = useMemo(() => {
        return {
            read: (pagination) => {
                const payload = {
                    pointCount: pagination.pointCount,
                    startDateFrom: pagination.startDateFrom || filters.startDateFrom,
                    startDateTo: pagination.startDateTo || filters.startDateTo,
                    updateDateFrom: filters.updateDateFrom,
                    updateDateTo: filters.updateDateTo,
                    filterType: filters.filterType,
                };

                return getRenewableDataChart(subRenewableId, payload, itemLabel);
            },
        };
    }, [subRenewableId, filters, itemLabel]);

    const dataColumns = useMemo(
        () => [
            {
                type: 'date',
                field: 'startDate',
                headerName: intl.formatMessage({ id: 'common_grid_start_date' }),
                sort: 'asc',
            },
            {
                type: 'date',
                field: 'endDate',
                headerName: intl.formatMessage({ id: 'common_grid_end_date' }),
            },
            {
                field: 'windOrSolar',
                type: 'number',
                headerName: dataUnitLabel,
            },
            {
                type: 'date',
                field: 'updateDateTime',
                headerName: intl.formatMessage({ id: 'common_grid_created' }),
                sort: 'asc',
            },
        ],
        [intl, dataUnitLabel]
    );

    const dataGridResource = useMemo(() => {
        return {
            create: (rows) => createRenewableData(subRenewableId, rows),
            read: (pagination) => getRenewableData(subRenewableId, { ...pagination, ...filters }),
            update: (rows) => {
                const validRows = getValidRowsWithDates(rows, ['startDate', 'endDate', 'updateDateTime']);

                return updateRenewableData(subRenewableId, validRows);
            },
            delete: (rows) =>
                deleteRenewableData(
                    subRenewableId,
                    rows.map((row) => row.windDataId)
                ),
            deleteAll: () => deleteAllRenewableData(subRenewableId),
            validate: (rows) => validateRenewableData(subRenewableId, rows),
            getDataSourceView: (pagination) =>
                getRenewableDataDataSourceView(subRenewableId, { ...pagination, ...filters }),
        };
    }, [subRenewableId, filters]);

    const forecastChartResource = useMemo(() => {
        return {
            read: (pagination) => {
                const payload = {
                    pointCount: pagination.pointCount,
                    startDateFrom: pagination.startDateFrom || filters.startDateFrom,
                    startDateTo: pagination.startDateTo || filters.startDateTo,
                    updateDateFrom: filters.updateDateFrom,
                    updateDateTo: filters.updateDateTo,
                    filterType: filters.filterType,
                    forecastTypeId: forecastType,
                };

                return getRenewableForecastChart(subRenewableId, payload, itemLabel);
            },
        };
    }, [subRenewableId, filters, forecastType, itemLabel]);

    const forecastColumns = useMemo(
        () => [
            {
                field: 'forecastTypeId',
                type: 'select',
                cellEditorParams: { options: forecastTypes, required: true },
                headerName: intl.formatMessage({ id: 'common_grid_forecast_type' }),
            },
            {
                type: 'date',
                field: 'startDate',
                headerName: intl.formatMessage({ id: 'common_grid_start_date' }),
                sort: 'asc',
            },
            {
                type: 'date',
                field: 'endDate',
                headerName: intl.formatMessage({ id: 'common_grid_end_date' }),
            },
            {
                field: 'expectedEnergy',
                type: 'number',
                headerName: intl.formatMessage(
                    { id: 'common_item_unit_label' },
                    { item: 'Expected Energy', unit: 'MWh' }
                ),
            },
            {
                field: 'expectedPeakGeneration',
                type: 'number',
                headerName: intl.formatMessage(
                    { id: 'common_item_unit_label' },
                    { item: 'Expected Peak Generation', unit: 'MW' }
                ),
            },
            {
                field: 'capacity',
                type: 'number',
                headerName: intl.formatMessage({ id: 'common_item_unit_label' }, { item: 'Capacity', unit: 'MW' }),
            },
            {
                type: 'date',
                field: 'updateDateTime',
                headerName: intl.formatMessage({ id: 'common_grid_created' }),
                sort: 'asc',
            },
        ],
        [intl, forecastTypes]
    );

    const forecastGridResource = useMemo(() => {
        return {
            create: (rows) => createRenewableForecasts(subRenewableId, rows),
            read: (pagination) => getRenewableForecast(subRenewableId, { ...pagination, ...filters }),
            update: (rows) => {
                const validRows = getValidRowsWithDates(rows, ['startDate', 'endDate', 'updateDateTime']);

                return updateRenewableForecast(subRenewableId, validRows);
            },
            delete: (rows) =>
                deleteRenewableForecasts(
                    subRenewableId,
                    rows.map((row) => row.windForecastId)
                ),
            deleteAll: () => deleteAllRenewableForecasts(subRenewableId),
            validate: (rows) => validateRenewableForecasts(subRenewableId, rows),
            getDataSourceView: (pagination) =>
                getRenewableForecastDataSourceView(subRenewableId, { ...pagination, ...filters }),
        };
    }, [subRenewableId, filters]);

    const costsColumns = useMemo(
        () => [
            {
                type: 'date',
                field: 'startDate',
                headerName: intl.formatMessage({ id: 'common_grid_start_date' }),
                sort: 'asc',
            },
            {
                type: 'date',
                field: 'endDate',
                headerName: intl.formatMessage({ id: 'common_grid_end_date' }),
            },
            {
                field: 'fixedCost',
                type: 'number',
                headerName: intl.formatMessage(
                    { id: 'common_item_unit_label' },
                    { item: 'Fixed Cost', unit: '$/month' }
                ),
            },
            {
                field: 'vom',
                type: 'number',
                headerName: intl.formatMessage({ id: 'common_item_unit_label' }, { item: 'VOM', unit: '$/MWh' }),
            },
            {
                field: 'curtailPrice',
                type: 'number',
                headerName: intl.formatMessage({ id: 'common_grid_curtailment_price' }),
            },
            {
                field: 'curtailPercentage',
                type: 'number',
                headerName: intl.formatMessage(
                    { id: 'common_item_unit_label' },
                    { item: 'Curtailment Percentage', unit: '%, 0-100' }
                ),
            },
            {
                type: 'date',
                field: 'updateDateTime',
                headerName: intl.formatMessage({ id: 'common_grid_created' }),
                sort: 'asc',
            },
        ],
        [intl]
    );

    const costsGridResource = useMemo(() => {
        return {
            create: (rows) => createRenewableCosts(subRenewableId, rows),
            read: (pagination) => getRenewableCosts(subRenewableId, { ...pagination }),
            update: (rows) => {
                const validRows = getValidRowsWithDates(rows, ['startDate', 'endDate', 'updateDateTime']);

                return updateRenewableCosts(subRenewableId, validRows);
            },
            delete: (rows) =>
                deleteRenewableCosts(
                    subRenewableId,
                    rows.map((row) => row.windIdCostId)
                ),
            deleteAll: () => deleteAllRenewableCosts(subRenewableId),
            getDataSourceView: (pagination) => getRenewableCostsDataSourceView(subRenewableId, { ...pagination }),
        };
    }, [subRenewableId]);

    const ancillariesColumns = useMemo(
        () => [
            {
                type: 'date',
                field: 'startDate',
                headerName: intl.formatMessage({ id: 'common_grid_start_date' }),
                sort: 'asc',
            },
            {
                type: 'date',
                field: 'endDate',
                headerName: intl.formatMessage({ id: 'common_grid_end_date' }),
            },
            {
                type: 'checkbox',
                field: 'canContributeContingent',
                headerName: intl.formatMessage({ id: 'common_grid_can_contribute_contingent' }),
            },
        ],
        [intl]
    );

    const ancillariesGridResource = useMemo(() => {
        return {
            create: (rows) => createRenewableAncillaries(subRenewableId, rows),
            read: (pagination) => getRenewableAncillaries(subRenewableId, { ...pagination }),
            update: (rows) => {
                const validRows = getValidRowsWithDates(rows, ['startDate', 'endDate']);

                return updateRenewableAncillaries(subRenewableId, validRows);
            },
            delete: (rows) =>
                deleteRenewableAncillaries(
                    subRenewableId,
                    rows.map((row) => row.windAncillaryId)
                ),
            deleteAll: () => deleteAllRenewableAncillaries(subRenewableId),
            getDataSourceView: (pagination) => getRenewableAncillariesDataSourceView(subRenewableId, pagination),
        };
    }, [subRenewableId]);

    const modelColumns = useMemo(
        () => [
            {
                type: 'checkbox',
                field: 'useFlag',
                headerName: intl.formatMessage({ id: 'common_grid_use' }),
            },
            {
                field: 'description',
                headerName: intl.formatMessage({ id: 'common_grid_model_description' }),
            },
            {
                field: 'formulaStatement',
                headerName: intl.formatMessage({ id: 'common_grid_formula_statement' }),
            },
            {
                field: 'runOrder',
                type: 'number',
                headerName: intl.formatMessage({ id: 'common_grid_run_order' }),
            },
        ],
        [intl]
    );

    const modelsGridResource = useMemo(() => {
        return {
            create: (rows) => createRenewableModels(subRenewableId, rows),
            read: (pagination) => getRenewableModels(subRenewableId, { ...pagination }),
            update: (rows) => updateRenewableModels(subRenewableId, rows),
            delete: (rows) =>
                deleteRenewableModels(
                    subRenewableId,
                    rows.map((row) => row.windModelId)
                ),
            deleteAll: () => deleteAllRenewableModels(subRenewableId),
            getDataSourceView: (pagination) => getRenewableModelsDataSourceView(subRenewableId, { ...pagination }),
        };
    }, [subRenewableId]);

    const phrasing = useMemo(
        () => ({
            create: {
                header: <FormattedMessage id="common_add_rows_heading" />,
                content: <FormattedMessage id="common_add_rows_sub_heading_without_date" />,
            },
        }),
        []
    );

    const onDownloadData = useCallback(
        (exportTemplate, sortOrder = {}) => {
            const payload = {
                ...parseDataFilters(filters),
                forecastTypeId: forecastType,
                dataSorting: sortOrder[GRID_KEYS.renewableData],
                forecastSorting: sortOrder[GRID_KEYS.renewableForecast],
                costsSorting: sortOrder[GRID_KEYS.renewableCosts],
                ancillariesSorting: sortOrder[GRID_KEYS.renewableAncillaries],
                modelsSorting: sortOrder[GRID_KEYS.renewableModel],
                exportTemplate,
            };

            return exportItemData(`renewables/${itemId}/export`, payload);
        },
        [itemId, forecastType, filters]
    );

    return (
        <Tabs
            px={0}
            mt={6}
            isLazy
            isManual
            lazyBehavior="keepMounted"
            key={key}
            index={selectedTab}
            onChange={setSelectedTab}
        >
            <TabList w="100%" flexWrap={{ base: 'wrap' }} alignItems="center">
                <Tab>
                    <FormattedMessage id="common_historical_data_data" />
                </Tab>

                <Tab>
                    <FormattedMessage id="common_historical_data_forecast" />
                </Tab>

                <Tab>
                    <FormattedMessage id="common_historical_data_costs" />
                </Tab>

                <Tab>
                    <FormattedMessage id="common_historical_data_ancillaries" />
                </Tab>

                {isRenewableModelVisible && (
                    <Tab>
                        <FormattedMessage id="common_historical_data_model" />
                    </Tab>
                )}

                <Flex
                    ml={{ base: 'auto' }}
                    mb={{ base: 1 }}
                    order={{ base: -1, xl: 0 }}
                    w={{ base: '100%', xl: 'auto' }}
                    flexWrap={{ base: 'wrap' }}
                >
                    <SecondaryButton
                        leftIcon={<DownloadIcon />}
                        w="auto !important"
                        ml={{ base: 0 }}
                        mr={{ base: 2 }}
                        mb={{ base: 1 }}
                        mt={{ base: 1 }}
                        size="sm"
                        type="button"
                        variant="secondary"
                        onClick={downloadModal.onOpen}
                    >
                        <FormattedMessage id="common_download_btn_tooltip_and_label" />
                    </SecondaryButton>

                    <UploadButton
                        type="renewables"
                        itemId={itemId}
                        hasLabel={true}
                        onAfterUploadChange={refreshTabs}
                        ml={{ base: 0 }}
                        mr={{ base: 2 }}
                        mb={{ base: 1 }}
                        mt={{ base: 1 }}
                        w="auto !important"
                    />

                    <SecondaryButton
                        onClick={refreshTabs}
                        size="sm"
                        variant="secondary"
                        leftIcon={<RefreshIcon />}
                        isLoading={loadingQueue.length > 0}
                        loadingText={intl.formatMessage({ id: 'common_loading' })}
                        textTransform="capitalize"
                        mr={{ base: 2 }}
                        mb={{ base: 1 }}
                        mt={{ base: 1 }}
                    >
                        <FormattedMessage id="common_refresh" />
                    </SecondaryButton>
                </Flex>
            </TabList>

            <TabPanels mt={4}>
                <TabPanel>
                    <Heading as="h3" variant="h3" textTransform="capitalize">
                        <FormattedMessage id="common_historical_data_heading" values={{ item: itemLabel }} />
                        <HelpButtonComponent helpAnchor={helpAnchors.WIND_DATA} itemTypeId={helpPageUrlItemTypeId} />
                    </Heading>

                    <SubItemData
                        hasChart
                        tab="0"
                        name={GRID_KEYS.renewableData}
                        yAxisLabel={dataUnitLabel}
                        chartResource={dataChartResource}
                        onLoadingStart={onLoadingStart}
                        onLoadingEnd={onLoadingEnd}
                        gridResource={dataGridResource}
                        columns={dataColumns}
                        addDataColumns={dataColumns}
                    />
                </TabPanel>

                <TabPanel>
                    <Heading as="h3" variant="h3" textTransform="capitalize">
                        <FormattedMessage id="common_historical_data_forecast_heading" values={{ item: itemLabel }} />
                        <HelpButtonComponent
                            helpAnchor={helpAnchors.WIND_FORECASTS}
                            itemTypeId={helpPageUrlItemTypeId}
                        />
                    </Heading>

                    <SubItemData
                        hasChart
                        actionBarSlot={(index) =>
                            index === 0 ? (
                                <Combobox
                                    width="var(--chakra-sizes-2xs) !important"
                                    options={forecastTypes}
                                    valueKey="id"
                                    labelKey="description"
                                    isControlled={true}
                                    showPlaceholder={false}
                                    value={forecastType}
                                    onChange={setForecastType}
                                />
                            ) : null
                        }
                        tab="1"
                        name={GRID_KEYS.renewableForecast}
                        yAxisLabel={dataUnitLabel}
                        chartResource={forecastChartResource}
                        onLoadingStart={onLoadingStart}
                        onLoadingEnd={onLoadingEnd}
                        gridResource={forecastGridResource}
                        columns={forecastColumns}
                        addDataColumns={forecastColumns}
                        primaryKeyName="windForecastId"
                    />
                </TabPanel>

                <TabPanel>
                    <Heading as="h3" variant="h3" textTransform="capitalize">
                        <FormattedMessage id="common_historical_data_costs_heading" values={{ item: itemLabel }} />
                        <HelpButtonComponent helpAnchor={helpAnchors.WIND_COSTS} itemTypeId={helpPageUrlItemTypeId} />
                    </Heading>

                    <SubItemData
                        tab="2"
                        name={GRID_KEYS.renewableCosts}
                        onLoadingStart={onLoadingStart}
                        onLoadingEnd={onLoadingEnd}
                        gridResource={costsGridResource}
                        columns={costsColumns}
                        addDataColumns={costsColumns}
                    />
                </TabPanel>

                <TabPanel>
                    <Heading as="h3" variant="h3" textTransform="capitalize">
                        <FormattedMessage
                            id="common_historical_data_ancillaries_heading"
                            values={{ item: itemLabel }}
                        />
                        <HelpButtonComponent
                            helpAnchor={helpAnchors.WIND_ANCILLARIES}
                            itemTypeId={helpPageUrlItemTypeId}
                        />
                    </Heading>

                    <SubItemData
                        tab="3"
                        name={GRID_KEYS.renewableAncillaries}
                        onLoadingStart={onLoadingStart}
                        onLoadingEnd={onLoadingEnd}
                        gridResource={ancillariesGridResource}
                        columns={ancillariesColumns}
                        addDataColumns={ancillariesColumns}
                    />
                </TabPanel>

                <TabPanel>
                    <Heading as="h3" variant="h3" textTransform="capitalize">
                        <FormattedMessage id="common_historical_data_model_heading" values={{ item: itemLabel }} />
                        <HelpButtonComponent helpAnchor={helpAnchors.WIND_MODEL} itemTypeId={helpPageUrlItemTypeId} />
                    </Heading>

                    <SubItemData
                        tab="4"
                        name={GRID_KEYS.renewableModel}
                        onLoadingStart={onLoadingStart}
                        onLoadingEnd={onLoadingEnd}
                        gridResource={modelsGridResource}
                        phrasing={phrasing}
                        columns={modelColumns}
                        addDataColumns={modelColumns}
                    />
                </TabPanel>
            </TabPanels>

            {downloadModal.isOpen && (
                <DownloadModal
                    isOpen
                    onClose={downloadModal.onClose}
                    onDownloadData={(sortOrder) => onDownloadData(false, sortOrder)}
                    onDownloadTemplate={() => onDownloadData(true)}
                />
            )}
        </Tabs>
    );
};

export default SubRenewableData;
