import axios from 'axios';

import { COLUMN_TYPES, INPUT_TYPES } from '../../constants/fields';
import { MULTIPLE_PLACEHOLDER_VALUE, multisortPairs } from '../../constants/thermal';

import thermalPlaceholder from '../../images/thermal-unselected.png';
import dsmPlaceholder from '../../images/dsm-unselected.png';

import { msalInstance } from '../auth';
import { getItem } from '../storage';

export const placeholderImages = {
    generation_asset: thermalPlaceholder,
    dsm: dsmPlaceholder,
};

// could be replaced with Lodash camelCase() if there are problems
const lowerFirstLetter = (string) => string.charAt(0).toLowerCase() + string.slice(1);

const getKeyBySubstring = (obj, target) => Object.keys(obj).find((key) => key.includes(target));

export const getColumnType = (propertyTypeDescription) => COLUMN_TYPES[propertyTypeDescription];

export const getInputType = (propertyTypeDescription) => INPUT_TYPES[propertyTypeDescription];

export const getField = (propertyName, type) => {
    const isAbbreviation = propertyName === propertyName.toUpperCase();
    const name = isAbbreviation ? propertyName.toLowerCase() : lowerFirstLetter(propertyName);

    return type === COLUMN_TYPES.ComboBox ? getKeyBySubstring(multisortPairs, name) : name;
};

export const getCellEditorParams = (metadata, propertyOptions, type, required = false) => {
    const params = {
        select: {
            options: propertyOptions ? metadata.dropdowns[lowerFirstLetter(propertyOptions)] : null,
            required,
        },
        date: {
            required,
        },
        text: {
            required,
        },
        number: {
            required,
        },
    };

    return params[type];
};

export const generateColumns = (metadata, gridKey, options = {}) => {
    const columns = metadata.columns[gridKey];
    const tabName = getResourceVariablesTabName(gridKey);

    return columns.map((col) => {
        const type = getColumnType(col.propertyTypeDescription);
        const field = getField(col.propertyName, type);
        const cellEditorParams =
            getCellEditorParams(metadata, col.propertyOptions, type, options.requiredFields?.includes(field)) || {};
        const editable = !options.uneditableColumns?.includes(field);
        const hide = options.bulkUpdateFields
            ? editable && !options.bulkUpdateFields?.[tabName]?.[field].includes('multiple')
            : false;

        return {
            field,
            type,
            headerName: col.displayLabel,
            sort: options.field === field ? options.sort : '',
            editable,
            minWidth: 180,
            cellEditorParams,
            hide,
        };
    });
};

export const getThermalData = async (itemId) => {
    const response = await axios.get(`/thermal/${itemId}`, { suppressErrorMessageHandler: true });
    return response.data;
};

export const getThermalMetadata = async (camelCaseProperties = false) => {
    const response = await axios.get(`/thermal/metadata?camelCaseProperties=${camelCaseProperties}`, {
        suppressErrorMessageHandler: true,
    });
    return response.data;
};

export const updateThermal = async (payload) => {
    const response = await axios.put(`/thermal/modify-thermal`, payload, { suppressErrorMessageHandler: true });

    if (response?.status === axios.HttpStatusCode.MultiStatus) {
        // Status 207 MultiStatus
        const multiStatus = response?.data?.multiStatus;
        if (Array.isArray(multiStatus)) {
            // Status 200 OK
            const data = multiStatus.find((s) => s.statusCode === axios.HttpStatusCode.Ok)?.value;
            // Status 424 FailedDependency
            const arcGisIssue = multiStatus.find(
                (s) => s.statusCode === axios.HttpStatusCode.FailedDependency && s?.value?.isArcGisError
            )?.value?.error;
            return { data, arcGisIssue };
        }
    }

    return response;
};

export const createNewUnit = (payload) => axios.post(`/thermal/create`, payload, { suppressErrorMessageHandler: true });

export const validateNewUnit = async (payload) => {
    const response = await axios.post(`/thermal/create/validation`, payload);
    return response.data;
};

export const copyThermal = async (itemId) => {
    const response = await axios.post(`/thermal/${itemId}/copy-item`);
    return response.data;
};

export const deleteSubThermal = (subThermalId, config) => axios.delete(`/thermal/${subThermalId}`, config);

export const copyUnit = async (subThermalId) => {
    const response = await axios.post(`/thermal/${subThermalId}/copy`);
    return response.data;
};

export const mapDefinitionsConfig = (
    definitions,
    itemId,
    itemTypeId,
    isCAMSliceOfDayVisible
) => {
    return definitions.map((def) => {
        const config = {};
        config['subThermalId'] = def.id; //BE expects sub thermal id as 'thermalId'
        config['description'] = def.description || '';
        config['unitTypeId'] = def.unitTypeId;
        if (itemTypeId !== 2) {
            config['dsmLoadId'] = def.dsmLoadId; // dsmLoadId is excluded in Thermal(Generation asset)
        }
        config['itemId'] = itemId;
        if (isCAMSliceOfDayVisible) {
            config['deliverabilityStatusId'] = def?.deliverabilityStatusId || null;
            config['pcdsPercent'] = def?.pcdsPercent || null;
        }

        return config;
    });
};

// transition matrix
export const getTransitionMatrixPeriods = async (itemId, payload) => {
    const response = await axios.post(`/thermal/${itemId}/matrix-periods`, payload, {
        suppressErrorMessageHandler: true,
    });
    return response.data;
};

export const updateTransitionMatrixPeriods = (itemId, payload) =>
    axios.put(`/thermal/${itemId}/matrix-periods`, payload, {
        suppressErrorMessageHandler: true,
    });

export const createTransitionMatrixPeriods = (itemId, payload) =>
    axios.post(`/thermal/${itemId}/matrix-periods-create`, payload, { suppressErrorMessageHandler: true });

export const deleteTransitionMatrixPeriods = (itemId, rowIds) =>
    axios.delete(`/thermal/${itemId}/matrix-periods`, { data: rowIds, suppressErrorMessageHandler: true });

export const deleteAllTransitionMatrixPeriods = (itemId) =>
    axios.delete(`/thermal/${itemId}/all-matrix-periods`, { suppressErrorMessageHandler: true });

export const getTransitionMatrixTransitions = async (itemId, payload) => {
    const response = await axios.post(`/thermal/${itemId}/matrix-transitions`, payload, {
        suppressErrorMessageHandler: true,
    });
    return response.data;
};

export const updateTransitionMatrixTransitions = (itemId, payload) =>
    axios.put(`/thermal/${itemId}/matrix-transitions`, payload, {
        suppressErrorMessageHandler: true,
    });

export const createTransitionMatrixTransitions = async (itemId, payload) => {
    const response = await axios.post(`/thermal/${itemId}/matrix-transitions-create`, payload, {
        suppressErrorMessageHandler: true,
    });
    return response.data;
};

export const deleteTransitionMatrixTransitions = (itemId, rowIds) =>
    axios.delete(`/thermal/${itemId}/matrix-transitions`, { data: rowIds, suppressErrorMessageHandler: true });

export const deleteAllTransitionMatrixTransitions = (itemId) =>
    axios.delete(`/thermal/${itemId}/all-matrix-transitions`, { suppressErrorMessageHandler: true });

export const getAllMatrixPeriods = async (itemId) => {
    const response = await axios.get(`/thermal/${itemId}/all-matrix-periods`, { suppressErrorMessageHandler: true });
    return response.data;
};

// resource variables
export const getResourceVariablesTabName = (gridKey) => `${gridKey}Tab`;

export const createSubThermalPeriods = (subThermalId, payload) =>
    axios.post(`/thermal/${subThermalId}/thermal-periods`, payload);

export const deleteSubThermalPeriods = (subThermalId, rowIds) =>
    axios.delete(`/thermal/${subThermalId}/thermal-periods`, { data: rowIds, suppressErrorMessageHandler: true });

export const validateSubThermalPeriods = async (subThermalId, payload) => {
    const response = await axios.post(`/thermal/${subThermalId}/periods/validation`, payload);
    return response.data;
};

export const deleteAllSubThermalPeriods = (subThermalId) =>
    axios.delete(`/thermal/${subThermalId}/all-thermal-periods`, { suppressErrorMessageHandler: true });

export const getAllOperationalDates = async (subThermalId) => {
    const response = await axios.get(`/thermal/${subThermalId}/all-operational-dates`, {
        suppressErrorMessageHandler: true,
    });
    return response.data;
};

export const getOperationalDates = async (subThermalId, payload) => {
    const response = await axios.post(`/thermal/${subThermalId}/operational-dates`, payload, {
        suppressErrorMessageHandler: true,
    });
    return response.data;
};

export const getOperationalDatesDataSourceView = async (subThermalId, payload) => {
    const response = await axios.post(`/thermal/${subThermalId}/operational-dates/datasourceview`, payload, {
        suppressErrorMessageHandler: true,
    });
    return response.data;
};

export const updateOperationalDates = (subThermalId, payload) =>
    axios.put(`/thermal/${subThermalId}/operational-dates`, payload);

export const getStartupShutdowns = async (subThermalId, payload) => {
    const response = await axios.post(`/thermal/${subThermalId}/startup-shutdown`, payload, {
        suppressErrorMessageHandler: true,
    });
    return response.data;
};

export const getStartupShutdownsDataSourceView = async (subThermalId, payload) => {
    const response = await axios.post(`/thermal/${subThermalId}/startup-shutdown/datasourceview`, payload, {
        suppressErrorMessageHandler: true,
    });
    return response.data;
};

export const getStartupShutdownsState = async (subThermalId) => {
    const response = await axios.get(`/thermal/${subThermalId}/startup-shutdown-state`, {
        suppressErrorMessageHandler: true,
    });
    return response.data;
};

export const updateStartupShutdowns = (subThermalId, payload) =>
    axios.put(`/thermal/${subThermalId}/startup-shutdown`, payload);

export const getRunConstraints = async (subThermalId, payload) => {
    const response = await axios.post(`/thermal/${subThermalId}/run-constraints`, payload, {
        suppressErrorMessageHandler: true,
    });
    return response.data;
};

export const getRunConstraintsDataSourceView = async (subThermalId, payload) => {
    const response = await axios.post(`/thermal/${subThermalId}/run-constraints/datasourceview`, payload, {
        suppressErrorMessageHandler: true,
    });
    return response.data;
};

export const getRunConstraintsState = async (subThermalId) => {
    const response = await axios.get(`/thermal/${subThermalId}/run-constraints-state`, {
        suppressErrorMessageHandler: true,
    });
    return response.data;
};

export const updateRunConstraints = (subThermalId, payload) =>
    axios.put(`/thermal/${subThermalId}/run-constraints`, payload);

export const createSubThermalFuelCosts = (subThermalId, payload) =>
    axios.post(`/thermal/${subThermalId}/fuel-costs-create`, payload);

export const validateSubThermalFuels = async (subThermalId, payload) => {
    const response = await axios.post(`/thermal/${subThermalId}/fuels/validation`, payload);
    return response.data;
};

export const deleteSubThermalFuelCosts = (itemId, rowIds) =>
    axios.delete(`/thermal/${itemId}/fuel-costs`, { data: rowIds, suppressErrorMessageHandler: true });

export const deleteAllSubThermalFuelCosts = (itemId) =>
    axios.delete(`/thermal/${itemId}/all-fuel-costs`, { suppressErrorMessageHandler: true });

export const getHeatRates = async (subThermalId, payload) => {
    const response = await axios.post(`/thermal/${subThermalId}/heat-rates`, payload, {
        suppressErrorMessageHandler: true,
    });
    return response.data;
};

export const getHeatRatesDataSourceView = async (subThermalId, payload) => {
    const response = await axios.post(`/thermal/${subThermalId}/heat-rates/datasourceview`, payload, {
        suppressErrorMessageHandler: true,
    });
    return response.data;
};

export const getHeatRatesState = async (subThermalId) => {
    const response = await axios.get(`/thermal/${subThermalId}/heat-rates-state`, {
        suppressErrorMessageHandler: true,
    });
    return response.data;
};

export const updateHeatRates = (subThermalId, payload) => axios.put(`/thermal/${subThermalId}/heat-rates`, payload);

export const getFuelCosts = async (subThermalId, payload) => {
    const response = await axios.post(`/thermal/${subThermalId}/fuel-costs`, payload, {
        suppressErrorMessageHandler: true,
    });
    return response.data;
};

export const getFuelCostsDataSourceView = async (subThermalId, payload) => {
    const response = await axios.post(`/thermal/${subThermalId}/fuel-costs/datasourceview`, payload, {
        suppressErrorMessageHandler: true,
    });
    return response.data;
};

export const getFuelCostsState = async (subThermalId) => {
    const response = await axios.get(`/thermal/${subThermalId}/fuel-costs-state`, {
        suppressErrorMessageHandler: true,
    });
    return response.data;
};

export const updateFuelCosts = (subThermalId, payload) => axios.put(`/thermal/${subThermalId}/fuel-costs`, payload);

export const getCostEmissions = async (subThermalId, payload) => {
    const response = await axios.post(`/thermal/${subThermalId}/cost-emissions`, payload, {
        suppressErrorMessageHandler: true,
    });
    return response.data;
};

export const getCostEmissionsDataSourceView = async (subThermalId, payload) => {
    const response = await axios.post(`/thermal/${subThermalId}/cost-emissions/datasourceview`, payload, {
        suppressErrorMessageHandler: true,
    });
    return response.data;
};

export const getCostEmissionsState = async (subThermalId) => {
    const response = await axios.get(`/thermal/${subThermalId}/cost-emissions-state`, {
        suppressErrorMessageHandler: true,
    });
    return response.data;
};

export const updateCostEmissions = (subThermalId, payload) =>
    axios.put(`/thermal/${subThermalId}/cost-emissions`, payload);

export const createSubThermalGenerationCapacity = (subThermalId, payload) =>
    axios.post(`/thermal/${subThermalId}/create-generation-capacity`, payload);

export const getGenerationCapacity = async (subThermalId, payload) => {
    const response = await axios.post(`/thermal/${subThermalId}/generation-capacity`, payload, {
        suppressErrorMessageHandler: true,
    });
    return response.data;
};

export const getGenerationCapacityDataSourceView = async (subThermalId, payload) => {
    const response = await axios.post(`/thermal/${subThermalId}/generation-capacity/datasourceview`, payload, {
        suppressErrorMessageHandler: true,
    });
    return response.data;
};

export const getGenerationCapacityState = async (subThermalId) => {
    const response = await axios.get(`/thermal/${subThermalId}/generation-capacity-state`, {
        suppressErrorMessageHandler: true,
    });
    return response.data;
};

export const updateGenerationCapacity = (subThermalId, payload) =>
    axios.put(`/thermal/${subThermalId}/generation-capacity`, payload);

export const deleteGenerationCapacity = (subThermalId, rowIds) =>
    axios.delete(`/thermal/${subThermalId}/generation-capacity`, { data: rowIds, suppressErrorMessageHandler: true });

export const deleteAllGenerationCapacity = (subThermalId) =>
    axios.delete(`/thermal/${subThermalId}/all-generation-capacity`, { suppressErrorMessageHandler: true });

export const validateGenerationCapacity = async (subThermalId, payload) => {
    const response = await axios.post(`/thermal/${subThermalId}/generation-capacity/validation`, payload);
    return response.data;
};

// Forced Outages

export const createSubThermalForcedOutages = (subThermalId, payload) =>
    axios.post(`/thermal/${subThermalId}/create-forced-outages`, payload);

export const getForcedOutages = async (subThermalId, payload) => {
    const response = await axios.post(`/thermal/${subThermalId}/forced-outages`, payload, {
        suppressErrorMessageHandler: true,
    });
    return response.data;
};

export const getForcedOutagesDataSourceView = async (subThermalId, payload) => {
    const response = await axios.post(`/thermal/${subThermalId}/forced-outages/datasourceview`, payload, {
        suppressErrorMessageHandler: true,
    });
    return response.data;
};

export const getForcedOutagesState = async (subThermalId) => {
    const response = await axios.get(`/thermal/${subThermalId}/forced-outages-state`, {
        suppressErrorMessageHandler: true,
    });
    return response.data;
};

export const updateForcedOutages = (subThermalId, payload) =>
    axios.put(`/thermal/${subThermalId}/forced-outages`, payload);

export const deleteForcedOutages = (subThermalId, rowIds) =>
    axios.delete(`/thermal/${subThermalId}/forced-outages`, { data: rowIds, suppressErrorMessageHandler: true });

export const deleteAllForcedOutages = (subThermalId) =>
    axios.delete(`/thermal/${subThermalId}/all-forced-outages`, { suppressErrorMessageHandler: true });

export const validateForcedOutages = async (subThermalId, payload) => {
    const response = await axios.post(`/thermal/${subThermalId}/forced-outages/validation`, payload);
    return response.data;
};

// Ancillary Contributions
export const createSubThermalAncillaryContributions = (subThermalId, payload) =>
    axios.post(`/thermal/${subThermalId}/ancillary-contributions-create`, payload);

export const getAncillaryContributions = async (subThermalId, payload) => {
    const response = await axios.post(`/thermal/${subThermalId}/ancillary-contributions`, payload, {
        suppressErrorMessageHandler: true,
    });
    return response.data;
};

export const getAncillaryContributionsDataSourceView = async (subThermalId, payload) => {
    const response = await axios.post(`/thermal/${subThermalId}/ancillary-contributions/datasourceview`, payload, {
        suppressErrorMessageHandler: true,
    });
    return response.data;
};

export const getAncillaryContributionsState = async (subThermalId) => {
    const response = await axios.get(`/thermal/${subThermalId}/ancillary-contributions-state`, {
        suppressErrorMessageHandler: true,
    });
    return response.data;
};

export const updateAncillaryContributions = (subThermalId, payload) =>
    axios.put(`/thermal/${subThermalId}/ancillary-contributions`, payload);

export const deleteAncillaryContributions = (subThermalId, rowIds) =>
    axios.delete(`/thermal/${subThermalId}/ancillary-contributions`, {
        data: rowIds,
        suppressErrorMessageHandler: true,
    });

export const deleteAllAncillaryContributions = (subThermalId) =>
    axios.delete(`/thermal/${subThermalId}/all-ancillary-contributions`, { suppressErrorMessageHandler: true });

export const validateAncillaryContributions = async (subThermalId, payload) => {
    const response = await axios.post(`/thermal/${subThermalId}/ancillary-contributions/validation`, payload);
    return response.data;
};

// linked resources
export const createSubThermalLinkedResources = (subThermalId, payload) =>
    axios.post(`/thermal/${subThermalId}/create-link-resources`, payload);

export const getLinkedResources = async (subThermalId, payload) => {
    const response = await axios.post(`/thermal/${subThermalId}/link-resources`, payload, {
        suppressErrorMessageHandler: true,
    });
    return response.data;
};

export const getLinkedResourcesDataSourceView = async (subThermalId, payload) => {
    const response = await axios.post(`/thermal/${subThermalId}/link-resources/datasourceview`, payload, {
        suppressErrorMessageHandler: true,
    });
    return response.data;
};

export const getLinkedResourcesState = async (subThermalId) => {
    const response = await axios.get(`/thermal/${subThermalId}/link-resources-state`, {
        suppressErrorMessageHandler: true,
    });
    return response.data;
};

export const updateLinkedResources = (subThermalId, payload) =>
    axios.put(`/thermal/${subThermalId}/link-resources`, payload);

export const deleteLinkedResources = (subThermalId, rowIds) =>
    axios.delete(`/thermal/${subThermalId}/link-resources`, { data: rowIds, suppressErrorMessageHandler: true });

export const deleteAllLinkedResources = (subThermalId) =>
    axios.delete(`/thermal/${subThermalId}/all-link-resources`, { suppressErrorMessageHandler: true });

export const validateLinkedResources = async (subThermalId, payload) => {
    const response = await axios.post(`/thermal/${subThermalId}/link-resources/validation`, payload);
    return response.data;
};

// generation derate

export const createSubThermalGenerationDerate = (subThermalId, payload) =>
    axios.post(`/thermal/${subThermalId}/create-generation-derate`, payload);

export const getGenerationDerate = async (subThermalId, payload) => {
    const response = await axios.post(`/thermal/${subThermalId}/generation-derate`, payload, {
        suppressErrorMessageHandler: true,
    });
    return response.data;
};

export const getGenerationDerateDataSourceView = async (subThermalId, payload) => {
    const response = await axios.post(`/thermal/${subThermalId}/generation-derate/datasourceview`, payload, {
        suppressErrorMessageHandler: true,
    });
    return response.data;
};

export const getGenerationDerateState = async (subThermalId) => {
    const response = await axios.get(`/thermal/${subThermalId}/generation-derate-state`, {
        suppressErrorMessageHandler: true,
    });
    return response.data;
};

export const updateGenerationDerate = (subThermalId, payload) =>
    axios.put(`/thermal/${subThermalId}/generation-derate`, payload);

export const deleteGenerationDerate = (subThermalId, rowIds) =>
    axios.delete(`/thermal/${subThermalId}/generation-derate`, { data: rowIds, suppressErrorMessageHandler: true });

export const deleteAllGenerationDerate = (subThermalId) =>
    axios.delete(`/thermal/${subThermalId}/all-generation-derate`, { suppressErrorMessageHandler: true });

export const validateGenerationDerate = async (subThermalId, payload) => {
    const response = await axios.post(`/thermal/${subThermalId}/generation-derate/validation`, payload);
    return response.data;
};

// forced outage temp

export const createSubThermalForcedOutageTemp = (subThermalId, payload) =>
    axios.post(`/thermal/${subThermalId}/create-forced-outage-temp`, payload);

export const getForcedOutageTemp = async (subThermalId, payload) => {
    const response = await axios.post(`/thermal/${subThermalId}/forced-outage-temp`, payload, {
        suppressErrorMessageHandler: true,
    });
    return response.data;
};

export const getForcedOutageTempDataSourceView = async (subThermalId, payload) => {
    const response = await axios.post(`/thermal/${subThermalId}/forced-outage-temp/datasourceview`, payload, {
        suppressErrorMessageHandler: true,
    });
    return response.data;
};

export const getForcedOutageTempState = async (subThermalId) => {
    const response = await axios.get(`/thermal/${subThermalId}/forced-outage-temp-state`, {
        suppressErrorMessageHandler: true,
    });
    return response.data;
};

export const updateForcedOutageTemp = (subThermalId, payload) =>
    axios.put(`/thermal/${subThermalId}/forced-outage-temp`, payload);

export const deleteForcedOutageTemp = (subThermalId, rowIds) =>
    axios.delete(`/thermal/${subThermalId}/forced-outage-temp`, { data: rowIds, suppressErrorMessageHandler: true });

export const deleteAllForcedOutageTemp = (subThermalId) =>
    axios.delete(`/thermal/${subThermalId}/all-forced-outage-temp`, { suppressErrorMessageHandler: true });

export const validateForcedOutageTemp = async (subThermalId, payload) => {
    const response = await axios.post(`/thermal/${subThermalId}/forced-outage-temp/validation`, payload);
    return response.data;
};

// no stay zones
export const createSubThermalCostNoStayZones = (subThermalId, payload) =>
    axios.post(`/thermal/${subThermalId}/no-stay-zones-create`, payload);

export const getNoStayZones = async (subThermalId, fuelCostId, payload) => {
    const response = await axios.post(`/thermal/${subThermalId}/${fuelCostId}/no-stay-zones`, payload, {
        suppressErrorMessageHandler: true,
    });
    return response.data;
};

export const updateNoStayZones = (subThermalId, fuelCostId, payload) =>
    axios.put(`/thermal/${subThermalId}/${fuelCostId}/no-stay-zones`, payload);

export const deleteNoStayZones = (subThermalId, fuelCostId, rowIds) =>
    axios.delete(`/thermal/${subThermalId}/${fuelCostId}/no-stay-zones`, {
        data: rowIds,
        suppressErrorMessageHandler: true,
    });

export const deleteAllNoStayZones = (subThermalId, fuelCostId) =>
    axios.delete(`/thermal/${subThermalId}/${fuelCostId}/all-no-stay-zones`, { suppressErrorMessageHandler: true });

export const validateNoStayZones = async (subThermalId, payload) => {
    const response = await axios.post(`/thermal/${subThermalId}/no-stay-zones/validation`, payload);
    return response.data;
};

// historical output
export const getHistoricalOutputs = async (subThermalId, payload) => {
    const response = await axios.post(`/thermal/${subThermalId}/historical-outputs`, payload, {
        suppressErrorMessageHandler: true,
    });
    return response.data;
};

export const getHistoricalOutputsDataSourceView = async (subThermalId, payload) => {
    const response = await axios.post(`/thermal/${subThermalId}/historical-outputs/datasourceview`, payload, {
        suppressErrorMessageHandler: true,
    });
    return response.data;
};

export const updateHistoricalOutputs = (subThermalId, payload) =>
    axios.put(`/thermal/${subThermalId}/historical-outputs`, payload);

export const createHistoricalOutputs = async (subThermalId, payload) => {
    const response = await axios.post(`/thermal/${subThermalId}/historical-outputs-create`, payload);
    return response.data;
};

export const deleteHistoricalOutputs = (subThermalId, rowIds) =>
    axios.delete(`/thermal/${subThermalId}/historical-outputs`, { data: rowIds, suppressErrorMessageHandler: true });

export const deleteAllHistoricalOutputs = (subThermalId) =>
    axios.delete(`/thermal/${subThermalId}/all-historical-outputs`, { suppressErrorMessageHandler: true });

export const validateHistoricalOutputs = async (subThermalId, payload) => {
    const response = await axios.post(`/thermal/${subThermalId}/historical-outputs/validation`, payload);
    return response.data;
};

// scheduled outages
export const getScheduledOutages = async (subThermalId, payload) => {
    const response = await axios.post(`/thermal/${subThermalId}/scheduled-outages`, payload, {
        suppressErrorMessageHandler: true,
    });
    return response.data;
};

export const getScheduledOutagesDataSourceView = async (subThermalId, payload) => {
    const response = await axios.post(`/thermal/${subThermalId}/scheduled-outages/datasourceview`, payload, {
        suppressErrorMessageHandler: true,
    });
    return response.data;
};

export const updateScheduledOutages = (subThermalId, payload) =>
    axios.put(`/thermal/${subThermalId}/scheduled-outages`, payload);

export const createScheduledOutages = async (subThermalId, payload) => {
    const response = await axios.post(`/thermal/${subThermalId}/scheduled-outages-create`, payload);
    return response.data;
};

export const deleteScheduledOutages = (subThermalId, rowIds) =>
    axios.delete(`/thermal/${subThermalId}/scheduled-outages`, { data: rowIds, suppressErrorMessageHandler: true });

export const deleteAllScheduledOutages = (subThermalId) =>
    axios.delete(`/thermal/${subThermalId}/all-scheduled-outages`, { suppressErrorMessageHandler: true });

export const validateScheduledOutages = async (subThermalId, payload) => {
    const response = await axios.post(`/thermal/${subThermalId}/scheduled-outages/validation`, payload);
    return response.data;
};

// Thermal Bulk Update
export const generateBulkUpdateFields = (subThermalId, gridKey, metadata, values, options = {}, serverErrors = []) => {
    const columns = metadata.columns[gridKey];
    const tabName = getResourceVariablesTabName(gridKey);

    const tabBulkUpdateFields = columns
        .map((col, idx) => {
            const inputType = getInputType(col.propertyTypeDescription);
            const columnType = getColumnType(col.propertyTypeDescription);
            const propertyValues = getCellEditorParams(metadata, col.propertyOptions, columnType) || {};
            const name = getField(col.propertyName, columnType);
            const value = values[tabName]?.[name];
            let isWarning = false;
            let isInvalid = false;
            let error = null;
            let warning = null;

            if (serverErrors?.filter((x) => parseInt(x.subThermalId) === parseInt(subThermalId))?.length > 0) {
                // Array of { colName: field column name, error: server error message from Thermal onFormSubmit}
                serverErrors
                    .filter((x) => parseInt(x.subThermalId) === parseInt(subThermalId))
                    .forEach((errorInfo) => {
                        if (errorInfo?.colName.toUpperCase() === name.toUpperCase()) {
                            isWarning = errorInfo?.warning?.length > 0 && errorInfo?.error?.length === 0 ? true : false;
                            isInvalid = errorInfo?.error?.length > 0 ? true : false;
                            error = errorInfo?.error;
                            warning = errorInfo?.warning;
                        }
                    });
            }

            const field = {
                id: idx,
                name,
                value,
                label: col.displayLabel,
                type: inputType,
                options: propertyValues.options,
                requiredFields: options.requiredFields,
                isWarning,
                isInvalid,
                error,
                warning,
            };

            return field;
        })
        .filter((column) => !options.uneditableColumns?.includes(column.name));

    return tabBulkUpdateFields;
};

const formatFieldsByType = (fields, types) => {
    const formattedFields = {};

    for (const fieldName in fields) {
        const type = types[fieldName];
        const value = fields[fieldName][0];

        const isEmpty = value === '' || value == null;

        let updateValue = isEmpty ? null : value;

        if (!isEmpty) {
            if (type === INPUT_TYPES.TextBox) {
                updateValue = value;
            } else if (type === INPUT_TYPES.CheckBox) {
                updateValue = value === 0; // 0 is for true, 1 for false
            } else if (type === INPUT_TYPES.NumericUpDown || type === INPUT_TYPES.ComboBox) {
                // only toThisDay field has id and description in form of strings
                // metadata can not be used for the correct data format only the field type
                updateValue = fieldName === 'toThisDay' ? value : Number(value);
            }
        }

        formattedFields[fieldName] = { updateValue };
    }

    return formattedFields;
};

const getMetadataTypes = (metadata) => {
    let types = {};

    for (const fields of Object.values(metadata.columns)) {
        types = {
            ...types,
            ...fields.reduce((obj, col) => {
                const columnType = getColumnType(col.propertyTypeDescription);
                const name = getField(col.propertyName, columnType);

                obj[name] = col.propertyTypeDescription;

                return obj;
            }, {}),
        };
    }

    return types;
};

export const addBulkFieldsToSubThermals = (subThermals, changedBulkFields, metadata) => {
    const types = getMetadataTypes(metadata);

    return subThermals.map((subItem) => {
        if (subItem.subThermalId in changedBulkFields) {
            const tabs = {};

            for (const [tabName, tabValue] of Object.entries(changedBulkFields[subItem.subThermalId])) {
                const fields = formatFieldsByType(tabValue, types);

                tabs[tabName] = { ...tabs[tabName], ...fields };
            }

            return { ...subItem, ...tabs };
        }

        return subItem;
    });
};

export const getCheckedTabFields = (fields) => {
    return Object.keys(fields)?.filter((fieldName) => fields[fieldName]?.includes(MULTIPLE_PLACEHOLDER_VALUE));
};

// filters
export const generationAssetDefaultFilter = {
    minEndDate: null,
};

export const getFiltersConfig = (item) => {
    const accounts = msalInstance.getAllAccounts();

    if (accounts.length) {
        const accountId = accounts[0].localAccountId;
        const filters = getItem(`${item}Filters`);

        if (filters && filters[accountId]) {
            return filters[accountId];
        }
    }

    return null;
};

export const getThermalFilters = (namespacePrefix) => {
    const userPreferences = getFiltersConfig(namespacePrefix);

    if (userPreferences) {
        return getFiltersConfig(namespacePrefix);
    } else {
        return {
            minEndDate: null,
        };
    }
};

// helper function to determine if the next item in a list of thermal resource groups has the same groupKey
export const isNextResourceInGroup = (res, currentIdx) => {
    if (currentIdx >= res?.length) {
        return false;
    }
    return res[currentIdx]?.groupKey === res[currentIdx + 1]?.groupKey;
};

// helper function to determine if the previous item in a list of thermal resource groups has the same groupKey
export const isPreviousResourceInGroup = (res, currentIdx) => {
    if (currentIdx === 0) {
        return false;
    }
    return res[currentIdx]?.groupKey === res[currentIdx - 1]?.groupKey;
};
