import axios from 'axios';

import { msalInstance } from '../auth';
import { getItem, removeItem, setItem } from '../storage';
import { REDIRECT_URL_STORAGE_KEY } from 'constants/local-storage';

export const getConnectionInfo = (params) => {
    // Not async/await end point
    const response = axios.get('/connection-details', {
        params,
        headers: params,
    });

    // Returns promise
    return response;
};

export const getUserConnections = async () => {
    const { data } = await axios.get('/connections');

    return data;
};

export const getEnvByConnectionId = async (connectionId) => {
    const { data } = await axios.get('/environments', {
        headers: {
            connectionId,
        },
    });

    return data;
};

export const getVersionsByEnvAndConnectionId = async (connectionId, environmentId, defaultVersionOnly = false) => {
    const response = await axios.get('/versions', {
        params: { defaultVersionOnly },
        headers: {
            connectionId,
            environmentId,
        },
    });

    return response?.data;
};

export const getUIVersionByNameOrDefault = async (connectionId, environmentId, versionName) => {
    const response = await axios.get('/version', {
        params: { versionName },
        headers: {
            connectionId,
            environmentId,
        },
    });

    return response?.data;
};

export const getBooksByEnvAndConnectionId = async (connectionId, environmentId) => {
    const { data } = await axios.get('/books', {
        headers: {
            connectionId,
            environmentId,
        },
    });

    return data;
};

export const getBooks = async () => {
    const { data } = await axios.get('/books');

    return data;
};

const getAccountConnectionConfig = () => {
    // Local storage account connection defaults
    const accounts = msalInstance.getAllAccounts();

    if (accounts?.length) {
        const accountId = accounts[0].localAccountId;
        const connections = getItem('connections');

        if (connections) {
            return connections[accountId];
        }
    }

    return null;
};

const parseConnectionSearchParams = (searchParams) => {
    return {
        connection: Number(searchParams.get('connection')),
        environment: searchParams.get('environment'),
        book: Number(searchParams.get('book')),
        schemaGroupId: Number(searchParams.get('schemaGroupId')),
        isDefaultConfig: searchParams.get('isDefaultConfig') === 'true',
        versionName: searchParams.get('versionName') ?? '',
    };
};

const getURLConnectionConfig = () => {
    // Query string parameters
    const searchParams = new URLSearchParams(window.location.search);

    const connection = searchParams.get('connection');
    const environment = searchParams.get('environment');
    const book = searchParams.get('book');
    const schemaGroupId = searchParams.get('schemaGroupId');

    if (connection != null && environment != null && book != null && schemaGroupId != null) {
        return parseConnectionSearchParams(searchParams);
    }

    return null;
};

export const setURLConnectionConfig = (config, nextURL) => {
    const searchParams = new URLSearchParams(config);
    const url = new URL(nextURL);

    url.search = searchParams;

    return url;
};

export const setAccountConnectionConfig = (config) => {
    const accounts = msalInstance.getAllAccounts();

    if (accounts?.length) {
        const connections = getItem('connections') || {};

        setItem('connections', {
            ...connections,
            [accounts[0].localAccountId]: config,
        });
    }
};

// a private variable to capture the list of connection config params
const connectionConfigParams = [
    'connection',
    'environment',
    'book',
    'schemaGroupId',
    'isDefaultConfig',
    'localAccountId',
    'versionName',
];

export const preserveConnectionSearchParams = (search) => {
    const searchParams = new URLSearchParams(search);
    const tempParams = new URLSearchParams(search);

    tempParams.forEach((_, key) => {
        if (!connectionConfigParams.includes(key)) {
            searchParams.delete(key);
        }
    });

    return searchParams.toString();
};

export const removeConnectionSearchParams = (search) => {
    const searchParams = new URLSearchParams(search);

    connectionConfigParams.forEach((key) => searchParams.delete(key));

    return searchParams.toString();
};

export const addToSearchParams = (search, config) => {
    const connectionConfig = new URLSearchParams(config);

    search.forEach((val, key) => {
        if (!connectionConfig.has(key)) {
            connectionConfig.append(key, val);
        }
    });

    return connectionConfig.toString();
};

export const removeAccountConnectionConfig = (config) => {
    const accounts = msalInstance.getAllAccounts();

    if (accounts?.length) {
        const connections = getItem('connections');

        if (connections) {
            delete connections[accounts[0].localAccountId];
        }

        setItem('connections', connections);
    }
};

/**
 * Check localStorage for the presense of connection configuration
 * Check for search params in the url
 * If both are present query string connection search params are prioritized
 */
export const getPrioritizedConnectionConfig = () => {
    const localStorageConfig = getAccountConnectionConfig();
    const urlConfig = getURLConnectionConfig();

    return urlConfig || localStorageConfig;
};

const hasValidConnectionConfig = () => {
    const config = getAccountConnectionConfig() || getURLConnectionConfig();

    if (config) {
        return config.connection != null && config.environment != null && config.book != null;
    }

    return false;
};

export const setupDefaultConnectionHeaders = () => {
    if (!hasValidConnectionConfig()) {
        return;
    }

    const connectionConfig = getPrioritizedConnectionConfig();

    axios.defaults.headers.connectionId = connectionConfig.connection;
    axios.defaults.headers.environmentId = connectionConfig.environment;
    axios.defaults.headers.bookId = connectionConfig.book;
};

export const setRedirectUrl = (url) => {
    setItem(REDIRECT_URL_STORAGE_KEY.REDIRECT_URL, url);
};
export const getRedirectUrl = () => {
    const redirectUrl = getItem(REDIRECT_URL_STORAGE_KEY.REDIRECT_URL);
    return redirectUrl;
};
export const removeRedirectUrl = () => {
    removeItem(REDIRECT_URL_STORAGE_KEY.REDIRECT_URL);
};
