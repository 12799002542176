import axios from 'axios';
import difference from 'lodash/difference';
import cloneDeep from 'lodash/cloneDeep';
import { nanoid } from 'nanoid';
import { FormattedMessage } from 'react-intl';

export const getPortfolioProperties = async (portfolioId) => {
    const response = await axios.get(`/portfolios/${portfolioId}/properties`);
    return response.data;
};

export const getPortfolioItems = async (portfolioId) => {
    const response = await axios.get(`/portfolios/${portfolioId}/items`);
    return response.data;
};

export const getPortfolios = async () => {
    const response = await axios.get('/portfolios');
    const portfolios = mapPortfolios(response.data.portfolioResponses);

    return {
        portfolios,
        metadata: {
            books: response.data.bookResponses,
            portfolioTypes: response.data.portfolioTypeResponses,
            dispatchTypes: response.data.dispatchTypes,
        },
    };
};

export const mapPortfolios = (portfolios, name) => {
    return portfolios.map((p) => {
        const path = name ? name + ' > ' + p.name : p.name;
        const nodes = p.portfolios ? mapPortfolios(p.portfolios, path) : [];
        const items = p.itemTypes
            ? p.itemTypes.map((group) => {
                  return {
                      id: nanoid(12),
                      label: group.itemTypeLabelName,
                      isFolder: false,
                      properties: {
                          id: group.typeId,
                          name: group.itemTypeLabelName,
                          typeId: group.typeId,
                      },
                      nodes: group.items.map((item) => {
                          return {
                              id: nanoid(12),
                              // properties object stores any non-tree data
                              properties: {
                                  id: item.id,
                                  name: item.name,
                                  typeId: group.typeId,
                                  portfolioId: p.id,
                                  isMaster: item.securityId,
                                  ownershipPercentage: item.ownershipPercentage,
                                  itemType: group.itemTypeLabelName,
                                  objectId: item?.objectId,
                                  longitude: item?.longitude,
                                  latitude: item?.latitude,
                              },
                              label: item.name,
                              isFolder: false,
                              nodes: [],
                          };
                      }),
                  };
              })
            : [];

        return {
            id: nanoid(12),
            // properties object stores any non-tree data
            properties: {
                id: p.id,
                name: p.name,
                bookId: p.bookId,
                description: p.description,
                dispatchTypeId: p.dispatchTypeId,
                dispatchTypeName: p.dispatchTypeName,
                portfolioTypeId: p.portfolioTypeId,
                portfolioTypeName: p.portfolioTypeName,
                parentId: p.parentId,
                locationId: p.locationId,
                generationTypeId: p.generationTypeId,
                isMaster: p.securityId,
            },
            path: name ? name : '',
            label: p.name,
            isFolder: true,
            nodes: items.concat(nodes),
        };
    });
};

export const getNodeIds = (nodes) => {
    const stack = [...nodes];
    const result = [];

    while (stack.length > 0) {
        const node = stack.pop();

        result.push(node.id);

        stack.push(...node.nodes);
    }

    return result;
};

export const getNodePropertyIds = (nodes) => {
    const stack = [...nodes];
    const result = [];

    while (stack.length > 0) {
        const node = stack.pop();

        result.push(node.properties.id);

        stack.push(...node.nodes);
    }

    return result;
};

export const getPortfolioItemIdsWithoutNested = (nodes) => {
    const stack = [...nodes];
    const result = [];

    while (stack.length > 0) {
        const node = stack.pop();

        if (!node.isFolder) {
            if (node.nodes.length === 0) {
                result.push(node.properties.id);
            }

            stack.push(...node.nodes);
        }
    }

    return result;
};

export const findNode = (nodes, id) => {
    const stack = [...nodes];

    while (stack.length) {
        const node = stack.pop();

        if (node.id === id) {
            return node;
        }

        stack.push(...node.nodes);
    }

    return null;
};

export const findPortfolioByPropertiesId = (nodes, id) => {
    const stack = [...nodes];

    while (stack.length) {
        const node = stack.pop();

        if (node.isFolder && node.properties.id === id) {
            return node;
        }

        stack.push(...node.nodes);
    }

    return null;
};

export const updateNode = (nodes, nodeId, nodeUpdates) => {
    const updatedTree = nodes.slice();
    const stack = [...updatedTree];

    while (stack.length) {
        let node = stack.pop();

        // this will update all portfolios or items with this id
        // I think this should be the desired behavior (an item with a specific id can be in many portfolios)
        // but if it isn't we should replace the check here with the nanoid generated value (node.id === id)
        if (node.properties.id === nodeId) {
            Object.keys(nodeUpdates).forEach((key) => {
                node.properties[key] = nodeUpdates[key];
            });
        }

        stack.push(...node.nodes);
    }

    return updatedTree;
};

export const renameNode = (nodes, nodeId, name) => {
    const updatedTree = nodes.slice();
    const stack = [...updatedTree];
    while (stack.length) {
        let node = stack.pop();

        // this will update all portfolios or items with this id
        // I think this should be the desired behavior (an item with a specific id can be in many portfolios)
        // but if it isn't we should replace the check here with the nanoid generated value (node.id === id)
        if (node.properties.id === nodeId) {
            node.label = name;
            node.properties.name = name;
        }

        stack.push(...node.nodes);
    }

    return updatedTree;
};

export const updateNodeIsMaster = (nodes, nodeId, isMaster) => {
    const updatedTree = nodes.slice();
    const stack = [...updatedTree];

    while (stack.length) {
        let node = stack.pop();

        // this will update all portfolios or items with this id
        // I think this should be the desired behavior (an item with a specific id can be in many portfolios)
        // but if it isn't we should replace the check here with the nanoid generated value (node.id === id)
        if (node.properties.id === nodeId) {
            node.properties.isMaster = isMaster;
        }

        stack.push(...node.nodes);
    }

    return updatedTree;
};

export const updateNodeCoords = (nodes, nodeId, lat, long, objectRefId) => {
    const updatedTree = nodes.slice();
    const stack = [...updatedTree];
    while (stack.length) {
        let node = stack.pop();

        // this will update all portfolios or items with this id
        // I think this should be the desired behavior (an item with a specific id can be in many portfolios)
        // but if it isn't we should replace the check here with the nanoid generated value (node.id === id)
        if (node.properties.id === nodeId) {
            node.properties.objectId = objectRefId;
            node.properties.latitude = lat;
            node.properties.longitude = long;
        }

        stack.push(...node.nodes);
    }
    return updatedTree;
};

export const updateNodeOwnshershipPercentage = (nodes, nodeId, ownershipPercentage) => {
    const updatedTree = nodes.slice();
    const stack = [...updatedTree];

    while (stack.length) {
        let node = stack.pop();

        if (node.id === nodeId) {
            node.properties.ownershipPercentage = ownershipPercentage;
        }

        stack.push(...node.nodes);
    }

    return updatedTree;
};

export const filterNodesByName = (nodes, term) => {
    const stack = [...nodes];
    const matches = [];

    while (stack.length > 0) {
        const node = stack.pop();

        if (node.isFolder && node.properties.name.toLowerCase().includes(term)) {
            // we have a match on portfolio name - add it to the results array along with any of its children
            matches.push(node);
        } else {
            // continue searching deeper
            stack.push(...node.nodes);
        }
    }

    return matches;
};

const removeNodes = (nodes, id) => {
    for (const node of nodes) {
        if (node.isFolder) {
            const itemGroups = node.nodes
                .filter((n) => !n.isFolder)
                .map((n) => {
                    return {
                        ...n,
                        nodes: n.nodes.filter(({ properties }) => properties.id !== id),
                    };
                })
                .filter((n) => n.nodes.length > 0);

            node.nodes = itemGroups.concat(node.nodes.filter((n) => n.isFolder));

            removeNodes(node.nodes, id);
        }
    }
};

export const removeNodesFromTree = (nodes, id) => {
    let tree = cloneDeep(nodes);

    // updating happens in-place and via recursion to preserve tree structure
    removeNodes(tree, id);

    return tree;
};

export const replaceNodeProperties = (nodes, nodeId, nodeUpdates) => {
    const updatedTree = nodes.slice();
    const stack = [...updatedTree];

    while (stack.length) {
        let node = stack.pop();

        // check the message on the updateNode function in this file
        if (node.properties.id === nodeId) {
            Object.keys(nodeUpdates).forEach((key) => {
                node[key] = nodeUpdates[key];
            });
        } else {
            stack.push(...node.nodes);
        }
    }

    return updatedTree;
};

export const removeNodeByParentId = (nodes, prevNode) => {
    const parentNode = findPortfolioByPropertiesId(nodes, prevNode.properties.parentId);
    const updatedParentNode = {
        ...parentNode,
        nodes: parentNode.nodes.filter((el) => el.id !== prevNode.id),
    };

    const updatedTree = replaceNodeProperties(nodes, updatedParentNode.properties.id, updatedParentNode);

    return updatedTree;
};

export const extractPortfoliosFromNodesExcludeSelected = (nodes, id) => {
    const stack = [...nodes];
    const matches = [];

    while (stack.length > 0) {
        const node = stack.pop();

        if (node.isFolder && node.id !== id) {
            matches.unshift({
                id: node.id,
                isFolder: node.isFolder,
                label: node.label,
                nodes: extractPortfoliosFromNodesExcludeSelected(node.nodes, id),
                properties: node.properties,
            });
        }
    }

    return matches;
};

export const getNodeAncestors = (nodes, node) => {
    let parentId = node.properties.parentId;
    const matches = [];

    while (parentId !== null) {
        const node = findPortfolioByPropertiesId(nodes, parentId);

        matches.push(node.id);
        parentId = node.properties.parentId;
    }

    return matches;
};

export const getNodeSubTree = (nodes, needle) => {
    const result = [];

    for (const currentNode of nodes) {
        if (
            currentNode.properties.portfolioId === needle.properties.portfolioId &&
            currentNode.properties.id === needle.properties.id
        ) {
            result.push(currentNode);
            break;
        }
    }

    if (!result.length) {
        for (const currentNode of nodes) {
            const subTree = getNodeSubTree(currentNode.nodes, needle);

            if (subTree.length) {
                result.push(currentNode, ...subTree);
                break;
            }
        }
    }

    return result;
};

export const updatePortfolio = (id, payload) => axios.put(`/portfolios/${id}`, payload);

export const deletePortfolio = (portfolioId) => axios.delete(`/portfolios/${portfolioId}`);

export const copyPortfolio = async (sourceId, targetId) => {
    const response = await axios.post('/portfolios/copy', {
        portfolioId: sourceId,
        destinationPortfolioId: targetId,
    });

    return mapPortfolios(response.data.portfolioResponses);
};

export const createPortfolio = async (payload, name) => {
    const response = await axios.post('/portfolios', payload);
    const mapNewPortfolio = mapPortfolios([response.data], name);

    return mapNewPortfolio[0];
};

export const getAssignableItems = async () => {
    const response = await axios.get('/portfolios/items');

    return response.data.map((group) => {
        return {
            id: nanoid(12),
            label: group.itemTypeLabelName,
            isFolder: false,
            properties: { id: group.typeId, name: group.itemTypeLabelName, typeId: group.typeId },
            nodes: group.items.map((item) => ({
                id: String(item.id),
                label: item.name,
                isFolder: false,
                nodes: [],
                properties: {
                    id: item.id,
                    name: item.name,
                    typeId: group.typeId,
                    isMaster: item.securityId,
                    ownershipPercentage: item.ownershipPercentage,
                    itemType: group.itemTypeLabelName,
                    objectId: item?.objectId,
                    longitude: item?.longitude,
                    latitude: item?.latitude,
                },
            })),
        };
    });
};

export const addItemsToPortfolio = async (portfolioId, items, config) => {
    const ids = items.map((id) => parseInt(id, 10));
    const response = await axios.post(`/portfolios/${portfolioId}/items`, ids, config);

    return response.data.map((group) => {
        return {
            id: nanoid(12),
            label: group.itemTypeLabelName,
            isFolder: false,
            properties: { id: group.typeId, name: group.itemTypeLabelName, typeId: group.typeId },
            nodes: group.items.map((item) => ({
                id: String(item.id),
                label: item.name,
                isFolder: false,
                nodes: [],
                properties: {
                    id: item.id,
                    name: item.name,
                    typeId: group.typeId,
                    portfolioId,
                    isMaster: item.securityId,
                    ownershipPercentage: item.ownershipPercentage,
                    itemType: group.itemTypeLabelName,
                    objectId: item?.objectId,
                    longitude: item?.longitude,
                    latitude: item?.latitude,
                },
            })),
        };
    });
};

export const movePortfolioToPortfolio = async (portfolioId, parentPortfolioId, config) => {
    return axios.put(`/portfolios/${portfolioId}/move/${parentPortfolioId}`, undefined, config);
};

export const removeItemFromPortfolio = (portfolioId, itemId) =>
    axios.delete(`/portfolios/${portfolioId}/items/${itemId}`);

const getListOfPortfolioIds = (nodes) => {
    const stack = [...nodes];
    const ids = [];

    while (stack.length > 0) {
        const node = stack.pop();

        if (node.isFolder) {
            ids.push(node.properties.id);
            stack.unshift(...node.nodes);
        }
    }

    return ids;
};

export const findCopiedNode = (before, after) => {
    const a = getListOfPortfolioIds(before);
    const b = getListOfPortfolioIds(after);

    const [id] = difference(b, a);

    return findPortfolioByPropertiesId(after, id);
};

export const updateItemOwnershipPercentage = (portfolioId, portfolioItemId, ownershipPercentage) => {
    return axios.put(
        `/portfolios/${portfolioId}/${portfolioItemId}/ownership-percentage?ownershipPercentage=${ownershipPercentage}`
    );
};

export const checkCoordsDigits = (value) => {
    // if (!value) return false;
    const dotSep = value.toString().indexOf('.');
    if (dotSep !== -1) {
        if (value.toString().length - dotSep > 8) {
            return true;
        }
    }
    return false;
};

export const getPowersimmLayerUrl = async () => {
    const response = await axios.get(`/map-layer`);
    return response.data;
};

export const getArcgisObjects = (nodes) => {
    let tempArr = [];
    for (let node of nodes) {
        if (node.nodes.length > 0) {
            tempArr = [...tempArr, ...getArcgisObjects(node.nodes)];
        } else {
            // Filter out only items with long/lat ( Such items had coords previously later on deleted )
            if (
                node.properties.objectId &&
                node.properties.longitude !== undefined &&
                node.properties.longitude !== null &&
                node.properties.latitude !== undefined &&
                node.properties.latitude !== null
            ) {
                tempArr.push(node.properties.objectId);
            }
        }
    }
    return tempArr;
};

export const resetMap = (pLayer, view) => {
    if (!pLayer || !view) return;

    view.center = [-95.8603, 38.27];
    view.zoom = 5;

    // Hide all points
    pLayer.definitionExpression = '1=0';
};

/**
 * Extent Map
 * @param {object | number} selected - Object equals to portfolio node, number equals to a new Arcgis item objectId
 * @param {object} layer - The powersimmV layer object reference
 * @param {object} view - The map view object reference
 */
export const extentMap = async (selected, layer, view) => {
    if (!layer || !view) return;

    let powersimmArcIds = [];
    let uniqueIds = [];

    if (typeof selected === 'number') {
        uniqueIds = [selected];
    } else {
        //Get all objectIds
        if (selected.isFolder) {
            powersimmArcIds = getArcgisObjects(selected.nodes);
            uniqueIds = powersimmArcIds.filter((v, i, a) => a.indexOf(v) === i);
        } else {
            const { longitude, latitude, objectId } = selected.properties;
            if (
                longitude !== undefined &&
                longitude !== null &&
                latitude !== undefined &&
                latitude !== null &&
                objectId
            ) {
                uniqueIds = [objectId];
            }
        }
    }

    //Extent map and hide redundant points
    if (uniqueIds.length > 0) {
        let query = layer.createQuery();

        query.where = '';
        query.objectIds = uniqueIds;
        query.returnGeometry = true;

        const extent = await layer.queryExtent(query);

        if (extent.count > 0) {
            if (uniqueIds.length === 1) {
                //Extent zoom level is really close when we are querying with 1 item only, so we expand
                extent.extent.expand(10000);
            }

            layer.definitionExpression = layer.objectIdField + ' IN(' + uniqueIds.join(',') + ')';
            view.goTo(extent);
        } else {
            resetMap(layer, view);
        }
    } else {
        resetMap(layer, view);
    }
};

export const getChildPortfolioIds = (node) => {
    let children = [];
    let stack = [...node.nodes];

    while (stack.length) {
        const childNode = stack.shift();

        if (childNode.isFolder) {
            children.push(childNode.properties.id);
            stack.unshift(...childNode.nodes);
        }
    }

    return children;
};

export const getChildPortfolioNodeIds = (node) => {
    let children = [];
    let stack = Array.isArray(node) ? [...node] : [...node.nodes];

    while (stack.length) {
        const childNode = stack.shift();

        if (childNode.isFolder) {
            children.push(childNode.id);
            stack.unshift(...childNode.nodes);
        }
    }

    return children;
};

export const validateLongLat = (value, type, sibling) => {
    let errorMessage;

    if (type === 'long') {
        if (value >= 180 || value <= -180) {
            errorMessage = <FormattedMessage id="longitude_validation_borders" />;
        }
    }
    if (type === 'lat') {
        if (value > 85 || value < -85) {
            errorMessage = <FormattedMessage id="latitude_validation_borders" />;
        }
    }

    if (value !== '' && sibling === '') {
        errorMessage = <FormattedMessage id="longlat_validation_both" />;
    }

    const digitsError = checkCoordsDigits(value);
    if (digitsError) {
        errorMessage = <FormattedMessage id="longlat_validation_decimal" />;
    }

    return errorMessage;
};
