import { Box, Input, InputRightElement, InputGroup } from '@chakra-ui/react';
import styled from '@emotion/styled/macro';
import { memo, useRef, useCallback, useMemo, useState } from 'react';
import { useCombobox } from 'downshift';
import { useVirtual } from 'react-virtual';
import { useFormControl } from '@chakra-ui/form-control';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';
import { useIntl } from 'react-intl';

import { ReactComponent as ArrowIcon } from '../../icons/multiselect-arrow.svg';

/**
 * Custom Combobox component
 * @param {any} value - The value of the Select.
 * @param {string | object | boolean | number} options - List of options to show in the combobox component.
 * @param {(value) => void} onChange - Callback that is called when the combobox value changes.
 * @param {string} valueKey - The object key used to extract the value of the component from the selected option.
 * @param {string} labelKey - The object key used to extract the visible label of the component from each <option value="" className=""></option>
 * @param {boolean} isInvalid - Determines whether invalid state is shown in the combobox.
 * @param {boolean} isDisabled - Is the combobox disabled.
 * @param {boolean} isControlled - Make the component uncontrolled if set to false.
 * @param {boolean} showPlaceholder - Show a placeholder at the beginning of the combobox.
 * @param {string} placeholderLabel - Placeholer String to show when placeholder is active.
 * @param {any} placeholderValue - Placeholer Value for which the placeholder is shown.
 * @param {callback} hasDivider - Can be used to add a dividing line between two options
 * @param {any} rest - Pass on Chakra params to parent Box.
 */

const List = ({
    isOpen,
    listRef,
    getItemProps,
    getMenuProps,
    highlightedIndex,
    selectedItem,
    reset,
    virtualItems,
    showPlaceholder,
    totalSize,
    placeholderLabel,
    placeholderValue,
    hasDivider,
    filteredOptions,
    getLabel,
    options,
    onChange,
    popperProps,
    isInPortal,
}) => {
    return (
        <ListWrapper
            {...getMenuProps({
                ref: listRef,
                suppressRefError: true,
                style: popperProps.styles.popper,
                ...popperProps.attributes.popper,
            })}
            isOpen={isOpen && (virtualItems.length > 0 || showPlaceholder)}
            isInPortal={isInPortal}
        >
            {isOpen && (
                <>
                    <li key="total-size" style={{ height: totalSize }} />
                    {virtualItems.length === 0 && showPlaceholder && (
                        <ListItem
                            key="placeholder"
                            isPlaceholder={true}
                            onClick={() => {
                                reset();
                                onChange(placeholderValue);
                            }}
                        >
                            {placeholderLabel}
                        </ListItem>
                    )}
                    {virtualItems.map((virtualRow) => (
                        <ListItemWrapper
                            key={virtualRow.index}
                            ref={virtualRow.measureRef}
                            style={{
                                transform: `translateY(${virtualRow.start}px)`,
                            }}
                        >
                            <ListItem
                                {...getItemProps({
                                    item: filteredOptions[virtualRow.index],
                                    index: virtualRow.index,
                                })}
                                isHighlighted={highlightedIndex === virtualRow.index}
                                isSelected={selectedItem === filteredOptions[virtualRow.index]}
                            >
                                {getLabel(filteredOptions[virtualRow.index])}
                            </ListItem>
                            {hasDivider &&
                                virtualRow.index !== 0 &&
                                options[virtualRow.index] &&
                                hasDivider(options[virtualRow.index - 1], options[virtualRow.index]) && <Divider />}
                        </ListItemWrapper>
                    ))}
                </>
            )}
        </ListWrapper>
    );
};

const Combobox = memo(
    ({
        value,
        options = [],
        onChange,
        onBlur: formikOnBlur,
        valueKey,
        labelKey,
        isInvalid,
        isWarning,
        isDisabled,
        isControlled = true,
        showPlaceholder,
        placeholderLabel,
        placeholderValue,
        hasDivider,
        portalId = null,
        placement = 'bottom-start',
        strategy = 'absolute',
        modifiers = [],
        isRequired,
        ...rest
    }) => {
        const inputRef = useRef();
        const listRef = useRef();

        const intl = useIntl();

        const [filterValue, setFilterValue] = useState('');
        const { onFocus: chakraOnFocus, onBlur: chakraOnBlur } = useFormControl({});

        const popperProps = usePopper(inputRef.current, listRef.current, {
            modifiers,
            strategy,
            placement,
        });
        const { name, id } = { ...rest };
        const inputIdForLabel = id ?? name;

        const getValue = useCallback((option) => (valueKey && option ? option[valueKey] : option), [valueKey]);

        const formattedPlaceholderLabel = useMemo(
            () =>
                placeholderLabel !== undefined
                    ? placeholderLabel
                    : isRequired
                    ? intl.formatMessage({ id: 'common_forms_select_option' })
                    : '-',
            [intl, isRequired, placeholderLabel]
        );

        const getLabel = useCallback(
            (option) => {
                if (option === undefined || option === '') {
                    return formattedPlaceholderLabel;
                }
                if (labelKey && labelKey === valueKey && getValue(option) === placeholderValue) {
                    return formattedPlaceholderLabel;
                }

                return labelKey && option ? String(option[labelKey]) : option;
            },
            [getValue, labelKey, formattedPlaceholderLabel, placeholderValue, valueKey]
        );

        const placeholder = useMemo(() => {
            const placeholderItem = labelKey || valueKey ? {} : placeholderValue;
            if (labelKey) {
                placeholderItem[labelKey] = formattedPlaceholderLabel;
            }
            if (valueKey) {
                placeholderItem[valueKey] = placeholderValue;
            }
            return placeholderItem;
        }, [valueKey, labelKey, formattedPlaceholderLabel, placeholderValue]);

        const optionsWithPlaceholder = useMemo(() => {
            let optionsWithplaceholder;
            if (showPlaceholder) {
                optionsWithplaceholder = [placeholder, ...options];
            } else {
                optionsWithplaceholder = options;
            }
            return optionsWithplaceholder;
        }, [placeholder, showPlaceholder, options]);

        const filteredOptions = useMemo(() => {
            return optionsWithPlaceholder.filter((option) => getLabel(option)?.toLowerCase().includes(filterValue));
        }, [optionsWithPlaceholder, getLabel, filterValue]);

        const controlledSelectedItem = useMemo(
            () => optionsWithPlaceholder.find((opt) => getValue(opt) === value) || '',
            [getValue, optionsWithPlaceholder, value]
        );

        const { virtualItems, scrollToIndex, totalSize } = useVirtual({
            size: filteredOptions.length,
            parentRef: listRef,
            overscan: 2,
            paddingStart: 8,
            estimateSize: useCallback(() => 40, []),
            paddingEnd: 8,
        });

        const {
            getItemProps,
            getMenuProps,
            highlightedIndex,
            selectedItem,
            getComboboxProps,
            getInputProps,
            isOpen,
            reset,
            toggleMenu,
            setInputValue,
            openMenu,
        } = useCombobox({
            items: filteredOptions,
            initialSelectedItem: controlledSelectedItem,
            selectedItem: isControlled ? controlledSelectedItem : undefined,
            onInputValueChange: ({ inputValue: newValue, isOpen }) =>
                setFilterValue(isOpen ? `${newValue?.toLowerCase()}` : ''),
            onSelectedItemChange: ({ selectedItem: item }) => onChange(item ? getValue(item) : item),
            onIsOpenChange: ({ selectedItem: item, isOpen }) => {
                if (!isOpen) {
                    setInputValue(getLabel(item));
                    formikOnBlur && formikOnBlur();
                } else {
                    if (!item) {
                        scrollToIndex(-1);
                    }
                }
            },
            scrollIntoView: () => {},
            onHighlightedIndexChange: ({ highlightedIndex }) => {
                return highlightedIndex !== -1 && scrollToIndex(highlightedIndex);
            },
            itemToString: getLabel,
        });

        const list = (
            <List
                isOpen={isOpen}
                getItemProps={getItemProps}
                getMenuProps={getMenuProps}
                highlightedIndex={highlightedIndex}
                selectedItem={selectedItem}
                reset={reset}
                virtualItems={virtualItems}
                showPlaceholder={showPlaceholder}
                totalSize={totalSize}
                placeholderLabel={formattedPlaceholderLabel}
                placeholderValue={placeholderValue}
                filteredOptions={filteredOptions}
                getLabel={getLabel}
                hasDivider={hasDivider}
                options={options}
                listRef={listRef}
                onChange={onChange}
                popperProps={popperProps}
                isInPortal={!!portalId}
            />
        );

        return (
            <ComboboxWrapper {...rest}>
                <InputGroup {...getComboboxProps()}>
                    <Input
                        {...getInputProps({
                            type: 'text',
                            ref: inputRef,
                            onClick: () => {
                                !isOpen && openMenu();
                                popperProps?.update();
                            },
                            onBlur: chakraOnBlur,
                            onFocus: chakraOnFocus,
                        })}
                        id={inputIdForLabel}
                        borderColor={isWarning ? 'orange.300' : undefined}
                        _hover={isWarning ? { borderColor: 'orange.300' } : undefined}
                        placeholder={formattedPlaceholderLabel}
                        isInvalid={isInvalid}
                        isDisabled={isDisabled}
                    />
                    <InputRightElement
                        zIndex="1"
                        children={
                            <Icon
                                isDisabled={isDisabled}
                                onClick={() => {
                                    !isDisabled && toggleMenu();
                                    popperProps?.update();
                                }}
                            >
                                <ArrowIcon />
                            </Icon>
                        }
                    />
                </InputGroup>

                {portalId ? <>{createPortal(<>{list}</>, document.querySelector(`#${portalId}`))}</> : <>{list}</>}
            </ComboboxWrapper>
        );
    },
    (prevProps, nextProps) => {
        return (
            prevProps.value === nextProps.value &&
            prevProps.options === nextProps.options &&
            prevProps.valueKey === nextProps.valueKey &&
            prevProps.labelKey === nextProps.labelKey &&
            prevProps.isInvalid === nextProps.isInvalid &&
            prevProps.isDisabled === nextProps.isDisabled &&
            prevProps.isControlled === nextProps.isControlled &&
            prevProps.showPlaceholder === nextProps.showPlaceholder &&
            prevProps.placeholderLabel === nextProps.placeholderLabel &&
            prevProps.placeholderValue === nextProps.placeholderValue &&
            prevProps.onChange === nextProps.onChange
        );
    }
);

export default Combobox;

const ComboboxWrapper = styled(Box)`
    width: 100%;
    position: relative;
`;

const Divider = styled(Box)`
    width: 100%;
    height: 1px;
    background: var(--chakra-colors-gray-200);
    margin: var(--chakra-space-2);
`;

const Icon = styled.div`
    cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'pointer')};
    svg {
        width: 1em;
        padding-bottom: 4px;
    }
`;

const ListWrapper = styled.ul`
    max-height: 230px;
    min-width: ${(props) => (props.isInPortal ? '250px' : '100%')};
    overflow-y: auto;
    box-shadow: var(--chakra-shadows-md);
    border-radius: 0 var(--chakra-radii-md) var(--chakra-radii-md) var(--chakra-radii-md);
    background-color: var(--chakra-colors-background-tertiary);
    position: absolute;
    z-index: ${(props) => (props.isInPortal ? 'var(--chakra-zIndices-popover)' : 3)};
    list-style: none;
    display: ${(props) => (props.isOpen ? 'block' : 'none')};

    outline: 2px solid transparent;
    outline-offset: 2px;
`;

const ListItemWrapper = styled.div`
    width: 100%;
    position: ${(props) => (props.isPlaceholder ? 'relative' : 'absolute')};
    top: 0;
    left: 0;
`;

const ListItem = styled.li`
    cursor: pointer;
    padding: var(--chakra-space-2) var(--chakra-space-4);
    background-color: ${(props) =>
        props.isHighlighted && !props.isSelected
            ? 'var(--chakra-colors-item-hover)'
            : props.isSelected
            ? 'var(--chakra-colors-item-selected)'
            : 'var(--chakra-colors-background-tertiary)'};
    color: ${(props) => (props.isSelected ? 'var(--chakra-colors-blue-400)' : 'var(--chakra-colors-item-text)')};
    ${(props) =>
        props.isPlaceholder
            ? `&:hover {
        background-color: var(--chakra-colors-blue-50);
    }`
            : ''}
    width: 100%;
`;
