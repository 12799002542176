import { useEffect, useState, useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
    Alert,
    AlertIcon,
    Box,
    Heading,
    Button,
    Flex,
    Text,
    useDisclosure,
    SimpleGrid,
    FormControl,
    FormLabel,
} from '@chakra-ui/react';
import styled from '@emotion/styled/macro';
import { useLocation } from 'react-router-dom';

import {
    getBasisDropdownOptions,
    createBasis,
    validateBasisCreationData,
    autoCreateBasis,
    autoCreateExistingBases,
    validateBasisDaOption,
    POWER_COMMODITY_DESCRIPTION,
} from '../services/basis';
import {
    getElectricNodeListData,
    getIsoHubList,
    getIsDefaultHubNode,
    getIsNodeHistoryValid,
} from '../services/traverseApi';
import { getMainMarketModels, validateMainMarketModel } from '../services/mainMarket';
import { ISO_RTO_OPTIONS, CAISO_OPTION, BASIS_SETUP_DARTM_OPTIONS } from '../services/utils';
import { isEmpty } from '../services/utils';

import useHistoryPush from '../hooks/useHistoryPush';
import useCommonToast from '../hooks/useCommonToast';

import SimpleModal from '../components/modal/SimpleModal';

import Error from '../components/utils/Error';
import DefinitionsSkeleton from '../components/utils/DefinitionsSkeleton';
import AddItemButton from '../components/utils/AddItemButton';
import CustomIconButton from '../components/utils/CustomIconButton';
import Popover from '../components/utils/Popover';
import HelpButtonComponent from 'components/utils/HelpButtonComponent';

import AddForwardItemModal from '../components/basisConfigurator/AddMainMarketModal';
import BoxTextDisplay from '../components/basisConfigurator/BoxTextDisplay';

import ComboboxField from '../components/forms/ComboboxField';
import InputField from '../components/forms/InputField';
import CheckboxField from '../components/forms/CheckboxField';

import { ReactComponent as AddIcon } from '../icons/add.svg';
import { ReactComponent as InfoIcon } from '../icons/info.svg';

import { helpAnchors } from 'constants/help';

const BasisSetup = () => {
    const intl = useIntl();
    const historyPush = useHistoryPush();
    const { search } = useLocation();
    const { toast } = useCommonToast();

    const { node, isoRto, lat, long } = useMemo(() => {
        // if we are coming from portfolios map setup basis action we have Node and ISO selected
        const searchParams = new URLSearchParams(search);
        return {
            node: searchParams.get('node'),
            isoRto: searchParams.get('isoRto'),
            lat: searchParams.get('lat'),
            long: searchParams.get('long'),
        };
    }, [search]);

    const addMainMarketModal = useDisclosure();
    const hubCheckModal = useDisclosure();
    const [hubCheckViewed, setHubCheckViewed] = useState(false);

    const closeHubCheckModal = () => {
        setHubCheckViewed(true);
        hubCheckModal.onClose();
    };

    const [basisData, setBasisData] = useState({
        basisName: '',
        selectedCommodityTypeId: null,
        selectedCommodityType: '',
        selectedMainMarketModel: null,
        // node could come from maps in url query string parameter
        selectedElectricNode: node ?? null,
        selectedIsoHub: null,
        // iso could come from maps in url query string parameter
        selectedIsoRto: isoRto ?? null,
        selectedDartMarkets: {
            da: false,
            rt5mm: false,
            rt15mm: false,
        },
        isEmptyItem: true,
    });

    const [dropdownOptions, setDropdownOptions] = useState({
        data: null,
        loading: true,
        error: false,
    });

    const [mainMarketList, setMainMarketList] = useState({
        data: null,
        loading: true,
        error: false,
    });

    const [daOption, setDaOption] = useState({
        error: false,
        isValidating: false,
    });

    const [powerCommodityId, setPowerCommodityId] = useState(null);
    const [electricNodeList, setElectricNodeList] = useState([]);
    const [isoHubList, setIsoHubList] = useState([]);
    const [defaultHubNode, setDefaultHubNode] = useState({ isValid: true, statusText: '' });
    const [nodeHistoryValid, setNodeHistoryValid] = useState({ isValid: true, statusText: '' });
    const [autoCreate, setAutoCreate] = useState(true);
    const [isValidMainMarketModel, setIsValidMainMarketModel] = useState(true);
    const [isValidData, setIsValidData] = useState(false);
    const [initialSubmit, setInitialSubmit] = useState(true);

    const [isLoadingData, setIsLoadingData] = useState(false);
    const [isCreatingBasis, setIsCreatingBasis] = useState(false);

    const [touched, setTouched] = useState({
        electricNode: false,
        isoRto: false,
        mainMarketModel: false,
        da: false,
    });

    const isCreateFromMapFailed = useMemo(() => {
        const isIsoRtoNotFound = !!isoRto && !ISO_RTO_OPTIONS.find((opt) => opt.id === isoRto);
        const isElectricNodeNotFound = !!node && electricNodeList.length > 0 && !electricNodeList.includes(node);
        return isIsoRtoNotFound || isElectricNodeNotFound;
    }, [electricNodeList, node, isoRto]);

    const isCreateFromMapMissingLocation = useMemo(() => {
        let isElectricNodeLocationFound = true;
        if (!!node && !!isoRto) {
            // If we have ISO code and node name from url query strings then it is coming from portflio maps
            // Check if node's location LAT and LONG exist
            isElectricNodeLocationFound = !!lat && !!long;
        }
        return !isElectricNodeLocationFound;
    }, [node, isoRto, lat, long]);

    const fetchInitialData = useCallback(() => {
        return getBasisDropdownOptions()
            .then((data) => {
                setDropdownOptions((prev) => ({ ...prev, data }));
                const powerCommodity = data?.commodityTypes?.find(
                    (commodityType) => commodityType.description?.toUpperCase() === POWER_COMMODITY_DESCRIPTION
                );
                const powerCommodityId = powerCommodity?.id;
                const powerCommodityDescr = powerCommodity?.description;

                setBasisData((prev) => ({
                    ...prev,
                    selectedCommodityTypeId: powerCommodityId,
                    selectedCommodityType: powerCommodityDescr,
                }));
                setPowerCommodityId(powerCommodityId);
            })
            .catch(() => setDropdownOptions((prev) => ({ ...prev, error: true })))
            .finally(() => setDropdownOptions((prev) => ({ ...prev, loading: false })));
    }, []);

    const fetchMainMarketListData = useCallback(() => {
        return getMainMarketModels()
            .then((data) => setMainMarketList((prev) => ({ ...prev, data })))
            .catch(() => setMainMarketList((prev) => ({ ...prev, error: true })))
            .finally(() => setMainMarketList((prev) => ({ ...prev, loading: false })));
    }, []);

    // used in useEffect to retrieve ISO's hubs list
    const fetchIsoHubList = (iso) => {
        if (!iso) {
            return;
        }

        getIsoHubList(iso)
            .then((isoHubList) => {
                !isEmpty(isoHubList) ? setIsoHubList(isoHubList) : setIsoHubList([]);
            })
            .catch(() => {
                const errorMessage = intl.formatMessage({
                    id: 'basis_configurator_iso_hub_list_loading_error',
                });
                toast({
                    status: 'error',
                    message: errorMessage,
                });
            });
    };

    const fetchIsDefaultHubNode = (iso, hub, node) => {
        if (!iso || !hub || !node) {
            return;
        }
        setIsLoadingData(true);
        setDefaultHubNode({ isValid: false, statusText: 'Verifying default hub node, please wait...' });
        setHubCheckViewed(false);

        getIsDefaultHubNode(iso, hub, node)
            .then((resp) => {
                setDefaultHubNode(resp);
            })
            .catch(() => {
                setDefaultHubNode({ isValid: true, statusText: '' });
            })
            .finally(() => {
                setIsLoadingData(false);
            });
    };

    const fetchIsNodeHistoryValid = (iso, node) => {
        if (!iso || !node) {
            return;
        }
        setIsLoadingData(true);
        setNodeHistoryValid({ isValid: false, statusText: 'Verifying node history, please wait...' });
        setHubCheckViewed(false);

        getIsNodeHistoryValid(iso, node)
            .then((resp) => {
                setNodeHistoryValid(resp);
            })
            .catch(() => {
                setNodeHistoryValid({ isValid: true, statusText: '' });
            })
            .finally(() => {
                setIsLoadingData(false);
            });
    };

    useEffect(() => {
        fetchInitialData();
        fetchMainMarketListData();
    }, [fetchInitialData, fetchMainMarketListData]);

    // check for non-default hub/node selection
    useEffect(() => {
        fetchIsDefaultHubNode(basisData.selectedIsoRto, basisData.selectedIsoHub, basisData.selectedElectricNode);
    }, [basisData.selectedIsoRto, basisData.selectedIsoHub, basisData.selectedElectricNode]);

    // check for sufficient node history
    useEffect(() => {
        fetchIsNodeHistoryValid(basisData.selectedIsoRto, basisData.selectedElectricNode);
    }, [basisData.selectedIsoRto, basisData.selectedElectricNode]);

    //validate basis data
    useEffect(() => {
        const isValid = validateBasisCreationData(basisData, autoCreate, isValidMainMarketModel, daOption.error);
        setIsValidData(isValid);
    }, [basisData, autoCreate, isValidMainMarketModel, daOption.error]);

    // ISO selection changed either by user action or by coming from portfolio map query string url arguments
    // refetch hubs and nodes lists
    useEffect(() => {
        if (basisData?.selectedIsoRto && ISO_RTO_OPTIONS.find((opt) => opt.id === basisData.selectedIsoRto)) {
            setIsLoadingData(true);
            fetchIsoHubList(basisData.selectedIsoRto);

            getElectricNodeListData(basisData.selectedIsoRto)
                .then((electricNodes) => {
                    setElectricNodeList(electricNodes);
                })
                .catch(() => {
                    const errorMessage = intl.formatMessage({
                        id: 'basis_configurator_electric_node_data_loading_error',
                    });
                    toast({
                        status: 'error',
                        message: errorMessage,
                    });
                })
                .finally(() => {
                    setIsLoadingData(false);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [intl, toast, basisData.selectedIsoRto]);

    const onIsoRtoOptionChange = async (value) => {
        // ISO selection changed, useEffect() above will refetch hubs and nodes lists
        // clear out local data
        setDefaultHubNode({ isValid: true, statusText: '' });
        setNodeHistoryValid({ isValid: true, statusText: '' });
        setHubCheckViewed(false);
        setIsoHubList([]);
        setElectricNodeList([]);
        setBasisData((prev) => ({
            ...prev,
            selectedIsoRto: value,
            selectedElectricNode: '',
            selectedIsoHub: '',
            selectedDartMarkets: {
                da: false,
                rt5mm: false,
                rt15mm: false,
            },
        }));
        setTouched((prev) => ({ ...prev, isoRto: true, electricNode: false }));
    };

    const onCommodityTypeOptionChange = (commodityTypeId) => {
        const comm = dropdownOptions?.data?.commodityTypes?.find(
            (commodityType) => commodityType.id === commodityTypeId
        );
        setBasisData((prev) => ({
            ...prev,
            selectedCommodityTypeId: commodityTypeId,
            selectedCommodityType: comm?.description,
        }));
    };

    const onBasisAutoCreateOptionChange = (autoCreate) => {
        setAutoCreate(autoCreate);
    };

    const onMarketModelOptionChange = async (mainMarketModelId) => {
        if (!mainMarketModelId) return;

        setBasisData((prev) => ({ ...prev, selectedMainMarketModel: mainMarketModelId }));
        setTouched((prev) => ({ ...prev, mainMarketModel: true }));
        setIsLoadingData(true);
        try {
            const isValid = await validateMainMarketModel(mainMarketModelId);
            setIsValidMainMarketModel(isValid);
        } catch {
            const errorMessage = intl.formatMessage({ id: 'basis_configurator_main_market_model_validation_fail' });
            toast({
                status: 'error',
                message: errorMessage,
            });
            setIsValidMainMarketModel(false);
        }
        setIsLoadingData(false);
    };

    const onIsoHubChange = async (value) => {
        setBasisData((prev) => ({
            ...prev,
            selectedIsoHub: value,
        }));
    };

    const onDartMarketsOptionChange = (field, value) => {
        setBasisData((prev) => ({ ...prev, selectedDartMarkets: { ...prev.selectedDartMarkets, [field]: value } }));
    };

    const autoCreateBasisRequest = {
        isoName: basisData.selectedIsoRto,
        nodeName: basisData.selectedElectricNode,
        marketModelId: basisData.selectedMainMarketModel,
        daOpt: basisData.selectedDartMarkets?.da,
        rt5Opt: basisData.selectedDartMarkets?.rt5mm,
        rt15Opt: basisData.selectedDartMarkets?.rt15mm,
        physItem: basisData.isEmptyItem,
    };

    const onBasisCreate = async () => {
        const successMessage = intl.formatMessage({ id: 'basis_configurator_basis_create_success' });

        const isValid = validateBasisCreationData(basisData, autoCreate, isValidMainMarketModel, daOption.error);
        setIsValidData(isValid);
        setInitialSubmit(false);

        // eslint-disable-next-line no-sequences
        setTouched((prev) => Object.keys(prev).reduce((prev, key) => ((prev[key] = true), prev), {}));

        if (!isValid) {
            toast({
                status: 'error',
                message: intl.formatMessage({ id: 'common_generic_saving_error' }),
            });
            return;
        }

        setIsCreatingBasis(true);
        let stayingOnPage = true;

        try {
            if (basisData.selectedCommodityTypeId === powerCommodityId && autoCreate) {
                let validRequest = true;
                const { data: existingBases } = await autoCreateExistingBases(autoCreateBasisRequest);
                if (existingBases?.da != null) {
                    if (autoCreateBasisRequest.rt5Opt || autoCreateBasisRequest.rt15Opt) {
                        let rt5RequestIsValid = false;
                        let rt15RequestIsValid = false;

                        //request is valid if at least one basis being requested does not already exist
                        if (
                            (autoCreateBasisRequest.rt5Opt && existingBases?.rt5 == null) ||
                            !autoCreateBasisRequest.rt5Opt
                        ) {
                            rt5RequestIsValid = true;
                        }
                        if (
                            (autoCreateBasisRequest.rt15Opt && existingBases?.rt15 == null) ||
                            !autoCreateBasisRequest.rt15Opt
                        ) {
                            rt15RequestIsValid = true;
                        }

                        if (!rt5RequestIsValid || !rt15RequestIsValid) {
                            validRequest = false;
                        }
                    } else {
                        validRequest = false;
                    }
                }

                if (validRequest) {
                    const { data: autoCreateBasisResponse } = await autoCreateBasis(autoCreateBasisRequest);

                    if (autoCreateBasisResponse) {
                        // autocreate basis response will have all existing bases, not only specific new once that were created
                        // need to check which bases we were requesting to create in order to do correct new basis redirection
                        let anyBasisId = null;

                        if (autoCreateBasisRequest.rt15Opt && autoCreateBasisResponse.rt15) {
                            anyBasisId = autoCreateBasisResponse.rt15;
                        }
                        if (autoCreateBasisRequest.rt5Opt && autoCreateBasisResponse.rt5) {
                            anyBasisId = autoCreateBasisResponse.rt5;
                        }
                        if (autoCreateBasisRequest.daOpt && autoCreateBasisResponse.da) {
                            anyBasisId = autoCreateBasisResponse.da;
                        }

                        // redirect to newly created DA or RT5 or RT15 basis
                        const path = anyBasisId ? `/basis/${anyBasisId}` : '/basis';
                        stayingOnPage = false;
                        toast(successMessage);
                        historyPush(path);
                    }
                } else {
                    const existingBasesError = intl.formatMessage({ id: 'basis_configurator_existing_bases_error' });
                    toast({
                        status: 'error',
                        message: existingBasesError,
                    });
                }
            } else {
                const basisResult = await createBasis({
                    description: basisData.basisName,
                });
                stayingOnPage = false;
                toast(successMessage);
                historyPush(`/basis-configurator/${basisResult.basisId}`);
            }
        } finally {
            stayingOnPage && setIsCreatingBasis(false);
        }
    };

    const onBasisCreateCancel = () => {
        historyPush(`/basis`);
    };

    const onBasisDaOptionValidate = async () => {
        setDaOption((prev) => ({ ...prev, isValidating: true }));

        const { data: isValid } = await validateBasisDaOption(autoCreateBasisRequest);

        setDaOption({ error: !isValid, isValidating: false });
    };

    const onBasisDaOptionSelect = (isChecked) => {
        onDartMarketsOptionChange(BASIS_SETUP_DARTM_OPTIONS.da, isChecked);
        if (isChecked) {
            setDaOption((prev) => ({ ...prev, error: false }));
        } else {
            onBasisDaOptionValidate();
        }
    };

    const onBasisRtOptionSelect = (option, isChecked) => {
        onDartMarketsOptionChange(option, isChecked);

        if (isChecked && !basisData.selectedDartMarkets.da && !daOption.error) {
            onBasisDaOptionValidate();
        }
    };

    const BasisSetupHeaderSimpleGrid = () => {
        return (
            <SimpleGrid
                py={5}
                spacingX={5}
                columns={{ base: 1, lg: 2 }}
                borderBottom={
                    basisData.selectedCommodityTypeId === powerCommodityId &&
                    autoCreate &&
                    '1px solid var(--chakra-colors-border-secondary)'
                }
            >
                <ComboboxField
                    id="commodityType"
                    name="commodityType"
                    label={intl.formatMessage({ id: 'basis_commodity_type' })}
                    options={dropdownOptions.data?.commodityTypes || []}
                    valueKey="id"
                    labelKey="description"
                    showPlaceholder={false}
                    value={basisData.selectedCommodityTypeId}
                    onChange={(value) => onCommodityTypeOptionChange(value)}
                    isRequired
                    isDisabled={dropdownOptions.loading || !dropdownOptions.data?.commodityTypes}
                />

                {basisData.selectedCommodityTypeId === powerCommodityId && (
                    <CheckboxField
                        id="chbAutocreateBasis"
                        name="chbAutocreateBasis"
                        isChecked={autoCreate}
                        onChange={(event) => onBasisAutoCreateOptionChange(event.target.checked)}
                        mt={7}
                    >
                        <Text size="sm">
                            <FormattedMessage id="basis_configurator_auto_create" />
                            <HelpButtonComponent helpAnchor={helpAnchors.BASIS_AUTOCREATE} />
                        </Text>
                    </CheckboxField>
                )}
            </SimpleGrid>
        );
    };

    const AutoCreateBasisComponent = () => {
        return (
            <>
                <SimpleGrid
                    mt={5}
                    spacingX={5}
                    columns={{ base: 1, lg: 3 }}
                    borderBottom="1px solid"
                    borderColor="border-secondary"
                >
                    <ComboboxField
                        id="mainMarketModel"
                        name="mainMarketModel"
                        label={intl.formatMessage({ id: 'main_market_model_title' })}
                        options={mainMarketList.data || []}
                        valueKey="id"
                        labelKey="description"
                        showPlaceholder={false}
                        value={basisData.selectedMainMarketModel}
                        onChange={(value) => onMarketModelOptionChange(value)}
                        isInvalid={
                            (basisData.selectedMainMarketModel && !isValidMainMarketModel) ||
                            (!basisData.selectedMainMarketModel && touched.mainMarketModel)
                        }
                        error={intl.formatMessage({
                            id: 'basis_configurator_main_market_model_incorrect_configuration',
                        })}
                        isRequired
                        isDisabled={mainMarketList.loading || !mainMarketList.data}
                    />

                    <ComboboxField
                        id="isoRto"
                        name="isoRto"
                        label={intl.formatMessage({ id: 'basis_configurator_iso_rto' })}
                        options={ISO_RTO_OPTIONS}
                        valueKey="id"
                        labelKey="description"
                        value={basisData.selectedIsoRto}
                        onChange={(value) => onIsoRtoOptionChange(value)}
                        isInvalid={!basisData.selectedIsoRto && touched.isoRto}
                        error={intl.formatMessage(
                            {
                                id: 'common_forms_validation_required',
                            },
                            {
                                label: intl.formatMessage({
                                    id: 'basis_configurator_iso_rto',
                                }),
                            }
                        )}
                        isRequired
                    />
                    <ComboboxField
                        id="isoHub"
                        name="isoHub"
                        label={intl.formatMessage({ id: 'basis_configurator_iso_hub' })}
                        options={isoHubList}
                        valueKey="description" // currently using description as key
                        labelKey="description"
                        value={basisData.selectedIsoHub}
                        onChange={(value) => onIsoHubChange(value)}
                        isWarning={!defaultHubNode.isValid}
                        warning={!defaultHubNode.isValid && defaultHubNode.statusText}
                        isRequired
                        isDisabled={isEmpty(isoHubList)}
                        showPlaceholder={isLoadingData}
                        placeholderLabel={isLoadingData ? intl.formatMessage({ id: 'common_loading' }) : undefined}
                    />

                    <AddItemButton
                        pl="0px !important"
                        m="0px !important"
                        leftIcon={<AddIcon />}
                        onClick={addMainMarketModal.onOpen}
                    >
                        <Text size="sm" color="gray.900" pl={1} pt={1}>
                            <FormattedMessage id="basis_configurator_add_new_main_market_item" />
                        </Text>
                    </AddItemButton>
                </SimpleGrid>

                <SimpleGrid
                    mt={5}
                    pb={5}
                    spacingX={5}
                    columns={{ base: 1, lg: 2 }}
                    borderBottom="1px solid"
                    borderColor="border-secondary"
                >
                    <ComboboxField
                        id="electricNode"
                        name="electricNode"
                        label={intl.formatMessage({ id: 'basis_configurator_electric_node' })}
                        options={electricNodeList}
                        value={basisData.selectedElectricNode}
                        onChange={(value) => {
                            setBasisData((prev) => ({ ...prev, selectedElectricNode: value }));
                            setTouched((prev) => ({ ...prev, electricNode: true }));
                        }}
                        isInvalid={!basisData.selectedElectricNode && touched.electricNode}
                        error={intl.formatMessage(
                            {
                                id: 'common_forms_validation_required',
                            },
                            {
                                label: intl.formatMessage({
                                    id: 'basis_configurator_electric_node',
                                }),
                            }
                        )}
                        isWarning={!nodeHistoryValid.isValid}
                        warning={!nodeHistoryValid.isValid && nodeHistoryValid.statusText}
                        isRequired
                        isDisabled={
                            isEmpty(electricNodeList) || !basisData.selectedMainMarketModel || !basisData.selectedIsoRto
                        }
                        showPlaceholder={isLoadingData}
                        placeholderLabel={isLoadingData ? intl.formatMessage({ id: 'common_loading' }) : undefined}
                    />

                    <Flex direction="column">
                        <Flex direction="column">
                            <FormControl isRequired>
                                <FormLabel>
                                    <FormattedMessage id="basis_configurator_day_ahead_and_real_time_markets" />
                                </FormLabel>
                            </FormControl>

                            <Flex pt={2}>
                                <CheckboxField
                                    id="chbDayAhead"
                                    name="chbDayAhead"
                                    isChecked={basisData.selectedDartMarkets.da}
                                    onChange={(event) => onBasisDaOptionSelect(event.target.checked)}
                                    flex="max-content 0 0"
                                    isDisabled={
                                        !basisData.selectedMainMarketModel ||
                                        !basisData.selectedIsoRto ||
                                        !basisData.selectedElectricNode ||
                                        daOption.isValidating
                                    }
                                    isInvalid={daOption.error}
                                >
                                    <Text size="sm">
                                        <FormattedMessage id="basis_configurator_dart_markets_da" />
                                    </Text>
                                </CheckboxField>

                                <CheckboxField
                                    id="cnbRt5mm"
                                    name="chbRt5mm"
                                    isChecked={basisData.selectedDartMarkets.rt5mm}
                                    onChange={(event) =>
                                        onBasisRtOptionSelect(BASIS_SETUP_DARTM_OPTIONS.rt5mm, event.target.checked)
                                    }
                                    flex="max-content 0 0"
                                    isDisabled={
                                        !basisData.selectedMainMarketModel ||
                                        !basisData.selectedIsoRto ||
                                        !basisData.selectedElectricNode ||
                                        daOption.isValidating
                                    }
                                    ml={5}
                                >
                                    <Text size="sm">
                                        <FormattedMessage id="basis_configurator_dart_markets_da5mm" />
                                    </Text>
                                </CheckboxField>

                                <CheckboxField
                                    id="chbRt15mm"
                                    name="chbRt15mm"
                                    isChecked={basisData.selectedDartMarkets.rt15mm}
                                    onChange={(event) =>
                                        onBasisRtOptionSelect(BASIS_SETUP_DARTM_OPTIONS.rt15mm, event.target.checked)
                                    }
                                    flex="max-content 0 0"
                                    isDisabled={
                                        !basisData.selectedMainMarketModel ||
                                        !basisData.selectedIsoRto ||
                                        basisData.selectedIsoRto !== CAISO_OPTION ||
                                        !basisData.selectedElectricNode ||
                                        daOption.isValidating
                                    }
                                    ml={5}
                                >
                                    <Text size="sm">
                                        <FormattedMessage id="basis_configurator_dart_markets_da15mm" />
                                    </Text>
                                </CheckboxField>
                            </Flex>
                        </Flex>

                        {((!basisData.selectedDartMarkets.rt15mm &&
                            !basisData.selectedDartMarkets.rt5mm &&
                            !basisData.selectedDartMarkets.da &&
                            touched.da) ||
                            daOption.error) && (
                            <Text mt={3} size="sm" color="text-error" variant="regular">
                                {daOption.error ? (
                                    <FormattedMessage id="basis_configurator_da_option_validation" />
                                ) : (
                                    <FormattedMessage
                                        id="common_forms_validation_required"
                                        values={{
                                            label: intl.formatMessage({
                                                id: 'basis_configurator_day_ahead_and_real_time_markets',
                                            }),
                                        }}
                                    />
                                )}
                            </Text>
                        )}
                    </Flex>
                </SimpleGrid>

                <Flex mt={5}>
                    <CheckboxField
                        id="chbIsoRto"
                        name="chbIsoRto"
                        isChecked={basisData.isEmptyItem}
                        onChange={(event) => setBasisData((prev) => ({ ...prev, isEmptyItem: event.target.checked }))}
                        flex="max-content 0 0"
                        isDisabled={!basisData.selectedMainMarketModel || !basisData.selectedIsoRto || isLoadingData}
                    >
                        <Text size="sm">
                            <FormattedMessage id="basis_configurator_create_empty_item" />
                        </Text>
                    </CheckboxField>

                    <Popover
                        placement="top"
                        trigger={<CustomIconButton mt="5px !important" ml="5px !important" icon={<InfoIcon />} />}
                    >
                        <FormattedMessage id="basis_configurator_empty_item_hint" />
                    </Popover>
                </Flex>
            </>
        );
    };

    return (
        <>
            {addMainMarketModal.isOpen && <AddForwardItemModal isOpen onClose={addMainMarketModal.onClose} />}
            {hubCheckModal.isOpen && (
                <SimpleModal
                    isOpen
                    onClose={closeHubCheckModal}
                    title={<FormattedMessage id="basis_configurator_modal_heading" />}
                    children={
                        <>
                            <Alert status="warning" my="2">
                                <AlertIcon />
                                <BoxTextDisplay label="ISO/RTO" value={basisData.selectedIsoRto} />
                                <BoxTextDisplay label="Hub" value={basisData.selectedIsoHub} />
                                <BoxTextDisplay label="Electric Node" value={basisData.selectedElectricNode} />
                            </Alert>
                            <FormattedMessage id="basis_configurator_modal_message" />
                        </>
                    }
                    withFooterDivider={true}
                    footerContent={
                        <Button
                            mt={1}
                            mb={1}
                            type="button"
                            align="end"
                            onClick={closeHubCheckModal}
                            textTransform="capitalize"
                        >
                            <FormattedMessage id="common_continue" />
                        </Button>
                    }
                />
            )}

            <MainWrapper>
                <CenteredWrapper>
                    <Heading as="h1" variant="h1" mt={10}>
                        <FormattedMessage id="basis_configurator_create_basis_heading" />
                        <HelpButtonComponent helpAnchor={helpAnchors.BASIS_SETUP} />
                    </Heading>
                    <Text fontSize="sm" mt={5}>
                        <FormattedMessage id="hint_text_basis_setup" />
                    </Text>

                    {autoCreate && isCreateFromMapFailed && basisData?.selectedElectricNode === node && (
                        <Error
                            secondaryId="basis_configurator_node_not_prepopulated_error"
                            additionalText={node}
                            mt={2}
                        />
                    )}

                    {autoCreate && isCreateFromMapMissingLocation && basisData?.selectedElectricNode === node && (
                        <Alert status="warning" mt={2}>
                            <AlertIcon />
                            <FormattedMessage id="basis_configurator_node_missing_location_warning" values={{ node }} />
                        </Alert>
                    )}

                    {dropdownOptions.loading || mainMarketList.loading ? (
                        <DefinitionsSkeleton />
                    ) : dropdownOptions.error || mainMarketList.error ? (
                        <Error
                            primaryId="common_error"
                            secondaryId="common_loading_error"
                            additionalText="basis options"
                            mt={8}
                        />
                    ) : (
                        <>
                            <BasisSetupHeaderSimpleGrid />
                            {basisData.selectedCommodityTypeId === powerCommodityId && autoCreate ? (
                                // Autocreate Basis
                                <AutoCreateBasisComponent />
                            ) : (
                                // Create Named Basis
                                <Box maxW={{ base: '100%', lg: '50%' }} pr={{ base: 0, lg: 3 }}>
                                    <InputField
                                        id="basisName"
                                        name="basisName"
                                        label={intl.formatMessage({ id: 'basis_configurator_create_basis_name' })}
                                        value={basisData.basisName}
                                        type="text"
                                        isRequired
                                        onChange={(event) =>
                                            setBasisData((prev) => ({ ...prev, basisName: event.target.value }))
                                        }
                                        isInvalid={basisData.basisName.length > 50}
                                        error={intl.formatMessage(
                                            { id: 'common_forms_validation_length' },
                                            { label: 'Basis Name', lengthRule: '50 characters or less' }
                                        )}
                                    />
                                </Box>
                            )}
                        </>
                    )}
                </CenteredWrapper>

                <StickyButtonsWrapper>
                    <Flex justifyContent="flex-end">
                        <Button variant="secondary" textTransform="capitalize" onClick={onBasisCreateCancel}>
                            <FormattedMessage id="common_cancel" />
                        </Button>
                        <Button
                            ml={5}
                            isDisabled={(daOption.isValidating || !isValidData) && !initialSubmit}
                            onClick={
                                (autoCreate ? nodeHistoryValid.isValid && defaultHubNode.isValid : true) ||
                                hubCheckViewed
                                    ? onBasisCreate
                                    : hubCheckModal.onOpen
                            }
                            isLoading={isCreatingBasis}
                            textTransform="capitalize"
                        >
                            <FormattedMessage id="basis_configurator_create_basis" />
                        </Button>
                    </Flex>
                </StickyButtonsWrapper>
            </MainWrapper>
        </>
    );
};

const MainWrapper = styled(Box)`
    position: fixed;
    top: 80px;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    margin-bottom: 100px;
    padding-bottom: 10px;
`;

const CenteredWrapper = styled(Box)`
    width: 100%;
    max-width: 1314px;
    margin: 0 auto;
    padding: 0 16px;
`;

const StickyButtonsWrapper = styled(Box)`
    width: 100%;
    position: fixed;
    bottom: 40px;
    min-height: 60px;
    background: var(--chakra-colors-background-tertiary);
    display: flex;
    align-items: center;
    padding: 10px 0px;
    border-top: 1px solid var(--chakra-colors-border-secondary);
    justify-content: flex-end;
    padding-right: 24px;
`;

export default BasisSetup;
