import { PublicClientApplication, EventType } from '@azure/msal-browser';
import axios from 'axios';

import { Flex, Button, createStandaloneToast } from '@chakra-ui/react';
import theme from '../theme';

import { createIntl, createIntlCache } from 'react-intl';
import en from '../lang/en.json';

import ErrorAccordion from '../components/utils/ErrorAccordion';

const cache = createIntlCache();
const intl = createIntl({ locale: 'en', messages: en }, cache);

const config = {
    auth: {
        clientId: process.env.REACT_APP_AUTH_CLIENT_ID,
        authority: process.env.REACT_APP_AUTH_AUTHORITY,
        redirectUri: process.env.REACT_APP_AUTH_REDIRECT_URI,
        postLogoutRedirectUri: process.env.REACT_APP_AUTH_REDIRECT_URI,
    },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false,
        secureCookies: false,
    },
};

export const msalInstance = new PublicClientApplication(config);

const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
        msalInstance.setActiveAccount(event.payload.account);
    }
});

export const scopes = [process.env.REACT_APP_AUTH_SCOPE];

const renderToast = (errMsg, errDetails) => {
    const toast = createStandaloneToast({ theme });
    const continueLabel = intl.formatMessage({ id: 'common_continue' });

    const status = 'error';
    // toast light and dark colors per status are defined in src\theme\colors.js
    const textColor = `${status}-toast-text-color`;
    const bgColor = `${status}-toast-background`;
    const borderColor = `${status}-toast-border-color`;

    if (!toast.isActive('noPermissionsToast')) {
        toast({
            render: ({ onClose }) => (
                <Flex
                    wrap="wrap"
                    direction="row"
                    align="center"
                    px={6}
                    py={4}
                    borderWidth="1px"
                    borderRadius="md"
                    color={textColor}
                    bg={bgColor}
                    borderColor={borderColor}
                >
                    <ErrorAccordion status={status} message={errMsg} details={errDetails} />
                    <Button textTransform="capitalize" ml="auto" variant="secondary" bg="white" onClick={onClose}>
                        {continueLabel}
                    </Button>
                </Flex>
            ),
            position: 'top',
            id: 'noPermissionsToast',
            duration: 20000,
        });
    }
};

export const setupInterceptors = (history) => {
    const reqId = axios.interceptors.request.use(async (config) => {
        try {
            if (config.headers?.powerBiAuthorization) {
                config.headers.Authorization = config.headers.powerBiAuthorization;
                delete config.headers.powerBiAuthorization;
            } else {
                const { accessToken } = await msalInstance.acquireTokenSilent({ scopes, account: accounts[0] });
                config.headers.Authorization = `Bearer ${accessToken}`;
            }
        } catch (err) {
            // something went wrong with the silent token acquisition
            console.dir(err);
            msalInstance.loginRedirect();
        }

        return config;
    });

    const resId = axios.interceptors.response.use(
        (response) => {
            // Any status code that lie within the range of 2xx cause this function to trigger
            return response;
        },
        async (error) => {
            let data;
            if (error.response) {
                data = error.response.data;
                const isPowerBiError = data?.error?.code?.startsWith('PowerBI');

                if (data instanceof Blob) {
                    data = JSON.parse(await data.text());
                }

                /*
                API Exception Middleware populates the following object structure:
                {
                    int code 
                    string error 
                    string details 
                    string instance 
                    string errorType 
                    enum errorMessageType 
                        {
                        General = 0,
                        OnDeleteChildrenFound = 1,
                        UniqueConstraintError = 2,
                        DBNetworkError = 3
                        }
                }
                */

                if (data.errorType === 'NO_MAIN_PERMISSIONS') {
                    history.replace('/connection');
                }

                // Any status codes that falls outside the range of 2xx cause this function to trigger
                if (error.response.status === axios.HttpStatusCode.Unauthorized && !isPowerBiError) {
                    // Status 401 Unauthorized
                    renderToast(data.error);
                } else if (
                    data &&
                    !error.config.suppressErrorMessageHandler &&
                    !data.issues &&
                    !data.validationResult?.issues
                ) {
                    const errTitle = data.title ?? '';
                    const dataError = data.errors ? JSON.stringify(data.errors) : '';
                    const dataDetails = data.details ?? dataError;

                    //Getting more information about the error
                    const errDetails = errTitle + dataDetails;

                    switch (data.errorMessageType) {
                        case 1: //OnDeleteChildrenFound
                            renderToast(intl.formatMessage({ id: 'common_delete_linked_children_error' }));
                            break;
                        case 2: //UniqueConstraintError
                            renderToast(intl.formatMessage({ id: 'common_unique_constraint_error' }));
                            break;
                        case 3: //DBNetworkError
                            renderToast(intl.formatMessage({ id: 'common_db_network_error' }), errDetails ?? '');
                            history.replace('/connection');
                            break;
                        default:
                            const errMsg = intl.formatMessage({ id: 'common_generic_processing_error' });
                            renderToast(errMsg, errDetails);
                            break;
                    }
                }
            }
            return Promise.reject(error);
        }
    );

    return { reqId, resId };
};

export const clearInterceptors = (reqId, resId) => {
    axios.interceptors.request.eject(reqId);
    axios.interceptors.response.eject(resId);
};
