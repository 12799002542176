import { useState, useCallback, useEffect, useRef } from 'react';
import { Button, FormControl, InputRightAddon, Alert, AlertIcon } from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';

import { getItemProperties, updateItem, validateItemOwnershipPercentage } from '../../services/items';
import { updateItemOwnershipPercentage } from '../../services/portfolios';
import { mapDynamicProperties, getUpdatedProperties } from '../../services/dynamicProperties';

import NumberInputField from '../forms/NumberInputField';

import useCommonToast from '../../hooks/useCommonToast';

import MainModal from '../modal/MainModal';

import DynamicPropertiesSkeleton from '../../components/utils/DynamicPropertiesSkeleton';
import Error from '../../components/utils/Error';
import AutomaticFormikDirtyCheck from '../../components/forms/AutomaticFormikDirtyCheck';

import DynamicPropertyTabs from './DynamicPropertyTabs';

const ItemPropertiesModal = ({
    itemId,
    onNodeOwnershipPercentageUpdate,
    portfolio,
    successMessage,
    forAutoForm,
    ...rest
}) => {
    const [ownershipPercentage, setOwnershipPercentage] = useState(
        portfolio ? portfolio.properties.ownershipPercentage : 0
    );

    const [ownershipPercentageValidationError, setOwnershipPercentageValidationError] = useState(false);

    const [itemProperties, setItemProperties] = useState({
        data: [],
        loading: true,
        error: false,
    });

    const [isLoading, setIsLoading] = useState(false);
    const [isDirty, setIsDirty] = useState(false);

    const submitButton = useRef();
    const intl = useIntl();
    const { toast } = useCommonToast();

    const btnDisabled = ownershipPercentageValidationError || !isDirty;

    const fetchItemProperties = useCallback(() => {
        getItemProperties(itemId)
            .then((data) => setItemProperties((prev) => ({ ...prev, data })))
            .catch(() => setItemProperties((prev) => ({ ...prev, error: true })))
            .finally(() => setItemProperties((prev) => ({ ...prev, loading: false })));
    }, [itemId]);

    useEffect(() => {
        fetchItemProperties();
    }, [fetchItemProperties, itemId]);

    const initialFormValues = {
        properties: mapDynamicProperties(itemProperties.data),
    };

    const onFormSubmit = async (values) => {
        try {
            setIsLoading(true);

            const updatedProperties = getUpdatedProperties(itemProperties.data, values);
            const hasUpdatedProperties = updatedProperties.length > 0;

            // Update Ownership Percentage
            if (portfolio) {
                const formattedOwnershipPercentage =
                    ownershipPercentage % 1 !== 0
                        ? Number.parseFloat(ownershipPercentage).toFixed(2)
                        : ownershipPercentage;

                await updateItemOwnershipPercentage(
                    portfolio.properties.portfolioId,
                    portfolio.properties.id,
                    formattedOwnershipPercentage
                );

                onNodeOwnershipPercentageUpdate(formattedOwnershipPercentage);

                toast({
                    id: 'itemPropertiesSuccessToast',
                    message: intl.formatMessage({ id: 'common_generic_item_change_success' }),
                });

                if (!hasUpdatedProperties) {
                    setIsLoading(false);
                    rest.onClose();
                }
            }

            // Update properties
            if (hasUpdatedProperties) {
                await updateItem(itemId, updatedProperties);

                toast({
                    id: 'itemPropertiesSuccessToast',
                    message: successMessage || intl.formatMessage({ id: 'common_generic_item_change_success' }),
                });

                setIsLoading(false);
                rest.onClose();
            }
        } catch (error) {
            setIsLoading(false);
        }
    };

    const validateOwnershipPercentage = (input) => {
        const hasError = validateItemOwnershipPercentage(input);

        if (hasError) {
            setOwnershipPercentageValidationError(true);
            return;
        }

        setOwnershipPercentageValidationError(false);
    };

    const onNumberInputChange = (value) => {
        //compare incoming value with the initial one
        const equal = parseInt(value) === portfolio.properties.ownershipPercentage;
        setOwnershipPercentage(value);
        validateOwnershipPercentage(value);
        setIsDirty(!equal);
    };

    return (
        <MainModal
            {...rest}
            header={
                <FormattedMessage
                    id={
                        forAutoForm
                            ? 'common_properties'
                            : `${portfolio ? 'load_properties_modal_title' : 'item_properties_modal_header'}`
                    }
                />
            }
            content={
                <>
                    {portfolio && (
                        <NumberInputField
                            mb={4}
                            maxW={250}
                            isInvalid={ownershipPercentageValidationError}
                            label={intl.formatMessage({ id: 'load_percent_owned' })}
                            onChange={(value) => onNumberInputChange(value)}
                            value={ownershipPercentage}
                            error={
                                ownershipPercentage > 100 &&
                                intl.formatMessage({ id: 'portfolios_percent_owned_error' })
                            }
                            hasStepperButtons={false}
                            rightElement={<InputRightAddon children="%" ml={1} />}
                        />
                    )}

                    {itemProperties.loading ? (
                        <DynamicPropertiesSkeleton />
                    ) : itemProperties.error ? (
                        <Error
                            primaryId="common_error"
                            secondaryId="common_loading_error"
                            additionalText="item properties"
                        />
                    ) : (
                        <>
                            {itemProperties.data.length > 0 ? (
                                <Formik initialValues={initialFormValues} onSubmit={onFormSubmit}>
                                    <Form>
                                        <FormControl isInvalid={ownershipPercentageValidationError}>
                                            <DynamicPropertyTabs groups={itemProperties.data} />

                                            <AutomaticFormikDirtyCheck
                                                isFormDirty={isDirty}
                                                onFormDirtyChange={setIsDirty}
                                            />

                                            {/* Used to submit the form through the footer slot button since it is outside of Formik */}
                                            <Button display="none" ref={submitButton} type="submit" />
                                        </FormControl>
                                    </Form>
                                </Formik>
                            ) : (
                                <Alert status="info">
                                    <AlertIcon />
                                    <FormattedMessage id="item_properties_none_found" />
                                </Alert>
                            )}
                        </>
                    )}
                </>
            }
            secondaryButton={
                <Button onClick={rest.onClose} variant="secondary" mr={3} textTransform="capitalize">
                    <FormattedMessage id="common_cancel" />
                </Button>
            }
            footerRightSlot={
                <Button
                    onClick={() => {
                        submitButton.current.click();
                    }}
                    type="submit"
                    isLoading={isLoading}
                    isDisabled={btnDisabled}
                >
                    <FormattedMessage id="load_properties_save_btn" />
                </Button>
            }
        />
    );
};

export default ItemPropertiesModal;
