import { FormattedMessage, useIntl } from 'react-intl';
import {
    Button,
    Box,
    Heading,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    SimpleGrid,
    Spacer,
} from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import { isFirstDayOfMonth } from 'date-fns';

import { mapPeriodFormValues } from '../../../services/batteries';
import { createDateAsUTC, lenientParse } from '../../../services/items';

import ParameterFormField from './ParameterFormField';

const ManageSubParameterPeriodModal = ({
    selectedParameter,
    subBatteryId,
    updateRibbonData,
    ribbonStaticData,
    ...rest
}) => {
    const intl = useIntl();

    const parameter = ribbonStaticData.parameters.find((p) => p.dbFieldName === selectedParameter.dbField);
    const allowNegativeValues = ['DischargingAdder', 'ChargingAdder'].includes(parameter.value.dbFieldName);

    const formHelpers = {
        startDate: {
            label: intl.formatMessage({ id: 'batteries_timeline_add_modal_start_date' }),
            isDisabled: false,
        },
        endDate: {
            label: intl.formatMessage({ id: 'batteries_timeline_add_modal_end_date' }),
            isDisabled: false,
        },
        peakPeriodId: {
            label: intl.formatMessage({ id: 'batteries_timeline_add_modal_peak_period' }),
            isDisabled: true,
        },
        batteryLevel: {
            label: intl.formatMessage({ id: 'batteries_timeline_add_modal_battery_level' }),
            isDisabled: true,
        },
        percentOutage: {
            label: intl.formatMessage({ id: 'batteries_timeline_add_modal_percent_outage' }),
            isDisabled: true,
        },
        value: {
            label: parameter.label + ' ' + intl.formatMessage({ id: 'batteries_timeline_add_modal_value' }),
            isDisabled: false,
            allowNegativeValues,
        },
        productTypeId: {
            label: intl.formatMessage({ id: 'batteries_timeline_add_modal_product_type' }),
            isDisabled: true,
        },
        emptyPlaceholder: '',
    };

    const initialFormValues = mapPeriodFormValues(selectedParameter.parameterValues, selectedParameter.dbField);

    const formFields = Object.entries(initialFormValues).map(([key]) => key);

    const validateForm = (values) => {
        const startDate = validateSiblingDate({
            value: values.startDate,
            siblingValue: values.endDate,
            type: 'start',
        });

        const endDate = validateSiblingDate({
            value: values.endDate,
            siblingValue: values.startDate,
            type: 'end',
        });

        const value = validateValueField({ value: values.value });

        return {
            ...(startDate ? { startDate } : null),
            ...(endDate ? { endDate } : null),
            ...(value ? { value } : null),
        };
    };

    const validateSiblingDate = ({ value, siblingValue, type }) => {
        if (!value) {
            return intl.formatMessage({ id: 'batteries_timeline_add_modal_required_value' });
        }

        if (!isFirstDayOfMonth(lenientParse(value))) {
            return intl.formatMessage({ id: 'batteries_timeline_modals_date_invalid_format' });
        }

        if (!siblingValue) return;

        if (type === 'start' && lenientParse(value).getTime() >= lenientParse(siblingValue).getTime())
            return intl.formatMessage({ id: 'batteries_common_modals_date_smaller' });
        if (type === 'end' && lenientParse(value).getTime() <= lenientParse(siblingValue).getTime())
            return intl.formatMessage({ id: 'batteries_common_modals_date_bigger' });
    };

    const validateValueField = ({ value }) => {
        if (!value) {
            return intl.formatMessage({ id: 'batteries_timeline_add_modal_required_value' });
        }
    };

    const onFormSubmit = async ({
        startDate,
        endDate,
        value,
        peakPeriodId,
        productTypeId,
        batteryLevel,
        percentOutage,
    }) => {
        const payload = {
            parameter: parameter.value,
            parameterPeriod: {
                startDate: createDateAsUTC(lenientParse(startDate)),
                endDate: createDateAsUTC(lenientParse(endDate)),
                value: Number(value),
                relatedPeriodIds: selectedParameter.parameterValues.periodIds,
            },
            subParameter: {
                peakPeriodId: Number(peakPeriodId) || null,
                productTypeId: Number(productTypeId) || null,
                batteryLevel: batteryLevel,
                percentOutage: percentOutage,
            },
        };

        await rest.checkOverlap(payload);
    };

    const dateFields = formFields.filter((field) => field === 'startDate' || field === 'endDate');
    const percentOutageField = formFields.filter((field) => field === 'percentOutage');
    const batteryLevelField = formFields.filter((field) => field === 'batteryLevel');
    const productTypeField = formFields.filter((field) => field === 'productTypeId');
    const peakPeriodField = formFields.filter((field) => field === 'peakPeriodId');
    const valueField = formFields.filter((field) => field === 'value');

    const hasAdditionalFields =
        (peakPeriodField.length > 0 && batteryLevelField.length > 0) || percentOutageField.length > 0;

    return (
        <>
            <Modal {...rest}>
                <ModalOverlay />

                <ModalContent pt={3}>
                    <ModalHeader pr={12}>
                        <Heading as="h3" variant="h3" textTransform="capitalize">
                            <FormattedMessage id={rest.modalTitleId} values={{ item: parameter.label }} />
                        </Heading>
                    </ModalHeader>

                    <ModalCloseButton mt={4} mr={3} h={6} w={6} />

                    <Formik initialValues={initialFormValues} onSubmit={onFormSubmit} validate={validateForm}>
                        {({ isSubmitting, isValid, dirty }) => (
                            <Form>
                                <ModalBody borderBottom="1px" borderColor="border-secondary" pb={4}>
                                    <SimpleGrid columns={{ xl: 2 }} spacingX={4} spacingY={4} py={3}>
                                        {dateFields.map((field, index) => (
                                            <ParameterFormField
                                                key={index}
                                                fieldName={field}
                                                formHelpers={formHelpers}
                                                ribbonStaticData={ribbonStaticData}
                                            />
                                        ))}
                                    </SimpleGrid>

                                    <SimpleGrid spacingX={4} spacingY={4}>
                                        {peakPeriodField.map((field, index) => (
                                            <ParameterFormField
                                                key={index}
                                                fieldName={field}
                                                formHelpers={formHelpers}
                                                ribbonStaticData={ribbonStaticData}
                                            />
                                        ))}

                                        {productTypeField.map((field, index) => (
                                            <ParameterFormField
                                                key={index}
                                                fieldName={field}
                                                formHelpers={formHelpers}
                                                ribbonStaticData={ribbonStaticData}
                                            />
                                        ))}
                                    </SimpleGrid>

                                    <SimpleGrid columns={{ xl: 2 }} spacingX={4} spacingY={4} py={3}>
                                        {hasAdditionalFields ? (
                                            <>
                                                <Box>
                                                    {batteryLevelField.map((field, index) => (
                                                        <ParameterFormField
                                                            key={index}
                                                            fieldName={field}
                                                            formHelpers={formHelpers}
                                                            ribbonStaticData={ribbonStaticData}
                                                        />
                                                    ))}

                                                    {percentOutageField.map((field, index) => (
                                                        <ParameterFormField
                                                            key={index}
                                                            fieldName={field}
                                                            formHelpers={formHelpers}
                                                            ribbonStaticData={ribbonStaticData}
                                                        />
                                                    ))}
                                                </Box>
                                                <Box>
                                                    {valueField.map((field, index) => (
                                                        <ParameterFormField
                                                            key={index}
                                                            fieldName={field}
                                                            formHelpers={formHelpers}
                                                            ribbonStaticData={ribbonStaticData}
                                                        />
                                                    ))}
                                                </Box>
                                            </>
                                        ) : (
                                            <>
                                                <Spacer />
                                                <Box>
                                                    {valueField.map((field, index) => (
                                                        <ParameterFormField
                                                            key={index}
                                                            fieldName={field}
                                                            formHelpers={formHelpers}
                                                            ribbonStaticData={ribbonStaticData}
                                                        />
                                                    ))}
                                                </Box>
                                            </>
                                        )}
                                    </SimpleGrid>
                                </ModalBody>

                                <ModalFooter justifyContent="stretch" flexWrap="wrap">
                                    <Box ml="auto">
                                        <Button variant="secondary" onClick={rest.onClose}>
                                            <Box as="span" textTransform="capitalize">
                                                <FormattedMessage id="common_cancel" />
                                            </Box>
                                        </Button>

                                        <Button
                                            type="submit"
                                            isLoading={isSubmitting}
                                            isDisabled={!(isValid && dirty)}
                                            ml={3}
                                        >
                                            <FormattedMessage id={rest.btnTitleId} />
                                        </Button>
                                    </Box>
                                </ModalFooter>
                            </Form>
                        )}
                    </Formik>
                </ModalContent>
            </Modal>
            {rest.periodOverlapModal}
        </>
    );
};

export default ManageSubParameterPeriodModal;
