import { Box } from '@chakra-ui/layout';
import { Skeleton } from '@chakra-ui/skeleton';
import { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { getResetPlantTypesCount } from 'services/hedges';

export const TechTypeCount = ({ hedgeId, instrumentTypeIds }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [count, setCount] = useState();

    useEffect(() => {
        setIsError(false);
        setIsLoading(true);
        getResetPlantTypesCount(hedgeId, instrumentTypeIds)
            .then((c) => setCount(c))
            .catch(() => setIsError(true))
            .finally(() => setIsLoading(false));
    }, [hedgeId, instrumentTypeIds]);

    if (isLoading) {
        return <Skeleton my={4} height="4" />;
    }

    if (isError) {
        return null;
    }

    return (
        <Box my={4} color="orange.600">
            <FormattedMessage id="hedge_reset_tech_type_count" values={{ count: count }} />
        </Box>
    );
};
