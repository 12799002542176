import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from '@emotion/styled/macro';
import { parseISO } from 'date-fns';
import { Box, Tooltip, Input, InputGroup, InputRightElement } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';

import { isMainParameter } from '../../../services/batteries';
import { createDateAsUTC, lenientParse } from '../../../services/items';

import { onHasUnsavedChanges } from '../../../store/helpers/helpersSlice';

import CustomIconButton from '../../utils/CustomIconButton';

import { ReactComponent as WarningIcon } from '../../../icons/warning.svg';

const TimelineTrackElement = ({
    time,
    element,
    parameterDbField,
    onMainPeriodClick,
    onSubPeriodClick,
    onTimelineValueChange,
    ribbonStaticData,
    subParameterData,
}) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const hasUnsavedChanges = useSelector((state) => state.helpers.hasUnsavedChanges);

    const initialInputValue =
        element.value === 'null' ? intl.formatMessage({ id: 'batteries_timeline_null_value' }) : element.value;

    const [inputValue, setInputValue] = useState(initialInputValue);
    const [isInvalid, setIsInvalid] = useState(null);

    const isMain = isMainParameter(parameterDbField);
    const parameter = ribbonStaticData.parameters.find((p) => p.dbFieldName === parameterDbField);
    const elementStyle = time.toStyleLeftAndWidth(parseISO(element.startDate), parseISO(element.endDate), true);
    const allowNegativeValues = ['DischargingAdder', 'ChargingAdder'].includes(parameter.value.dbFieldName);

    const onInputChange = (e) => {
        const value = e.target.value;
        const trimmed = value.trim();
        setInputValue(trimmed);

        const isValid = validateInputValue(trimmed);
        setIsInvalid(!isValid);

        const isDirty = !isEqual(Number(e.target.value), element.value);
        if (isDirty !== hasUnsavedChanges) {
            dispatch(onHasUnsavedChanges(isDirty));
        }
    };

    const validateInputValue = (value) => {
        if (!value) {
            return true;
        }

        const numbersRegExp = new RegExp(allowNegativeValues ? '^-?[0-9]+([.][0-9]+)?$' : '^[0-9]+([.][0-9]+)?$');

        if (!numbersRegExp.test(value)) {
            return false;
        }

        return true;
    };

    const onKeyPress = (e) => {
        const translatedNull = intl.formatMessage({ id: 'batteries_timeline_null_value' });

        if (e.key === 'Enter' && !isInvalid && e.target.value !== translatedNull) {
            const payload = {
                parameter: parameter.value,
                parameterPeriod: {
                    startDate: createDateAsUTC(lenientParse(element.startDate)),
                    endDate: createDateAsUTC(lenientParse(element.endDate)),
                    value: e.target.value === '' ? null : Number(e.target.value),
                    relatedPeriodIds: element.periodIds,
                },
                subParameter: {},
            };

            // payload object's subParameter gets populated with data depending on the period type
            if (!isMain) {
                payload.subParameter = {
                    peakPeriodId: Number(subParameterData.peakPeriodId) || null,
                    productTypeId: Number(subParameterData.productTypeId) || null,
                    batteryLevel: subParameterData.batteryLevel,
                    percentOutage: subParameterData.percentOutage,
                };
            }

            onTimelineValueChange(payload, element.startDate);
        }
    };

    // for yearly view, if given element spans for more than six consecutive months (hence having > 6 periodIds) we have space to show the default element;
    // otherwise we have to show a tooltip and hide the label since there is no place for it
    const isLargeEnough = time.selectedView === 1 || (time.selectedView === 2 && element.periodIds?.length > 6);

    return (
        <Tooltip isDisabled={isLargeEnough} label={inputValue === 0 ? inputValue.toString() : inputValue}>
            <TrackElementWrapper
                style={elementStyle}
                onClick={() => {
                    if (!isInvalid) {
                        isMain
                            ? onMainPeriodClick(element, parameterDbField)
                            : onSubPeriodClick(element, parameterDbField);
                    }
                }}
            >
                {isLargeEnough && (
                    <StickyInputGroup>
                        <StyledInput
                            type="text"
                            value={inputValue}
                            onChange={onInputChange}
                            isInvalid={isInvalid}
                            onKeyPress={onKeyPress}
                            onClick={(e) => e.stopPropagation()}
                            ml={1}
                        />
                        {isInvalid && (
                            <InputRightElement>
                                <Tooltip label={<FormattedMessage id="batteries_timeline_number_invalid_value" />}>
                                    <StyledCustomIconButton
                                        icon={<WarningIcon />}
                                        onClick={(e) => e.stopPropagation()}
                                    />
                                </Tooltip>
                            </InputRightElement>
                        )}
                    </StickyInputGroup>
                )}
            </TrackElementWrapper>
        </Tooltip>
    );
};

const TrackElementWrapper = styled(Box)`
    position: absolute;
    top: 2px;
    height: 46px;
    background: var(--chakra-colors-background-track-element);
    border-radius: 8px;
    padding-right: 4px;
`;

const StickyInputGroup = styled(InputGroup)`
    align-items: center;
    height: 100%;
    width: 100px;
    position: sticky;
    left: 0;
`;

const StyledInput = styled(Input)`
    background: var(--chakra-colors-white);
    font-size: 13px;
    width: 100px;
    padding-right: 36px;

    &:hover,
    &:focus {
        background: var(--chakra-colors-white);
    }
`;

const StyledCustomIconButton = styled(CustomIconButton)`
    svg {
        width: 16px;
        height: 16px;
        margin-top: 2px;
    }
    svg path {
        stroke: var(--chakra-colors-transparent);
    }
`;

export default TimelineTrackElement;
