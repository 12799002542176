import { Redirect } from 'react-router-dom';
import { useMsal, useIsAuthenticated, UnauthenticatedTemplate } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import { Flex, Box, Link, Button, Text, Center } from '@chakra-ui/react';
import styled from '@emotion/styled/macro';
import { FormattedMessage } from 'react-intl';

import Logo from '../components/utils/Logo';
import SplashScreen from '../components/layout/SplashScreen';

import { ReactComponent as PowerSimmLogo } from '../icons/logos/power-simm-outlines.svg';

import aaLightBlueURL from '../icons/logos/aa-logo-light-blue.svg';
import aaBlueURL from '../icons/logos/aa-logo-blue.svg';

const Login = () => {
    const { instance, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    if (isAuthenticated) {
        return <Redirect to={{ pathname: '/', search: window.location.search }} />;
    }

    return (
        <>
            <SplashScreen />

            <UnauthenticatedTemplate>
                <Wrapper>
                    <Center
                        bg="background-primary"
                        flexGrow={{ base: 1, md: 0 }}
                        flexDirection="column"
                        w="100%"
                        gap={12}
                        maxW={{ md: '480px' }}
                        p={{ base: 4, md: 12 }}
                        borderRadius={{ md: 'lg' }}
                        margin={{ md: 'auto' }}
                    >
                        <Center>
                            <Logo w="250px" />
                        </Center>

                        <Button
                            isDisabled={inProgress === InteractionStatus.Login}
                            w={{ base: '100%', md: '250px' }}
                            height={10}
                            onClick={() => instance.loginRedirect()}
                        >
                            <FormattedMessage id="login_sign_in_btn" />
                        </Button>

                        <Flex justifyContent="center" alignItems="center">
                            <Text variant="regular" mr={1} pt={5} fontSize={14} color="blue.500">
                                <FormattedMessage id="login_powered_by" />
                            </Text>
                            <Logo h="38px" w="116px" lightModeSrc={aaBlueURL} darkModeSrc={aaLightBlueURL} />
                        </Flex>
                    </Center>
                </Wrapper>
                <Box
                    display={{ base: 'none', md: 'block' }}
                    position="fixed"
                    top={0}
                    right={0}
                    left={0}
                    bg="white"
                    h={12}
                    p={1}
                >
                    <PowerSimmLogo width="250px" />
                </Box>
                <Center flexDir={{ base: 'column', md: 'row' }} position="fixed" bottom={2} right={0} left={0}>
                    <Text variant="regular" color={{ base: 'black', md: 'white' }} textAlign="center">
                        <FormattedMessage id="login_trademark" />
                    </Text>
                    <Link
                        ml={2}
                        display="block"
                        textAlign="center"
                        color={{ base: 'blue.400', md: 'blue.300' }}
                        href="https://www.ascendanalytics.com/privacy-policy-2024"
                    >
                        <FormattedMessage id="login_privacy_policy" />
                    </Link>
                </Center>
            </UnauthenticatedTemplate>
        </>
    );
};

const Wrapper = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    overflow: auto;
    animation: 3s ease 0s normal forwards 1 entry;

    @keyframes entry {
        0% {
            transform: translateY(100vh);
        }
        66% {
            transform: translateY(100vh);
        }
        100% {
            transform: translateY(0);
        }
    }
`;

export default Login;
