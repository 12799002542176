import React, { useCallback, useMemo } from 'react';
import { useMsal } from '@azure/msal-react';
import { Icon } from '@chakra-ui/react';
import { useIntl } from 'react-intl';
import { isArray } from 'lodash';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styled from '@emotion/styled/macro';

import Logo from '../components/utils/Logo';
import { ReactComponent as DashboardIcon } from '../icons/dashboard.svg';
import { ReactComponent as PortfolioIcon } from '../icons/portfolio.svg';
import { ReactComponent as TurbineIcon } from '../icons/turbine.svg';
import { ReactComponent as HelpIcon } from '../icons/help.svg';
import { ReactComponent as CogIcon } from '../icons/cog.svg';
import { ReactComponent as ArrowDropDownIcon } from '../icons/arrow_drop_down.svg';
import mappedItemTypes from 'services/items/mappedItemTypes';
import { findNestedRoutes, findNestedHelpPageUrls, isRouteIncludedInList } from 'services/navigation';

const useNavigation = () => {
    const { accounts, instance } = useMsal();
    const intl = useIntl();
    const location = useLocation();
    const { config: remoteConfig } = useSelector((state) => state.navigation);
    const hasUnsavedChanges = useSelector((state) => state.helpers.hasUnsavedChanges);

    const userName = useMemo(() => (accounts.length > 0 ? accounts[0].name : ''), [accounts]);
    const isTopMenuFetched = useMemo(() => !!remoteConfig, [remoteConfig]);

    const getMappedItem = useCallback((typeId) => {
        for (const type in mappedItemTypes) {
            if (mappedItemTypes[type].id === typeId) {
                return mappedItemTypes[type];
            }
        }
        return null;
    }, []);

    const routesConfig = useMemo(() => {
        const navigationMapperAutoformUrlGenerator = function ({ subschemaCode, parameterValues } = {}) {
            return subschemaCode
                ? `${this.pathname}/${subschemaCode}${parameterValues ? '?parameterValues=' + parameterValues : ''}`
                : '/';
        };

        const routePath = location?.pathname?.toLowerCase();

        // Hide top navigation menus if we are on connection or environment or book pages
        const isConnectionRoute =
            routePath.startsWith('/connection') ||
            routePath.startsWith('/environment') ||
            routePath.startsWith('/book');

        // Hide Run a Study navigation menu button when on the run a study page
        const isRunStudyRoute = routePath.startsWith('/run-a-study');

        return {
            root: {
                pathname: '/',
                mobileConfig: {
                    isVisible: false,
                },
            },
            dashboard: {
                pathname: '/',
                icon: 'dashboard',
                desktopConfig: {
                    delimiterGap: '15px',
                    delimiterColor: 'transparent',
                    isVisible: !isConnectionRoute,
                },
                mobileConfig: {
                    isVisible: !isConnectionRoute,
                },
            },
            portfolios: {
                pathname: '/portfolios',
                icon: 'portfolios',
                desktopConfig: {
                    isVisible: !isConnectionRoute,
                },
                mobileConfig: {
                    isVisible: !isConnectionRoute,
                },
            },
            runStudy: {
                pathname: '/run-a-study',
                desktopConfig: {
                    isVisible: !isRunStudyRoute && !isConnectionRoute,
                },
                mobileConfig: {
                    isVisible: !isRunStudyRoute && !isConnectionRoute,
                },
            },
            loads: {
                pathname: '/loads',
            },
            batteries: {
                pathname: '/batteries',
            },
            wind: {
                pathname: '/wind',
            },
            solar: {
                pathname: '/solar',
            },
            thermal: {
                pathname: '/thermal',
            },
            'hydro-project': {
                pathname: '/hydro-project',
            },
            dsm: {
                pathname: '/dsm',
            },
            'resource-planning': {
                pathname: '/resource-planning',
            },
            'hedges-contracts': {
                pathname: '/hedges-contracts',
            },
            'basis-configurator': {
                pathname: '/basis-configurator',
            },
            basis: {
                pathname: '/basis',
            },
            variables: {
                pathname: '/variables',
            },
            charts: {
                pathname: '/charts',
            },
            'realized-values': {
                pathname: '/realized-values',
            },
            'global-constraints': {
                pathname: '/global-constraints',
            },
            autoforms: {
                pathname: '/autoform',
                getUrl: navigationMapperAutoformUrlGenerator,
            },
            'spot-price-autoform': {
                pathname: '/spot-price-autoform',
            },
            'user-management': {
                pathname: '/user-management',
            },
            'weighting-schema': {
                pathname: '/weighting-schema',
            },
            'economic-reserve': {
                pathname: '/economic-reserve',
            },
            'shape-set': {
                pathname: '/shape-set',
            },
            'admin-express-jobs': {
                pathname: '/admin-express-jobs',
            },
            'express-jobs': {
                pathname: '/express-jobs',
            },
            'fuels-and-emissions': {
                pathname: '/fuels-and-emissions',
            },
            'bidding-strategies': {
                pathname: '/bidding-strategies',
            },
            jobs: {
                pathname: '/jobs',
            },
            'main-market': {
                pathname: '/main-market',
            },
            'forward-item': {
                pathname: '/forward-item',
            },
            'spot-price': {
                pathname: '/spot-price',
            },
            'price-formula': {
                pathname: '/price-formula',
            },
            connection: {
                pathname: '/connection',
            },
            __signOut: {
                //for hover only
                pathname: '/_signOut',
                clickHandler: (event) => {
                    if (!hasUnsavedChanges) {
                        event.preventDefault();
                        event.stopPropagation();

                        instance.logoutRedirect();
                    }
                },
            },
            __darkMode: {
                pathname: '/_darkMode',
                mobileConfig: {
                    transform: 'Checkbox',
                },
            },
            __items: {
                icon: 'turbine',
                desktopConfig: {
                    isVisible: !isConnectionRoute,
                },
                mobileConfig: {
                    isVisible: !isConnectionRoute,
                },
            },
            __autoforms: {
                icon: 'cog',
                desktopConfig: {
                    isVisible: !isConnectionRoute,
                },
                mobileConfig: {
                    isVisible: !isConnectionRoute,
                },
            },
            __namedMenu: {
                desktopConfig: {
                    delimiterGap: '12px',
                    delimiterColor: 'transparent',
                    labelMaxWidth: 162,
                    menuPlacement: 'bottom-end',
                },
                mobileConfig: {
                    transform: 'Link',
                },
            },
        };
    }, [location?.pathname, instance, hasUnsavedChanges]);

    const defaultConfig = useMemo(
        () => [
            [
                {
                    type: 'ImageLink',
                    routeName: 'root',
                    image: 'logo',
                    //do not remove this
                    helpPageUrl: null,
                },
                {
                    entryPoint: 'leftSideItems',
                },
                {
                    type: 'Menu',
                    label: 'Items',
                    routeName: '__items',
                    items: [],
                },
                {
                    type: 'Menu',
                    routeName: '__autoforms',
                    items: [],
                },
                {
                    entryPoint: 'hiddenItems',
                },
            ],
            [
                {
                    entryPoint: 'rightSideItems',
                },
                {
                    type: 'Menu',
                    label: userName,
                    routeName: '__namedMenu',
                    items: [
                        {
                            type: 'MenuItem',
                            routeName: 'connection',
                            label: intl.formatMessage({ id: 'connection_connection' }),
                        },
                        {
                            type: 'Checkbox',
                            routeName: '__darkMode',
                            label: intl.formatMessage({ id: 'common_dark_mode' }),
                        },
                        {
                            type: 'MenuItem',
                            routeName: '__signOut',
                            label: intl.formatMessage({ id: 'common_sign_out' }),
                        },
                    ],
                },
            ],
        ],
        [intl, userName]
    );

    const assetsMap = useMemo(
        () => ({
            logo: <Logo data-comp="navigation-asset" width={'150px'} height={'44px'} />,
            dashboard: <StyledDefaultIcon as={DashboardIcon} mr={2} data-comp="navigation-asset" />,
            portfolios: <StyledDefaultIcon as={PortfolioIcon} mr={2} data-comp="navigation-asset" />,
            turbine: <StyledDefaultIcon as={TurbineIcon} mr={2} data-comp="navigation-asset" />,
            cog: <StyledStandaloneIcon as={CogIcon} data-comp="navigation-asset" />,
            help: <StyledStandaloneIcon as={HelpIcon} data-comp="navigation-asset" />,
            dropdownArrow: <StyledDefaultIcon as={ArrowDropDownIcon} data-comp="dropdown-asset" />,
        }),
        []
    );

    const preProcessConfigNode = useCallback(
        (configNode) => {
            let result = null;

            if (isArray(configNode)) {
                result = configNode.map((item) => preProcessConfigNode(item)).filter((item) => item !== null);
            } else if (configNode?.type !== 'Menu' || configNode?.items?.length) {
                const { items, image: imageName, ...restConfigNodeProps } = configNode;
                const routeConfig = routesConfig[configNode.routeName];
                const iconName = routeConfig?.icon;

                let url =
                    configNode?.url ??
                    (routeConfig?.getUrl
                        ? routeConfig.getUrl(configNode?.routeParameters)
                        : routeConfig?.pathname ?? '/');

                const clickHandler = routeConfig?.clickHandler;
                const icon = iconName ? assetsMap[iconName] : null;
                const image = imageName ? assetsMap[imageName] : null;

                result = {
                    ...restConfigNodeProps,
                    items: items?.map((item) => preProcessConfigNode(item)),
                    url,
                    clickHandler,
                    icon,
                    image,
                    desktopConfig: routeConfig?.desktopConfig,
                    mobileConfig: routeConfig?.mobileConfig,
                };
            }

            return result;
        },
        [routesConfig, assetsMap]
    );

    const isPathMatched = useCallback((target, candidate) => {
        if (target === '/') {
            return target === candidate;
        }

        const targetBasePath = target.split('?')[0];
        const targetParts = targetBasePath.split('/');
        const candidateParts = candidate.split('?')[0].split('/');

        return targetBasePath === candidateParts.slice(0, targetParts.length).join('/');
    }, []);

    const findAvailableHelpUrl = useCallback(
        (item) => {
            let result = null;

            if (Array.isArray(item)) {
                for (let i = 0; i < item.length; i++) {
                    const url = findAvailableHelpUrl(item[i]);

                    if (url != null) {
                        return url;
                    }
                }
            } else if (item?.items) {
                return findAvailableHelpUrl(item.items);
            } else if (isPathMatched(item.url, location.pathname)) {
                return item?.helpPageUrl;
            }

            return result;
        },
        [location, isPathMatched]
    );

    const mergeLeftNavigationPart = useCallback(
        (leftNavigation) => {
            const result = [];

            for (let i = 0; i < leftNavigation.length; i++) {
                const item = leftNavigation[i];

                if (item.entryPoint && remoteConfig && remoteConfig[item.entryPoint]) {
                    result.push(...remoteConfig[item.entryPoint]);
                } else {
                    const data = { ...item };

                    //eslint-disable-next-line default-case
                    switch (item?.routeName) {
                        case '__items':
                            data.items = [...(data.items ?? []), ...(remoteConfig?.itemsMenu ?? [])];
                            break;
                        case '__autoforms':
                            data.items = [...(data.items ?? []), ...(remoteConfig?.autoformsMenu ?? [])];
                            break;
                    }

                    result.push(data);
                }
            }

            return result;
        },
        [remoteConfig]
    );

    const mergeRightNavigationPart = useCallback(
        (rightNavigation) => {
            const result = [];

            for (let i = 0; i < rightNavigation.length; i++) {
                const item = rightNavigation[i];

                if (item.entryPoint && remoteConfig && remoteConfig[item.entryPoint]) {
                    result.push(...remoteConfig[item.entryPoint]);
                } else {
                    const data = { ...item };

                    //eslint-disable-next-line default-case
                    switch (item?.routeName) {
                        case '__namedMenu':
                            data.items = [
                                ...(data.items?.length ? data.items.slice(0, 1) : []),
                                ...(remoteConfig?.profileMenu ?? []),
                                ...(data.items?.length > 1 ? data.items.slice(1) : []),
                            ];
                            break;
                    }

                    result.push(data);
                }
            }

            return result;
        },
        [remoteConfig]
    );

    const preProcessedConfig = useMemo(() => {
        let result = [];
        const [leftNavigation, rightNavigation] = defaultConfig;

        const mergedLeftNavigation = mergeLeftNavigationPart(leftNavigation);
        const mergedRightNavigation = mergeRightNavigationPart(rightNavigation);
        const mergedConfig = [[...mergedLeftNavigation], [...mergedRightNavigation]];

        result = preProcessConfigNode(mergedConfig);

        const helpUrl = findAvailableHelpUrl(result);

        if (helpUrl && result.length) {
            result[0].push({
                type: 'Link',
                icon: assetsMap.help,
                url: helpUrl,
            });
        }
        return result;
    }, [
        preProcessConfigNode,
        defaultConfig,
        assetsMap,
        findAvailableHelpUrl,
        mergeLeftNavigationPart,
        mergeRightNavigationPart,
    ]);

    const adminRoutes = useMemo(() => {
        let result = [];

        if (preProcessedConfig?.length) {
            const [leftPart, rightPart] = preProcessedConfig;

            const predicate = (currentValue) => currentValue?.adminOnly;

            result = [...findNestedRoutes(leftPart, predicate), ...findNestedRoutes(rightPart, predicate)];
        }

        return result;
    }, [preProcessedConfig]);

    const isAdminRoute = useCallback((url) => isRouteIncludedInList(url, adminRoutes), [adminRoutes]);

    const disabledRoutes = useMemo(() => {
        let result = [];

        if (preProcessedConfig?.length) {
            const [leftPart, rightPart] = preProcessedConfig;

            const predicate = (currentValue) => currentValue?.isEnabled === false;

            result = [...findNestedRoutes(leftPart, predicate), ...findNestedRoutes(rightPart, predicate)];
        }

        return result;
    }, [preProcessedConfig]);

    const routesByLabel = useCallback(
        (label) => {
            let result = [];

            if (preProcessedConfig?.length) {
                const [leftPart, rightPart] = preProcessedConfig;

                const predicate = (currentValue) => currentValue?.label === label;

                result = [
                    ...findNestedHelpPageUrls(leftPart, predicate),
                    ...findNestedHelpPageUrls(rightPart, predicate),
                ];
            }

            return result;
        },
        [preProcessedConfig]
    );

    const routesByItemTypeId = useCallback(
        (itemTypeId) => {
            let result = [];

            if (preProcessedConfig?.length) {
                const [leftPart, rightPart] = preProcessedConfig;

                const predicate = (currentValue) =>
                    currentValue?.routeParameters?.parameterValues === itemTypeId.toString();

                result = [
                    ...findNestedHelpPageUrls(leftPart, predicate),
                    ...findNestedHelpPageUrls(rightPart, predicate),
                ];
            }

            return result;
        },
        [preProcessedConfig]
    );

    const isDisabledRoute = useCallback((url) => isRouteIncludedInList(url, disabledRoutes), [disabledRoutes]);

    const getHelpPageUrlFromConfigByLabel = useCallback(
        (label) => {
            let matchingUrls = routesByLabel(label);
            if (matchingUrls != null && matchingUrls.length > 0) {
                return matchingUrls[0];
            }
            return null;
        },
        [routesByLabel]
    );

    const getHelpPageUrlFromConfigByItemTypeId = useCallback(
        (itemTypeId) => {
            if (itemTypeId != null) {
                let matchingUrls = routesByItemTypeId(itemTypeId);
                if (matchingUrls != null && matchingUrls.length > 0) {
                    return matchingUrls[0];
                } else {
                    return null;
                }
            }

            return null;
        },
        [routesByItemTypeId]
    );

    const getHelpPageUrl = useCallback(
        (itemTypeId) => {
            if (itemTypeId != null) {
                let foundUrl = getHelpPageUrlFromConfigByItemTypeId(itemTypeId);
                if (foundUrl != null) {
                    return foundUrl;
                }

                let mappedItem = getMappedItem(itemTypeId);
                if (mappedItem != null) {
                    let searchLabel = mappedItem.alias ?? mappedItem.description;
                    foundUrl = getHelpPageUrlFromConfigByLabel(searchLabel);
                    if (foundUrl != null) {
                        return foundUrl;
                    }
                }
            }

            return findAvailableHelpUrl(preProcessedConfig);
        },
        [
            preProcessedConfig,
            findAvailableHelpUrl,
            getHelpPageUrlFromConfigByLabel,
            getHelpPageUrlFromConfigByItemTypeId,
            getMappedItem,
        ]
    );

    return useMemo(
        () => ({
            config: preProcessedConfig,
            assetsMap,
            adminRoutes,
            isAdminRoute,
            disabledRoutes,
            isTopMenuFetched,
            isDisabledRoute,
            getHelpPageUrl,
            getHelpPageUrlFromConfigByLabel,
        }),
        [
            preProcessedConfig,
            assetsMap,
            adminRoutes,
            isAdminRoute,
            disabledRoutes,
            isTopMenuFetched,
            isDisabledRoute,
            getHelpPageUrl,
            getHelpPageUrlFromConfigByLabel,
        ]
    );
};

const StyledDefaultIcon = styled(Icon)`
    width: 30px;
    height: 30px;
    color: var(--chakra-colors-gray-400);
`;

const StyledStandaloneIcon = styled(Icon)`
    width: 24px;
    height: 24px;
    color: var(--chakra-colors-gray-400);
`;

export default useNavigation;
