import { useState, useEffect, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Flex, Button, Container, Text, useDisclosure } from '@chakra-ui/react';
import { useMsal } from '@azure/msal-react';

import {
    getBooksByEnvAndConnectionId,
    setAccountConnectionConfig,
    setupDefaultConnectionHeaders,
    setURLConnectionConfig,
    getUIVersionByNameOrDefault,
    removeConnectionSearchParams,
    getRedirectUrl,
} from '../services/connections';
import useConnectionSearch from '../hooks/useConnectionSearch';
import useCommonToast from '../hooks/useCommonToast';

import ConnectionStep from '../components/connection/ConnectionStep';
import ConnectionOption from '../components/connection/ConnectionOption';
import MainModal from '../components/modal/MainModal';

import { setAccountConnection } from '../store/connection/connectionSlice';

import { ReactComponent as BookOpenIcon } from '../icons/book-open-icon.svg';

const Book = () => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const history = useHistory();
    const { toast } = useCommonToast();
    const { accounts } = useMsal();

    const connectionConfig = useSelector((state) => state.connections);

    const defaultConnectionModal = useDisclosure();

    const { search: locationSearch } = useLocation();
    const searchParams = new URLSearchParams(locationSearch);

    let connectionId = searchParams.get('_connectionId') || connectionConfig.connection;
    const connectionTitle = searchParams.get('_connectionTitle') || connectionConfig.connectionTitle;
    const envId = searchParams.get('_envId') || connectionConfig.environment;
    const envTitle = searchParams.get('_envTitle') || connectionConfig.environmentTitle;
    let schemaId = searchParams.get('_schemaGroupId') || connectionConfig.schemaGroupId;
    const selectedVersionName = searchParams.get('_selectedVersionName') || connectionConfig.versionName;

    connectionId = Number(connectionId);
    schemaId = Number(schemaId);

    const [book, setBook] = useState({
        isLoading: false,
        options: [],
        error: '',
        value: connectionConfig?.book,
        title: connectionConfig?.bookTitle,
    });

    const [version, setVersion] = useState({ name: '', url: null, isDefault: false });

    const { search, setSearch, setSearchCollection, noSearchResultsFound, searchResultsFound } = useConnectionSearch();

    const handleSearch = (value) => {
        setSearch((prev) => ({ ...prev, term: value }));
        setSearchCollection(book.options);
    };

    const getVersion = useCallback(async () => {
        try {
            const selectedVersionInfo = await getUIVersionByNameOrDefault(connectionId, envId, selectedVersionName);

            if (selectedVersionInfo?.url) {
                // Set version info
                setVersion(selectedVersionInfo);
            } else {
                // Connection has no match for selected version, no default and possibly no versions at all
                toast({
                    status: 'error',
                    message: intl.formatMessage(
                        { id: 'connection_nodata_fetch_versions' },
                        { connection: connectionTitle, environment: envTitle }
                    ),
                });
            }
        } catch (err) {
            if (err?.response?.status === 500) {
                toast({
                    status: 'error',
                    message: intl.formatMessage({ id: 'connection_error_fetch_versions' }),
                });
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [intl]);

    const getBooks = useCallback(async () => {
        try {
            const data = await getBooksByEnvAndConnectionId(connectionId, envId);

            setBook((prev) => ({
                ...prev,
                isLoading: false,
                options: data.map((item) => ({
                    title: item.name,
                    subtitle: item.description,
                    value: item.id,
                    icon: BookOpenIcon,
                })),
            }));
        } catch (err) {
            if (err.response.status === 500) {
                setBook((prev) => ({
                    ...prev,
                    isLoading: false,
                    error: intl.formatMessage({ id: 'connection_error_fetch_books' }),
                }));
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [intl]);

    useEffect(() => {
        getVersion();
        getBooks();
    }, [getBooks, getVersion]);

    const onBookSelect = (selected, selectedTitle) => {
        setBook((prev) => ({ ...prev, value: selected, title: selectedTitle }));
        defaultConnectionModal.onOpen();
    };

    const onDismiss = (isDefaultConfig) => {
        defaultConnectionModal.onClose();

        const connectionConfigParams = new URLSearchParams({
            connection: connectionId.toString(),
            environment: envId.toString(),
            book: book.value.toString(),
            schemaGroupId: schemaId.toString(),
            isDefaultConfig: isDefaultConfig,
            localAccountId: accounts[0].localAccountId,
            versionName: version?.name?.toString(),
        });

        const connectionConfig = {
            connection: connectionId,
            connectionTitle: connectionTitle,
            environment: envId,
            environmentTitle: envTitle,
            book: book.value,
            bookTitle: book.title,
            schemaGroupId: schemaId,
            isDefaultConfig,
            versionName: version?.name,
        };

        dispatch(setAccountConnection(connectionConfig));

        if (isDefaultConfig) {
            // Set connection, environment, book, versionName config defaults and headers
            // Comming from a URL to a Local storage config's connectionId, environmentId, bookId
            // We need to clear current URL config in order to get the getPrioritizedConnectionConfig() since we still have both
            const clearCurrent = removeConnectionSearchParams(window.location.search);
            const searchParams = new URLSearchParams(clearCurrent);
            history.replace(window.location.pathname, searchParams.toString());

            setAccountConnectionConfig(connectionConfig);
            setupDefaultConnectionHeaders();
        }

        const currentUrl = window.location.origin + process.env.PUBLIC_URL;
        //Get the redirect Url from local storage
        const redirectUrl = getRedirectUrl();

        if (currentUrl.toUpperCase() === version?.url?.toUpperCase() || version?.url == null) {
            // No difference in url path or no valid selected version found
            if (isDefaultConfig) {
                // Set connectionId, environmentId, bookId config defaults and headers was done in the block above
                if (redirectUrl) {
                    history.push(redirectUrl?.pathname);
                } else {
                    history.push('/');
                }
            } else {
                const gotoUrl = redirectUrl
                    ? setURLConnectionConfig(connectionConfigParams, currentUrl + redirectUrl?.pathname)
                    : setURLConnectionConfig(connectionConfigParams, currentUrl);

                window.location.assign(gotoUrl.href);
            }
        } else {
            // Redirect to selected version url if it is valid and different from current path url
            const newUrl = version.url;

            const gotoUrl = redirectUrl
                ? setURLConnectionConfig(connectionConfigParams, newUrl + redirectUrl?.pathname)
                : setURLConnectionConfig(connectionConfigParams, newUrl);
            window.location.assign(gotoUrl.href);
        }
    };

    return (
        <Flex minHeight="100vh" bg="background-secondary" pt={40} pb={20} flexDirection="column">
            <MainModal
                scrollBehavior="inside"
                isOpen={defaultConnectionModal.isOpen}
                onClose={defaultConnectionModal.onClose}
                header={<FormattedMessage id="connection_set_as_default_title" />}
                content={
                    <>
                        <Text size="sm" variant="regular" mb={4}>
                            <FormattedMessage id="connection_set_as_default_p1" />
                        </Text>

                        <Text size="sm" variant="regular">
                            <FormattedMessage id="connection_set_as_default_p2" />
                        </Text>
                    </>
                }
                secondaryButton={
                    <Button textTransform="capitalize" minW={100} variant="secondary" onClick={() => onDismiss(false)}>
                        <FormattedMessage id="common_no" />
                    </Button>
                }
                footerRightSlot={
                    <Button textTransform="capitalize" minW={100} ml={4} onClick={() => onDismiss(true)}>
                        <FormattedMessage id="common_yes" />
                    </Button>
                }
            />

            <Container maxW={1056}>
                <ConnectionStep
                    onSearch={handleSearch}
                    current={3}
                    step={3}
                    total={3}
                    title={<FormattedMessage id="connection_select_book" />}
                    subtitle={<FormattedMessage id="connection_book" />}
                    description={
                        <FormattedMessage id="connection_please_select_the_book" values={{ environment: envTitle }} />
                    }
                    isLoading={book.isLoading}
                    error={book.error}
                    noResults={noSearchResultsFound}
                    onErrorDismiss={() => {
                        setBook((prev) => ({ ...prev, error: '' }));
                    }}
                >
                    {(searchResultsFound ? search.results : book.options).map((o) => (
                        <ConnectionOption
                            {...o}
                            key={o.value}
                            isSelected={Number(book.value) === o.value}
                            onClick={() => onBookSelect(o.value, o.title)}
                        />
                    ))}
                </ConnectionStep>
            </Container>
        </Flex>
    );
};

export default Book;
