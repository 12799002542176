import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { NavigationClient } from '@azure/msal-browser';

class CustomNavigationClient extends NavigationClient {
    constructor(history) {
        super();
        this.history = history;
    }

    async navigateInternal(url, options) {
        const relativePath = url.replace(window.location.origin, '');

        if (options.noHistory) {
            this.history.replace(relativePath);
        } else {
            this.history.push(relativePath);
        }

        return false;
    }
}

const useCustomNavigationClient = () => {
    const { instance } = useMsal();
    const history = useHistory();

    useEffect(() => {
        const navigationClient = new CustomNavigationClient(history);

        instance.setNavigationClient(navigationClient);
    }, [history, instance]);
};

export default useCustomNavigationClient;
