import { useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import {
    Button,
    Box,
    Heading,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    SimpleGrid,
    Skeleton,
} from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import { reduce } from 'lodash';

import useCommonToast from '../../hooks/useCommonToast';
import useHistoryPush from '../../hooks/useHistoryPush';

import { createItem, getItemTypeGenericAssetTemplates, applyGenericAssetTemplateItemType } from '../../services/items';
import { validateLongLat } from '../../services/portfolios';
import { isSubmitDisabled } from '../../services/utils';
import { getAutoFormGenericAssetTemplates, applyGenericAssetTemplate } from '../../services/autoforms';

import InputFormikField from '../forms/InputFormikField';
import ComboboxFormikField from '../forms/ComboboxFormikField';
import CheckboxFormikField from '../forms/CheckboxFormikField';
import ErrorAccordion from './ErrorAccordion';

// Shared component by Loads/Batteries/Renewables for adding new item of the given type
const AddItemModal = ({
    itemTypeId,
    updateItems,
    typeLabel,
    extraFields,
    extraFieldsInitialValues = {},
    formLabelsPhrasing = {},
    starterDataAutoFormSchemaCode = '',
    hideStarterDataOption = false,
    ...rest
}) => {
    const intl = useIntl();
    const { toast } = useCommonToast();
    const historyPush = useHistoryPush();
    const { url } = useRouteMatch();

    const books = useSelector((state) => state.item.dataForItemCreation.books);
    const pricingPoints = useSelector((state) => state.item.dataForItemCreation.pricingPoints);
    const book = useSelector((state) => state.connections.book);
    const [genericAssetTemplateFolder, setTemplateFolder] = useState([]);
    const [useStarterDataFlag, setUserStarterData] = useState(false);

    const formLabels = {
        itemName: intl.formatMessage({ id: 'common_item_name' }),
        book: intl.formatMessage({ id: 'common_book' }),
        pricingPoint: intl.formatMessage({ id: 'common_pricing_point' }),
        emptyPlaceholder: ' ',
        longitude: intl.formatMessage({ id: 'longitude' }),
        latitude: intl.formatMessage({ id: 'latitude' }),
        ...formLabelsPhrasing,
    };

    const initialFormValues = {
        itemName: '',
        bookId: book,
        pricingPointId: '',
        latitude: '',
        longitude: '',
        useStarterData: false,
        ...extraFieldsInitialValues,
    };

    const removeFileExtensionRegex = /\.[^/.]+$/;

    const validateItemName = (value) => {
        const trimmed = value.trim();

        if (!trimmed) {
            return intl.formatMessage({ id: 'common_forms_validation_required' }, { label: 'Item name' });
        }

        if (trimmed.length > 100) {
            return intl.formatMessage(
                { id: 'common_forms_validation_length' },
                { label: 'Item name', lengthRule: '100 characters or less' }
            );
        }
    };

    const isEmptyBook = (value) => {
        if (!value) {
            return intl.formatMessage({ id: 'common_forms_validation_required' }, { label: 'Book' });
        }
    };

    const onFormSubmit = async ({
        itemName,
        bookId,
        pricingPointId,
        longitude,
        latitude,
        selectedTemplate,
        useStarterData,
        ...values
    }) => {
        const successMessage = intl.formatMessage({ id: 'common_item_creation_success' }, { type: typeLabel });
        const arcGISError = intl.formatMessage({ id: 'common_item_arcgis_lat_long_save_failed' });

        const extraFieldsValues = reduce(
            values,
            (acc, value, key) => {
                acc[key] = value || null;
                return acc;
            },
            {}
        );

        const payload = {
            description: itemName,
            bookId,
            itemTypeId,
            pricingPointId: pricingPointId || null,
            longitude: longitude !== '' ? longitude : null,
            latitude: latitude !== '' ? latitude : null,
            ...extraFieldsValues,
        };

        const { data, arcGisIssue } = await createItem(payload);
        const newItemData = data;

        updateItems(newItemData);

        const displayToast = toast({
            message: successMessage,
            slot: arcGisIssue && <ErrorAccordion message={arcGISError} details={arcGisIssue} status={'warn'} />,
            duration: arcGisIssue && 20000,
        });

        if (useStarterData && selectedTemplate) {
            if (starterDataAutoFormSchemaCode) {
                await applyGenericAssetTemplate(starterDataAutoFormSchemaCode, newItemData.id, selectedTemplate).then(
                    () => {
                        historyPush(`${url}/${newItemData.id}`);
                        rest.onClose();
                        displayToast();
                    }
                );
            } else {
                await applyGenericAssetTemplateItemType(itemTypeId, newItemData.id, selectedTemplate).then(() => {
                    historyPush(`${url}/${newItemData.id}`);
                    rest.onClose();
                    displayToast();
                });
            }
        } else {
            historyPush(`${url}/${newItemData.id}`);
            rest.onClose();
            displayToast();
        }
    };

    const validate = (values) => {
        let longitude = validateLongLat(values.longitude, 'long', values.latitude);
        let latitude = validateLongLat(values.latitude, 'lat', values.longitude);

        return {
            ...(longitude ? { longitude } : null),
            ...(latitude ? { latitude } : null),
        };
    };

    const onUseStarterDataChange = (checked) => {
        setUserStarterData(checked);
        if (checked) {
            if (!Array.isArray(genericAssetTemplateFolder?.templates)) {
                if (starterDataAutoFormSchemaCode) {
                    getGenericAssetTemplatesAutoForm(starterDataAutoFormSchemaCode);
                } else {
                    getGenericAssetTemplatesItemType(itemTypeId);
                }
            }
        }
    };

    const getGenericAssetTemplatesAutoForm = (schemaCode) => {
        getAutoFormGenericAssetTemplates(schemaCode).then((response) => setTemplateFolder(response.data));
    };

    const getGenericAssetTemplatesItemType = (typeId) => {
        getItemTypeGenericAssetTemplates(typeId).then((response) => setTemplateFolder(response.data));
    };

    return (
        <Modal {...rest}>
            <ModalOverlay />

            <ModalContent pt={3}>
                <ModalHeader pr={12}>
                    <Heading as="h3" variant="h3" textTransform="capitalize">
                        <FormattedMessage id="common_item_creation_title" values={{ type: typeLabel }} />
                    </Heading>
                </ModalHeader>
                <ModalCloseButton mt={4} mr={3} h={6} w={6} />

                <Formik
                    enableReinitialize
                    initialValues={initialFormValues}
                    onSubmit={onFormSubmit}
                    validate={validate}
                >
                    {({ isSubmitting, errors, touched }) => (
                        <Form>
                            <ModalBody pb={10} borderBottom="1px" borderColor="border-secondary">
                                <InputFormikField
                                    name="itemName"
                                    type="text"
                                    label={formLabels.itemName}
                                    validate={validateItemName}
                                    isRequired
                                    isFastField
                                />

                                <SimpleGrid mt={4} columns={{ base: 1, md: 2 }} spacingX={6} spacingY={4}>
                                    <ComboboxFormikField
                                        id="bookId"
                                        name="bookId"
                                        label={formLabels.book}
                                        isRequired
                                        validate={isEmptyBook}
                                        options={books}
                                        valueKey="id"
                                        labelKey="name"
                                        placeholderValue=""
                                        placeholderLabel={formLabels.emptyPlaceholder}
                                        isFastField
                                    />

                                    <ComboboxFormikField
                                        id="pricingPointId"
                                        name="pricingPointId"
                                        label={formLabels.pricingPoint}
                                        info={<FormattedMessage id="common_pricing_point_empty_tooltip" />}
                                        options={pricingPoints}
                                        labelKey="description"
                                        valueKey="id"
                                        showPlaceholder={true}
                                        placeholderValue=""
                                        isFastField
                                    />

                                    {extraFields}

                                    <InputFormikField
                                        name="latitude"
                                        label={formLabels.latitude}
                                        type="number"
                                        isFastField
                                    />

                                    <InputFormikField
                                        name="longitude"
                                        label={formLabels.longitude}
                                        type="number"
                                        isFastField
                                    />
                                    {!hideStarterDataOption && (
                                        <CheckboxFormikField
                                            id="useStarterData"
                                            name="useStarterData"
                                            onChange={(e) => onUseStarterDataChange(e.target.checked)}
                                        >
                                            <FormattedMessage id="add_item_use_starter_data_checkbox" />
                                        </CheckboxFormikField>
                                    )}
                                    {useStarterDataFlag &&
                                        ((Array.isArray(genericAssetTemplateFolder?.templates) && (
                                            <ComboboxFormikField
                                                id="selectedTemplate"
                                                name="selectedTemplate"
                                                label={
                                                    <FormattedMessage id="add_item_starter_data_template_dropdown" />
                                                }
                                                options={genericAssetTemplateFolder?.templates.flatMap((x) =>
                                                    x?.fileName?.replace(removeFileExtensionRegex, '')
                                                )}
                                                showPlaceholder={true}
                                                placeholderValue=""
                                                isFastField
                                            />
                                        )) || <Skeleton />)}
                                </SimpleGrid>
                            </ModalBody>

                            <ModalFooter justifyContent="stretch" flexWrap="wrap">
                                <Box ml="auto">
                                    <Button variant="secondary" onClick={rest.onClose}>
                                        <Box as="span" textTransform="capitalize">
                                            <FormattedMessage id="common_cancel" />
                                        </Box>
                                    </Button>

                                    <Button
                                        type="submit"
                                        isDisabled={isSubmitDisabled({ errors, touched })}
                                        isLoading={isSubmitting}
                                        ml={3}
                                    >
                                        <FormattedMessage id="common_item_create_btn" />
                                    </Button>
                                </Box>
                            </ModalFooter>
                        </Form>
                    )}
                </Formik>
            </ModalContent>
        </Modal>
    );
};

export default AddItemModal;
