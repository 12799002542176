import styled from '@emotion/styled/macro';
import sidebarScrollbarCss from 'components/utils/sidebarScrollbar';
import React from 'react';

const TreeWrapper = ({ classNamePrefix, ...props }) => {
    return <Wrapper {...props} classNamePrefix={classNamePrefix ?? 'common'} />;
};

const Wrapper = styled.div`
    flex-grow: 1;
    padding: 8px 0;
    user-select: none;
    pointer-events: ${(props) => (props.isDisabled ? 'none' : 'auto')};

    ${(props) => (props.isDisplayedInModal ? '' : sidebarScrollbarCss)}

    [role='tree'],
    [role='treeitem'] {
        list-style: none;
    }

    [role='treeitem']:focus {
        outline: 0;
        color: var(--chakra-colors-text-tertiary);

        .${(props) => props.classNamePrefix}-label-icon:not(.master-node) {
            color: var(--chakra-colors-text-tertiary);
        }
    }

    [role='treeitem']:focus > div {
        position: relative;
        z-index: 1;
        border-color: var(--chakra-ring-color);
    }

    [role='treeitem'][aria-selected='true']:focus-within > div .icons-wrapper {
        display: inline-grid;
    }

    @media (min-width: 769px) {
        & [role='tree'] > [role='treeitem'] {
            padding: ${(props) => (props.isDisplayedInModal ? '0' : '0 24px')};
        }

        [role='tree'] {
            min-height: 60px;
        }
    }

    [role='treeitem'][aria-expanded='true'] {
        background: var(--chakra-colors-background-quinary);
        li > div,
        & > div {
            color: var(--chakra-colors-text-tertiary);

            .${(props) => props.classNamePrefix}-label-icon:not(.master-node):not(.portfolio-folder) {
                color: var(--chakra-colors-text-tertiary);
                path:first-of-type {
                    fill: var(--chakra-colors-text-tertiary);
                }

                path + path {
                    fill: var(--chakra-colors-text-tertiary);
                }
            }

            &:hover {
                color: var(--chakra-colors-blue-400);
                .${(props) => props.classNamePrefix}-label-icon:not(.master-node) {
                    color: var(--chakra-colors-blue-400);
                    path:first-of-type {
                        fill: var(--chakra-colors-blue-400);
                    }

                    path + path {
                        fill: var(--chakra-colors-blue-400);
                    }
                }
            }
        }
    }

    // spacing for nested nodes
    [role='treeitem'] > [role='group'] {
        margin-left: 16px;
    }
    [role='treeitem']:not([aria-expanded]) .${(props) => props.classNamePrefix}-label-wrapper.non-expandable {
        margin-left: 24px;
    }

    [role='treeitem'] > div {
        border: 1px solid transparent;
        border-radius: var(--chakra-radii-base);
        padding: 0 8px;
        color: var(--chakra-colors-text-tertiary);
        font-size: 14px;
        line-height: 1.43;
        font-weight: 400;
        cursor: pointer;

        .arrow-icon {
            color: var(--chakra-colors-gray-400);
        }

        &:hover {
            background: var(--chakra-colors-gray-100);
            color: var(--chakra-colors-blue-400);

            .${(props) => props.classNamePrefix}-label-icon:not(.master-node) {
                color: var(--chakra-colors-blue-400);
                path:first-of-type {
                    fill: var(--chakra-colors-blue-400);
                }

                path + path {
                    fill: var(--chakra-colors-blue-400);
                }
            }
            .arrow-icon {
                color: inherit;

                &.master-node {
                    color: var(--chakra-colors-green-900);
                }
            }

            .icons-wrapper {
                display: inline-grid;
            }
        }
    }

    [role='treeitem'][aria-selected] > div {
        background: var(--chakra-colors-gray-200);
        color: var(--chakra-colors-navigation-active-item-text) !important;

        .arrow-icon {
            color: var(--chakra-colors-blue-400);
        }

        .${(props) => props.classNamePrefix}-label-icon:not(.master-node):not(.portfolio-folder) {
            color: var(--chakra-colors-navigation-active-item-text) !important;
            path:first-of-type {
                fill: var(--chakra-colors-navigation-active-item-text) !important;
            }

            path + path {
                fill: var(--chakra-colors-navigation-active-item-text) !important;
            }
        }
    }

    .${(props) => props.classNamePrefix}-label-wrapper {
        display: flex;
        align-items: center;
    }

    .${(props) => props.classNamePrefix}-label-icon {
        display: inline-block;
        font-size: 24px;
        width: 1em;
        height: 1em;
        line-height: 1em;
        flex-shrink: 0;
        color: var(--chakra-colors-text-tertiary);

        &.master-node {
            color: var(--chakra-colors-green-500);

            &.folder-opened {
                path:first-of-type {
                    fill: var(--chakra-colors-green-900);
                }

                path + path {
                    fill: var(--chakra-colors-green-500);
                }
            }

            &.folder-closed {
                path {
                    fill: var(--chakra-colors-green-900);
                }

                rect {
                    fill: var(--chakra-colors-green-500);
                }
            }
        }
    }

    .${(props) => props.classNamePrefix}-label {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: inline-block;
        flex-grow: 1;
        padding: 4px 0;

        span {
            margin-left: var(--chakra-space-2);
            margin-right: var(--chakra-space-2);
        }
    }

    [role=tree] > li[aria-expanded=false]:last-child:not(:only-child) .tooltip_wrapper, /* when all root nodes are collapsed and this is the last folder but is not the only one */
    [role=tree] > li:not([aria-expanded]):last-child:not(:only-child) .tooltip_wrapper, /* when all root nodes are collapsed and this is the last item but is not the only one */
    [role=tree] li:last-child li[aria-expanded=false]:last-child .tooltip_wrapper, /* last folder in a folder that's also a last child */
    [role=tree] li:last-child li:not([aria-expanded]):last-child .tooltip_wrapper/* last item in a folder that's also a last child */ {
        transform: translate(-50%, -145%);
    }

    /* any node that is a last child, but its parent isn't a last child */
    [role='tree'] li:not(:last-child) li:not([aria-expanded='true']):last-child .tooltip_wrapper,
    [role='tree'] li:not(:last-child) li:not([aria-expanded]):last-child .tooltip_wrapper,
    [role='tree'] > li:only-child .tooltip_wrapper /* when there is only one folder */ {
        transform: translate(-50%, 145%);
    }

    [role=tree] > li[aria-expanded=false]:last-child:not(:only-child) .tooltip_children_wrapper:hover + div, /* when all root nodes are collapsed and this is the last folder but is not the only one */
    [role=tree] > li:not([aria-expanded]):last-child:not(:only-child) .tooltip_children_wrapper:hover + div, /* when all root nodes are collapsed and this is the last item but is not the only one*/
    [role=tree] li:last-child li[aria-expanded=false]:last-child .tooltip_children_wrapper:hover + div, /* last folder in a folder that's also a last child */
    [role=tree] li:last-child li:not([aria-expanded]):last-child .tooltip_children_wrapper:hover + div /* last item in a folder that's also a last child */ {
        transform: translate(-50%, -135%);
    }

    /* any node that is a last child, but its parent isn't a last child */
    [role='tree'] li:not(:last-child) li:not([aria-expanded='true']):last-child .tooltip_children_wrapper:hover + div,
    [role='tree'] li:not(:last-child) li:not([aria-expanded]):last-child .tooltip_children_wrapper:hover + div,
    [role='tree'] > li:not([aria-expanded]):only-child .tooltip_wrapper /* when there is only one folder */ {
        transform: translate(-50%, 135%);
    }
`;

export default TreeWrapper;
