import { useState, useMemo, useCallback, useEffect, useImperativeHandle, forwardRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Heading, Tabs, TabPanels, TabPanel, useDisclosure, TabList, Tab, Flex } from '@chakra-ui/react';
import styled from '@emotion/styled/macro';

import * as ThermalService from '../../services/thermals';
import { getValidRowsWithDates } from '../../services/grid';

import {
    multisortPairs,
    resourceVariablesGridKeys,
    GENERATION_ASSET_TYPE_KEY,
    resourceVariablesGroupKeys,
    resourceVariableRequiredFieldKeys,
} from '../../constants/thermal';

import useNoStayZones from './hooks/useNoStayZones';

import TabListWrapper from '../utils/TabListWrapper';
import HelpButtonComponent from 'components/utils/HelpButtonComponent';

import NoStayZonesModal from './NoStayZonesModal';
import BulkUpdate from './BulkUpdate';
import SubItemData from '../itemData/SubItemData';

import { helpAnchors } from 'constants/help';
import { FEATURE_FLAG_NAMES, useIsFeatureActive } from 'hooks/features-flags';
import { thermalTabNames as tabs } from './util/thermalHelpers';

const ResourceVariables = forwardRef(
    (
        {
            innerSelectedTab,
            onInnerTabChange,
            onRefreshTabs,
            subThermalId,
            metadata,
            onLoadingStart,
            onLoadingEnd,
            helpPageUrlItemTypeId = null,
            bulkUpdate: {
                bulkUpdateVisibility,
                bulkUpdateLoading,
                bulkUpdateFields,
                allVariedByDate,
                generateFields,
                getBulkUpdateState,
                onFieldChange,
                onToggleVariedByDate,
                onToggleAllVariedByDate,
                clearBulkUpdateState,
            },
            forceGridRefresh,
            filters,
            type,
        },
        ref
    ) => {
        const intl = useIntl();
        const noStayZonesModal = useDisclosure();
        const {
            selectedRowId,
            column: noStayZonesColumn,
            onCellKeyPress,
            closeModal,
        } = useNoStayZones(noStayZonesModal);

        const [allOperationalDates, setAllOperationalDates] = useState([]);
        const [mountedInnerTabs, setMountedInnerTabs] = useState([innerSelectedTab.index]);

        const showGenerationDerate = useIsFeatureActive(FEATURE_FLAG_NAMES.GENERATION_DERATE_VISIBLE);
        const showForcedOutageTemp = useIsFeatureActive(FEATURE_FLAG_NAMES.THERMAL_FORCED_OUTAGE_TEMP_VISIBLE);

        /*
         * Specific period columns for resource variables
         */
        const periodColumns = useMemo(() => {
            const commonProperties = {
                headerName: intl.formatMessage({ id: 'common_date_period' }),
                sort: 'asc',
                minWidth: 180,
            };

            return {
                description: {
                    field: 'description',
                    editable: false,
                    ...commonProperties,
                },
                periodId: {
                    field: 'periodId',
                    type: 'select',
                    cellEditorParams: {
                        options: allOperationalDates,
                        required: true,
                    },
                    ...commonProperties,
                },
                periodName: {
                    field: 'periodName',
                    editable: false,
                    ...commonProperties,
                },
            };
        }, [intl, allOperationalDates]);

        const getAllOperationalData = useCallback(async () => {
            const operationalDates = await ThermalService.getAllOperationalDates(subThermalId);
            setAllOperationalDates(operationalDates);
        }, [subThermalId]);

        /*
         * Grid resources
         */
        const operationalDatesGridResource = useMemo(() => {
            return {
                id: 'unit_operational_dates',
                gridKey: resourceVariablesGridKeys.OPERATIONAL_DATES_KEY,
                groupKey: resourceVariablesGroupKeys.PERIODS,
                create: (rows) => ThermalService.createSubThermalPeriods(subThermalId, rows),
                read: (pagination) =>
                    ThermalService.getOperationalDates(subThermalId, { ...pagination, endDate: filters.minEndDate }),
                update: (rows) => {
                    const validRows = getValidRowsWithDates(rows, ['startDate', 'endDate']);
                    return ThermalService.updateOperationalDates(subThermalId, validRows);
                },
                delete: (rows) =>
                    ThermalService.deleteSubThermalPeriods(
                        subThermalId,
                        rows.map((row) => row.thermalPeriodId)
                    ),
                deleteAll: () => ThermalService.deleteAllSubThermalPeriods(subThermalId),
                validate: (rows) => ThermalService.validateSubThermalPeriods(subThermalId, rows),
                getDataSourceView: (pagination) =>
                    ThermalService.getOperationalDatesDataSourceView(subThermalId, {
                        ...pagination,
                        endDate: filters.minEndDate,
                    }),
            };
        }, [subThermalId, filters.minEndDate]);

        const startupShutdownGridResource = useMemo(() => {
            return {
                id: 'unit_startup_shutdown',
                gridKey: resourceVariablesGridKeys.STARTUP_SHUTDOWN_KEY,
                groupKey: resourceVariablesGroupKeys.PERIODS,
                create: (rows) => ThermalService.createSubThermalPeriods(subThermalId, rows),
                read: (pagination) =>
                    ThermalService.getStartupShutdowns(subThermalId, { ...pagination, endDate: filters.minEndDate }),
                update: (rows) => ThermalService.updateStartupShutdowns(subThermalId, rows),
                delete: (rows) =>
                    ThermalService.deleteSubThermalPeriods(
                        subThermalId,
                        rows.map((row) => row.thermalPeriodId)
                    ),
                deleteAll: () => ThermalService.deleteAllSubThermalPeriods(subThermalId),
                validate: (rows) => ThermalService.validateSubThermalPeriods(subThermalId, rows),
                readFields: () =>
                    getBulkUpdateState(
                        subThermalId,
                        ThermalService.getStartupShutdownsState,
                        tabs[resourceVariablesGridKeys.STARTUP_SHUTDOWN_KEY]
                    ),
                getDataSourceView: (pagination) =>
                    ThermalService.getStartupShutdownsDataSourceView(subThermalId, {
                        ...pagination,
                        endDate: filters.minEndDate,
                    }),
            };
        }, [subThermalId, getBulkUpdateState, filters.minEndDate]);

        const runConstraintsGridResource = useMemo(() => {
            return {
                id: 'unit_run_constraints',
                gridKey: resourceVariablesGridKeys.RUN_CONSTRAINTS_KEY,
                groupKey: resourceVariablesGroupKeys.PERIODS,
                create: (rows) => ThermalService.createSubThermalPeriods(subThermalId, rows),
                read: (pagination) =>
                    ThermalService.getRunConstraints(subThermalId, { ...pagination, endDate: filters.minEndDate }),
                update: (rows) => ThermalService.updateRunConstraints(subThermalId, rows),
                delete: (rows) =>
                    ThermalService.deleteSubThermalPeriods(
                        subThermalId,
                        rows.map((row) => row.thermalPeriodId)
                    ),
                deleteAll: () => ThermalService.deleteAllSubThermalPeriods(subThermalId),
                validate: (rows) => ThermalService.validateSubThermalPeriods(subThermalId, rows),
                readFields: () =>
                    getBulkUpdateState(
                        subThermalId,
                        ThermalService.getRunConstraintsState,
                        tabs[resourceVariablesGridKeys.RUN_CONSTRAINTS_KEY]
                    ),
                getDataSourceView: (pagination) =>
                    ThermalService.getRunConstraintsDataSourceView(subThermalId, {
                        ...pagination,
                        endDate: filters.minEndDate,
                    }),
            };
        }, [subThermalId, getBulkUpdateState, filters.minEndDate]);

        const heatRatesGridResource = useMemo(() => {
            return {
                id: 'unit_heat_rates',
                gridKey: resourceVariablesGridKeys.HEAT_RATES_KEY,
                groupKey: resourceVariablesGroupKeys.FUELS,
                create: (rows) => ThermalService.createSubThermalFuelCosts(subThermalId, rows),
                read: (pagination) =>
                    ThermalService.getHeatRates(subThermalId, { ...pagination, endDate: filters.minEndDate }),
                update: (rows) => ThermalService.updateHeatRates(subThermalId, rows),
                delete: (rows) =>
                    ThermalService.deleteSubThermalFuelCosts(
                        subThermalId,
                        rows.map((row) => row.id)
                    ),
                deleteAll: () => ThermalService.deleteAllSubThermalFuelCosts(subThermalId),
                validate: (rows) => ThermalService.validateSubThermalFuels(subThermalId, rows),
                readFields: () =>
                    getBulkUpdateState(
                        subThermalId,
                        ThermalService.getHeatRatesState,
                        tabs[resourceVariablesGridKeys.HEAT_RATES_KEY]
                    ),
                getDataSourceView: (pagination) =>
                    ThermalService.getHeatRatesDataSourceView(subThermalId, {
                        ...pagination,
                        endDate: filters.minEndDate,
                    }),
            };
        }, [subThermalId, getBulkUpdateState, filters.minEndDate]);

        const fuelCostsGridResource = useMemo(() => {
            return {
                id: 'unit_fuel_cost',
                gridKey: resourceVariablesGridKeys.FUEL_COSTS_KEY,
                groupKey: resourceVariablesGroupKeys.FUELS,
                create: (rows) => ThermalService.createSubThermalFuelCosts(subThermalId, rows),
                read: (pagination) =>
                    ThermalService.getFuelCosts(subThermalId, { ...pagination, endDate: filters.minEndDate }),
                update: (rows) => ThermalService.updateFuelCosts(subThermalId, rows),
                delete: (rows) =>
                    ThermalService.deleteSubThermalFuelCosts(
                        subThermalId,
                        rows.map((row) => row.id)
                    ),
                deleteAll: () => ThermalService.deleteAllSubThermalFuelCosts(subThermalId),
                validate: (rows) => ThermalService.validateSubThermalFuels(subThermalId, rows),
                readFields: () =>
                    getBulkUpdateState(
                        subThermalId,
                        ThermalService.getFuelCostsState,
                        tabs[resourceVariablesGridKeys.FUEL_COSTS_KEY]
                    ),
                getDataSourceView: (pagination) =>
                    ThermalService.getFuelCostsDataSourceView(subThermalId, {
                        ...pagination,
                        endDate: filters.minEndDate,
                    }),
            };
        }, [subThermalId, getBulkUpdateState, filters.minEndDate]);

        const costEmissionsGridResource = useMemo(() => {
            return {
                id: 'unit_misc_cost',
                gridKey: resourceVariablesGridKeys.COST_EMISSIONS_KEY,
                groupKey: resourceVariablesGroupKeys.FUELS,
                create: (rows) => ThermalService.createSubThermalFuelCosts(subThermalId, rows),
                read: (pagination) =>
                    ThermalService.getCostEmissions(subThermalId, { ...pagination, endDate: filters.minEndDate }),
                update: (rows) => ThermalService.updateCostEmissions(subThermalId, rows),
                delete: (rows) =>
                    ThermalService.deleteSubThermalFuelCosts(
                        subThermalId,
                        rows.map((row) => row.id)
                    ),
                deleteAll: () => ThermalService.deleteAllSubThermalFuelCosts(subThermalId),
                validate: (rows) => ThermalService.validateSubThermalFuels(subThermalId, rows),
                readFields: () =>
                    getBulkUpdateState(
                        subThermalId,
                        ThermalService.getCostEmissionsState,
                        tabs[resourceVariablesGridKeys.COST_EMISSIONS_KEY]
                    ),
                getDataSourceView: (pagination) =>
                    ThermalService.getCostEmissionsDataSourceView(subThermalId, {
                        ...pagination,
                        endDate: filters.minEndDate,
                    }),
            };
        }, [subThermalId, getBulkUpdateState, filters.minEndDate]);

        const generationCapacityGridResource = useMemo(() => {
            return {
                id: 'unit_gen_capacity',
                gridKey: resourceVariablesGridKeys.GENERATION_CAPACITY_KEY,
                groupKey: resourceVariablesGroupKeys.GENERATION_CAPACITY,
                create: (rows) => ThermalService.createSubThermalGenerationCapacity(subThermalId, rows),
                read: (pagination) =>
                    ThermalService.getGenerationCapacity(subThermalId, {
                        ...pagination,
                        endDate: filters.minEndDate,
                    }),
                update: (rows) => ThermalService.updateGenerationCapacity(subThermalId, rows),
                delete: (rows) =>
                    ThermalService.deleteGenerationCapacity(
                        subThermalId,
                        rows.map((row) => row.id)
                    ),
                deleteAll: () => ThermalService.deleteAllGenerationCapacity(subThermalId),
                validate: (rows) => ThermalService.validateGenerationCapacity(subThermalId, rows),
                readFields: () =>
                    getBulkUpdateState(
                        subThermalId,
                        ThermalService.getGenerationCapacityState,
                        tabs[resourceVariablesGridKeys.GENERATION_CAPACITY_KEY]
                    ),
                getDataSourceView: (pagination) =>
                    ThermalService.getGenerationCapacityDataSourceView(subThermalId, {
                        ...pagination,
                        endDate: filters.minEndDate,
                    }),
            };
        }, [subThermalId, getBulkUpdateState, filters.minEndDate]);

        const forcedOutagesGridResource = useMemo(() => {
            return {
                id: 'unit_forced_outages',
                gridKey: resourceVariablesGridKeys.FORCED_OUTAGES_KEY,
                groupKey: resourceVariablesGroupKeys.FORCED_OUTAGES,
                create: (rows) => ThermalService.createSubThermalForcedOutages(subThermalId, rows),
                read: (pagination) =>
                    ThermalService.getForcedOutages(subThermalId, { ...pagination, endDate: filters.minEndDate }),
                update: (rows) => ThermalService.updateForcedOutages(subThermalId, rows),
                delete: (rows) =>
                    ThermalService.deleteForcedOutages(
                        subThermalId,
                        rows.map((row) => row.id)
                    ),
                deleteAll: () => ThermalService.deleteAllForcedOutages(subThermalId),
                validate: (rows) => ThermalService.validateForcedOutages(subThermalId, rows),
                readFields: () =>
                    getBulkUpdateState(
                        subThermalId,
                        ThermalService.getForcedOutagesState,
                        tabs[resourceVariablesGridKeys.FORCED_OUTAGES_KEY]
                    ),
                getDataSourceView: (pagination) =>
                    ThermalService.getForcedOutagesDataSourceView(subThermalId, {
                        ...pagination,
                        endDate: filters.minEndDate,
                    }),
            };
        }, [subThermalId, getBulkUpdateState, filters.minEndDate]);

        const ancillaryContributionsGridResource = useMemo(() => {
            return {
                id: 'unit_ancillaries',
                gridKey: resourceVariablesGridKeys.ANCILLARY_CONTRIBUTIONS_KEY,
                groupKey: resourceVariablesGroupKeys.ANCILLARY_CONTRIBUTION,
                create: (rows) => ThermalService.createSubThermalAncillaryContributions(subThermalId, rows),
                read: (pagination) =>
                    ThermalService.getAncillaryContributions(subThermalId, {
                        ...pagination,
                        endDate: filters.minEndDate,
                    }),
                update: (rows) => ThermalService.updateAncillaryContributions(subThermalId, rows),
                delete: (rows) =>
                    ThermalService.deleteAncillaryContributions(
                        subThermalId,
                        rows.map((row) => row.id)
                    ),
                deleteAll: () => ThermalService.deleteAllAncillaryContributions(subThermalId),
                validate: (rows) => ThermalService.validateAncillaryContributions(subThermalId, rows),
                readFields: () =>
                    getBulkUpdateState(
                        subThermalId,
                        ThermalService.getAncillaryContributionsState,
                        tabs[resourceVariablesGridKeys.ANCILLARY_CONTRIBUTIONS_KEY]
                    ),
                getDataSourceView: (pagination) =>
                    ThermalService.getAncillaryContributionsDataSourceView(subThermalId, {
                        ...pagination,
                        endDate: filters.minEndDate,
                    }),
            };
        }, [subThermalId, getBulkUpdateState, filters.minEndDate]);

        const linkedResourceGridResource = useMemo(() => {
            return {
                id: 'unit_linked_resource',
                gridKey: resourceVariablesGridKeys.LINKED_RESOURCES_KEY,
                groupKey: resourceVariablesGroupKeys.LINKED_RESOURCE,
                create: (rows) => ThermalService.createSubThermalLinkedResources(subThermalId, rows),
                read: (pagination) =>
                    ThermalService.getLinkedResources(subThermalId, { ...pagination, endDate: filters.minEndDate }),
                update: (rows) => ThermalService.updateLinkedResources(subThermalId, rows),
                delete: (rows) =>
                    ThermalService.deleteLinkedResources(
                        subThermalId,
                        rows.map((row) => row.id)
                    ),
                deleteAll: () => ThermalService.deleteAllLinkedResources(subThermalId),
                validate: (rows) => ThermalService.validateLinkedResources(subThermalId, rows),
                readFields: () =>
                    getBulkUpdateState(
                        subThermalId,
                        ThermalService.getLinkedResourcesState,
                        tabs[resourceVariablesGridKeys.LINKED_RESOURCES_KEY]
                    ),
                getDataSourceView: (pagination) =>
                    ThermalService.getLinkedResourcesDataSourceView(subThermalId, {
                        ...pagination,
                        endDate: filters.minEndDate,
                    }),
            };
        }, [subThermalId, getBulkUpdateState, filters.minEndDate]);

        const generationDerateGridResource = useMemo(() => {
            return {
                id: 'unit_generation_derate',
                gridKey: resourceVariablesGridKeys.GENERATION_DERATE_KEY,
                groupKey: resourceVariablesGroupKeys.GENERATION_DERATE,
                create: (rows) => ThermalService.createSubThermalGenerationDerate(subThermalId, rows),
                read: (pagination) =>
                    ThermalService.getGenerationDerate(subThermalId, { ...pagination, endDate: filters.minEndDate }),
                update: (rows) => ThermalService.updateGenerationDerate(subThermalId, rows),
                delete: (rows) =>
                    ThermalService.deleteGenerationDerate(
                        subThermalId,
                        rows.map((row) => row.id)
                    ),
                deleteAll: () => ThermalService.deleteAllGenerationDerate(subThermalId),
                validate: (rows) => ThermalService.validateGenerationDerate(subThermalId, rows),
                readFields: () =>
                    getBulkUpdateState(
                        subThermalId,
                        ThermalService.getGenerationDerateState,
                        tabs[resourceVariablesGridKeys.GENERATION_DERATE_KEY]
                    ),
                getDataSourceView: (pagination) =>
                    ThermalService.getGenerationDerateDataSourceView(subThermalId, {
                        ...pagination,
                        endDate: filters.minEndDate,
                    }),
            };
        }, [subThermalId, getBulkUpdateState, filters.minEndDate]);

        const forcedOutageTempGridResource = useMemo(() => {
            return {
                id: 'unit_forced_outage_temp',
                gridKey: resourceVariablesGridKeys.FORCED_OUTAGE_TEMP_KEY,
                groupKey: resourceVariablesGroupKeys.FORCED_OUTAGE_TEMP,
                create: (rows) => ThermalService.createSubThermalForcedOutageTemp(subThermalId, rows),
                read: (pagination) =>
                    ThermalService.getForcedOutageTemp(subThermalId, { ...pagination, endDate: filters.minEndDate }),
                update: (rows) => ThermalService.updateForcedOutageTemp(subThermalId, rows),
                delete: (rows) =>
                    ThermalService.deleteForcedOutageTemp(
                        subThermalId,
                        rows.map((row) => row.id)
                    ),
                deleteAll: () => ThermalService.deleteAllForcedOutageTemp(subThermalId),
                validate: (rows) => ThermalService.validateForcedOutageTemp(subThermalId, rows),
                readFields: () =>
                    getBulkUpdateState(
                        subThermalId,
                        ThermalService.getForcedOutageTempState,
                        tabs[resourceVariablesGridKeys.FORCED_OUTAGE_TEMP_KEY]
                    ),
                getDataSourceView: (pagination) =>
                    ThermalService.getForcedOutageTempDataSourceView(subThermalId, {
                        ...pagination,
                        endDate: filters.minEndDate,
                    }),
            };
        }, [subThermalId, getBulkUpdateState, filters.minEndDate]);

        /*
         * Add table rows (columns, format)
         */
        const periodsColumns = useMemo(() => {
            return [
                ...ThermalService.generateColumns(metadata, resourceVariablesGridKeys.OPERATIONAL_DATES_KEY, {
                    requiredFields: ['description', 'startDate', 'endDate'],
                }),
                ...ThermalService.generateColumns(metadata, resourceVariablesGridKeys.STARTUP_SHUTDOWN_KEY, {
                    requiredFields: ['costlessStartup', 'coldStartupTime', 'coldStartupCost', 'downTimeToCold'],
                }),
                ...ThermalService.generateColumns(metadata, resourceVariablesGridKeys.RUN_CONSTRAINTS_KEY),
            ];
        }, [metadata]);

        const fuelsColumns = useMemo(() => {
            return [
                periodColumns.periodId,
                ...ThermalService.generateColumns(metadata, resourceVariablesGridKeys.HEAT_RATES_KEY),
                ...ThermalService.generateColumns(metadata, resourceVariablesGridKeys.FUEL_COSTS_KEY).filter(
                    (column) => column.field !== 'fuelTypeId'
                ),
                ...ThermalService.generateColumns(metadata, resourceVariablesGridKeys.COST_EMISSIONS_KEY).filter(
                    (column) => column.field !== 'fuelTypeId'
                ),
            ];
        }, [metadata, periodColumns.periodId]);

        //Add Table Rows columns for Forced Outage tab
        const foDataColumns = useMemo(() => {
            return [
                periodColumns.periodId,
                ...ThermalService.generateColumns(metadata, resourceVariablesGridKeys.FORCED_OUTAGES_KEY, {
                    requiredFields: [
                        resourceVariableRequiredFieldKeys.FORCED_OUTAGES_EFOR,
                        resourceVariableRequiredFieldKeys.FORCED_OUTAGES_PERCENT_OUTAGE,
                        resourceVariableRequiredFieldKeys.FORCED_OUTAGES_OUTAGE_DURATION_MEAN,
                        resourceVariableRequiredFieldKeys.FORCED_OUTAGES_OUTAGE_STD_DEV,
                    ],
                }),
            ];
        }, [metadata, periodColumns.periodId]);

        /*
         * Multiple grid phrasing
         */
        const phrasing = useMemo(() => {
            return {
                create: {
                    header: <FormattedMessage id="common_add_rows_multiple_grids_heading" />,
                    content: <FormattedMessage id="add_rows_multi_grid_modal_description" />,
                },
                delete: {
                    header: <FormattedMessage id="common_delete_multi_grid" />,
                    content: <FormattedMessage id="delete_multi_grid_modal_description" />,
                },
                deleteAll: {
                    header: <FormattedMessage id="common_delete_multi_grid" />,
                    content: <FormattedMessage id="delete_multi_grid_modal_description" />,
                },
            };
        }, []);

        /*
         * Grid columns
         */
        const operationalDatesColumns = useMemo(() => {
            return ThermalService.generateColumns(metadata, resourceVariablesGridKeys.OPERATIONAL_DATES_KEY, {
                field: 'startDate',
                sort: 'asc',
            });
        }, [metadata]);

        const startupShutdownColumns = useMemo(() => {
            return [
                periodColumns.description,
                ...ThermalService.generateColumns(metadata, resourceVariablesGridKeys.STARTUP_SHUTDOWN_KEY, {
                    requiredFields: ['costlessStartup', 'coldStartupTime', 'coldStartupCost', 'downTimeToCold'],
                    bulkUpdateFields: bulkUpdateFields[subThermalId],
                }),
            ];
        }, [bulkUpdateFields, metadata, periodColumns.description, subThermalId]);

        const runConstraintsColumns = useMemo(() => {
            return [
                periodColumns.description,
                ...ThermalService.generateColumns(metadata, resourceVariablesGridKeys.RUN_CONSTRAINTS_KEY, {
                    bulkUpdateFields: bulkUpdateFields[subThermalId],
                }),
            ];
        }, [bulkUpdateFields, metadata, periodColumns.description, subThermalId]);

        const heatRatesColumns = useMemo(() => {
            return [
                periodColumns.periodId,
                ...ThermalService.generateColumns(metadata, resourceVariablesGridKeys.HEAT_RATES_KEY, {
                    bulkUpdateFields: bulkUpdateFields[subThermalId],
                }),
            ];
        }, [bulkUpdateFields, metadata, periodColumns.periodId, subThermalId]);

        const fuelCostsColums = useMemo(() => {
            return [
                periodColumns.periodName,
                ...ThermalService.generateColumns(metadata, resourceVariablesGridKeys.FUEL_COSTS_KEY, {
                    uneditableColumns: ['fuelTypeId'],
                    bulkUpdateFields: bulkUpdateFields[subThermalId],
                }),
                noStayZonesColumn,
            ];
        }, [bulkUpdateFields, metadata, noStayZonesColumn, periodColumns.periodName, subThermalId]);

        const costEmissionsColumns = useMemo(() => {
            return [
                periodColumns.periodName,
                ...ThermalService.generateColumns(metadata, resourceVariablesGridKeys.COST_EMISSIONS_KEY, {
                    uneditableColumns: ['fuelTypeId'],
                    bulkUpdateFields: bulkUpdateFields[subThermalId],
                }),
            ];
        }, [bulkUpdateFields, metadata, periodColumns.periodName, subThermalId]);

        const generationCapacityColumns = useMemo(() => {
            return [
                periodColumns.periodId,
                ...ThermalService.generateColumns(metadata, resourceVariablesGridKeys.GENERATION_CAPACITY_KEY, {
                    requiredFields: ['fuelTypeId'],
                    bulkUpdateFields: bulkUpdateFields[subThermalId],
                }),
            ];
        }, [bulkUpdateFields, metadata, periodColumns.periodId, subThermalId]);

        const forcedOutagesColumns = useMemo(() => {
            return [
                periodColumns.periodId,
                ...ThermalService.generateColumns(metadata, resourceVariablesGridKeys.FORCED_OUTAGES_KEY, {
                    bulkUpdateFields: bulkUpdateFields[subThermalId],
                }),
            ];
        }, [bulkUpdateFields, metadata, periodColumns.periodId, subThermalId]);

        const ancillaryContributionsColumns = useMemo(() => {
            return [
                periodColumns.periodId,
                ...ThermalService.generateColumns(metadata, resourceVariablesGridKeys.ANCILLARY_CONTRIBUTIONS_KEY, {
                    bulkUpdateFields: bulkUpdateFields[subThermalId],
                }),
            ];
        }, [bulkUpdateFields, metadata, periodColumns.periodId, subThermalId]);

        const linkedResourceColumns = useMemo(() => {
            return [
                periodColumns.periodId,
                ...ThermalService.generateColumns(metadata, resourceVariablesGridKeys.LINKED_RESOURCES_KEY, {
                    bulkUpdateFields: bulkUpdateFields[subThermalId],
                }),
            ];
        }, [bulkUpdateFields, metadata, periodColumns.periodId, subThermalId]);

        const generationDerateColumns = useMemo(() => {
            return [
                periodColumns.periodId,
                ...ThermalService.generateColumns(metadata, resourceVariablesGridKeys.GENERATION_DERATE_KEY, {
                    bulkUpdateFields: bulkUpdateFields[subThermalId],
                }),
            ];
        }, [bulkUpdateFields, metadata, periodColumns.periodId, subThermalId]);

        const forcedOutageTempColumns = useMemo(() => {
            return [
                periodColumns.periodId,
                ...ThermalService.generateColumns(metadata, resourceVariablesGridKeys.FORCED_OUTAGE_TEMP_KEY, {
                    bulkUpdateFields: bulkUpdateFields[subThermalId],
                }),
            ];
        }, [bulkUpdateFields, metadata, periodColumns.periodId, subThermalId]);

        /*
         * Refresh helper functions
         * This determins the order of the tabs
         */
        const gridResources = useMemo(() => {
            // This will be the order of the tabs
            const resources = [
                operationalDatesGridResource,
                startupShutdownGridResource,
                runConstraintsGridResource,
                heatRatesGridResource,
                fuelCostsGridResource,
                costEmissionsGridResource,
                generationCapacityGridResource,
                forcedOutagesGridResource,
                forcedOutageTempGridResource,
                ancillaryContributionsGridResource,
                linkedResourceGridResource,
                generationDerateGridResource,
            ];
            // remove resource based on feature flag
            if (!showGenerationDerate) {
                const idx = resources.findIndex((res) => res.id === 'unit_generation_derate');
                idx !== -1 && resources.splice(idx, 1);
            }
            if (!showForcedOutageTemp) {
                const idx = resources.findIndex((res) => res.id === 'unit_forced_outage_temp');
                idx !== -1 && resources.splice(idx, 1);
            }
            return resources;
        }, [
            operationalDatesGridResource,
            startupShutdownGridResource,
            runConstraintsGridResource,
            heatRatesGridResource,
            fuelCostsGridResource,
            generationCapacityGridResource,
            costEmissionsGridResource,
            forcedOutagesGridResource,
            ancillaryContributionsGridResource,
            linkedResourceGridResource,
            generationDerateGridResource,
            forcedOutageTempGridResource,
            showGenerationDerate,
            showForcedOutageTemp,
        ]);

        useEffect(() => {
            // 1. updating all operational data could be done for all grids but it's wasteful
            // only these inner tab indexes(grids) need the latest operational dates to work
            // 2. onMountInnerTab is not triggered when we load the screen for the first time
            // but we are safe here as in the first tab we don't need all operational dates
            if (innerSelectedTab.index > 2) {
                getAllOperationalData(innerSelectedTab.index);
            }
        }, [innerSelectedTab, getAllOperationalData]);

        const onMountInnerTab = useCallback(
            (index) => {
                if (!mountedInnerTabs.includes(index)) {
                    gridResources[index].readFields();

                    setMountedInnerTabs((prev) => [...prev, index]);
                }

                onInnerTabChange({ index: index, groupKey: gridResources[index]?.groupKey });
            },
            [gridResources, mountedInnerTabs, onInnerTabChange]
        );

        const onGridDataChange = useCallback(() => {
            mountedInnerTabs.forEach((index) => {
                // Opеrational Dates tab has no bulk fields and is missing readFields in gridResource
                if (typeof gridResources[index].readFields === 'function') {
                    gridResources[index].readFields();
                }
            });

            clearBulkUpdateState();
        }, [gridResources, mountedInnerTabs, clearBulkUpdateState]);

        const onUploadChange = useCallback(() => {
            onRefreshTabs();
            onGridDataChange();
        }, [onRefreshTabs, onGridDataChange]);

        useImperativeHandle(
            ref,
            () => {
                return {
                    onUploadChange,
                };
            },
            [onUploadChange]
        );

        const resourceConfig = useCallback(
            (id) => {
                switch (id) {
                    case 'unit_operational_dates':
                        return {
                            helpAnchor:
                                type === GENERATION_ASSET_TYPE_KEY
                                    ? helpAnchors.THERMAL_OPERATIONAL_DATES
                                    : helpAnchors.DSM_OPERATIONAL_DATES,
                            includeBulkUpdate: false,
                            phrasing: phrasing,
                            columns: operationalDatesColumns,
                            addDataColumns: periodsColumns,
                            primaryKeyName: 'thermalPeriodId',
                        };
                    case 'unit_startup_shutdown':
                        return {
                            helpAnchor: helpAnchors.THERMAL_STARTUP_SHUTDOWN,
                            includeBulkUpdate: true,
                            phrasing: phrasing,
                            columns: startupShutdownColumns,
                            addDataColumns: periodsColumns,
                            primaryKeyName: 'thermalPeriodId',
                        };
                    case 'unit_run_constraints':
                        return {
                            helpAnchor:
                                type === GENERATION_ASSET_TYPE_KEY
                                    ? helpAnchors.THERMAL_RUN_CONSTRAINTS
                                    : helpAnchors.DSM_RUN_CONSTRAINTS,
                            includeBulkUpdate: true,
                            phrasing: phrasing,
                            columns: runConstraintsColumns,
                            addDataColumns: periodsColumns,
                            primaryKeyName: 'thermalPeriodId',
                        };
                    case 'unit_heat_rates':
                        return {
                            helpAnchor:
                                type === GENERATION_ASSET_TYPE_KEY
                                    ? helpAnchors.THERMAL_HEAT_RATES
                                    : helpAnchors.DSM_HEAT_RATES,
                            includeBulkUpdate: true,
                            phrasing: phrasing,
                            columns: heatRatesColumns,
                            addDataColumns: fuelsColumns,
                            primaryKeyName: 'id',
                        };
                    case 'unit_fuel_cost':
                        return {
                            helpAnchor:
                                type === GENERATION_ASSET_TYPE_KEY
                                    ? helpAnchors.THERMAL_FUEL_COST_FUEL_SWITCHING
                                    : helpAnchors.DSM_FUEL_COST_FUEL_SWITCHING,
                            includeBulkUpdate: true,
                            phrasing: phrasing,
                            onCellKeyPress: onCellKeyPress,
                            columns: fuelCostsColums,
                            addDataColumns: fuelsColumns,
                            primaryKeyName: 'id',
                        };
                    case 'unit_misc_cost':
                        return {
                            helpAnchor:
                                type === GENERATION_ASSET_TYPE_KEY
                                    ? helpAnchors.THERMAL_MISC_COSTS_EMISSIONS
                                    : helpAnchors.DSM_MISC_COSTS_EMISSIONS,
                            includeBulkUpdate: true,
                            phrasing: phrasing,
                            columns: costEmissionsColumns,
                            addDataColumns: fuelsColumns,
                            primaryKeyName: 'id',
                        };
                    case 'unit_gen_capacity':
                        return {
                            helpAnchor:
                                type === GENERATION_ASSET_TYPE_KEY
                                    ? helpAnchors.THERMAL_GENERATION_CAPACITY
                                    : helpAnchors.DSM_GENERATION_CAPACITY,
                            includeBulkUpdate: true,
                            columns: generationCapacityColumns,
                            addDataColumns: generationCapacityColumns,
                            primaryKeyName: 'id',
                        };
                    case 'unit_forced_outages':
                        return {
                            helpAnchor:
                                type === GENERATION_ASSET_TYPE_KEY
                                    ? helpAnchors.THERMAL_FORCED_OUTAGES
                                    : helpAnchors.DSM_FORCED_OUTAGES,
                            includeBulkUpdate: true,
                            columns: forcedOutagesColumns,
                            addDataColumns: foDataColumns, //Includes the required fields for Add Data Rows modal
                            primaryKeyName: 'id',
                        };
                    case 'unit_ancillaries':
                        return {
                            helpAnchor:
                                type === GENERATION_ASSET_TYPE_KEY
                                    ? helpAnchors.THERMAL_ANCILLARY_CONTRIBUTIONS
                                    : helpAnchors.DSM_ANCILLARY_CONTRIBUTIONS,
                            includeBulkUpdate: true,
                            columns: ancillaryContributionsColumns,
                            addDataColumns: ancillaryContributionsColumns,
                            primaryKeyName: 'id',
                        };
                    case 'unit_linked_resource':
                        return {
                            helpAnchor:
                                type === GENERATION_ASSET_TYPE_KEY
                                    ? helpAnchors.THERMAL_LINKED_RESOURCE
                                    : helpAnchors.DSM_LINKED_RESOURCE,
                            includeBulkUpdate: true,
                            columns: linkedResourceColumns,
                            addDataColumns: linkedResourceColumns,
                            primaryKeyName: 'id',
                        };
                    case 'unit_generation_derate':
                        return {
                            helpAnchor: null,
                            includeBulkUpdate: true,
                            columns: generationDerateColumns,
                            addDataColumns: generationDerateColumns,
                            primaryKeyName: 'id',
                        };
                    case 'unit_forced_outage_temp':
                        return {
                            helpAnchor: null,
                            includeBulkUpdate: true,
                            columns: forcedOutageTempColumns,
                            addDataColumns: forcedOutageTempColumns,
                            primaryKeyName: 'id',
                        };
                    default:
                        return null;
                }
            },
            [
                operationalDatesColumns,
                startupShutdownColumns,
                runConstraintsColumns,
                heatRatesColumns,
                fuelCostsColums,
                costEmissionsColumns,
                generationCapacityColumns,
                forcedOutagesColumns,
                foDataColumns,
                ancillaryContributionsColumns,
                linkedResourceColumns,
                generationDerateColumns,
                forcedOutageTempColumns,
                periodsColumns,
                fuelsColumns,
                phrasing,
                onCellKeyPress,
                type,
            ]
        );

        return (
            <>
                <Tabs
                    isLazy
                    isManual
                    lazyBehavior="unmount"
                    variant="badges"
                    index={innerSelectedTab.index}
                    onChange={onMountInnerTab}
                >
                    <InnerTabList>
                        <TabListWrapper currentTab={innerSelectedTab.index} tabCount={10}>
                            {gridResources?.map((resource, idx) => {
                                const isPrevResInGroup = ThermalService.isPreviousResourceInGroup(gridResources, idx);
                                const isNextResInGroup = ThermalService.isNextResourceInGroup(gridResources, idx);
                                return (
                                    <Flex
                                        key={resource.id}
                                        bg="gray.200"
                                        p="10px"
                                        borderLeftRadius={isPrevResInGroup ? '' : '4px'}
                                        borderRightRadius={isNextResInGroup ? '' : '4px'}
                                        mr={isNextResInGroup ? '' : '4px'}
                                    >
                                        <Tab>
                                            <FormattedMessage id={resource.id} />
                                        </Tab>
                                    </Flex>
                                );
                            })}
                        </TabListWrapper>
                    </InnerTabList>

                    <TabPanels>
                        {gridResources?.map((resource, idx) => {
                            const config = resourceConfig(resource?.id);
                            const thermalTabKey = resource?.gridKey;
                            let options = {};

                            //Custom options for resource variables.
                            switch (thermalTabKey) {
                                case resourceVariablesGridKeys.FUEL_COSTS_KEY:
                                case resourceVariablesGridKeys.COST_EMISSIONS_KEY: {
                                    options = {
                                        uneditableColumns: ['fuelTypeId'],
                                    };
                                    break;
                                }

                                case resourceVariablesGridKeys.GENERATION_CAPACITY_KEY: {
                                    options = {
                                        requiredFields: ['fuelTypeId'],
                                    };
                                    break;
                                }

                                case resourceVariablesGridKeys.FORCED_OUTAGES_KEY: {
                                    /*Adds required fields for Forced Outages. This get passed to BulkUpdateFields component
                                        which makes a bulk update field required.*/
                                    options = {
                                        requiredFields: [
                                            resourceVariableRequiredFieldKeys.FORCED_OUTAGES_EFOR,
                                            resourceVariableRequiredFieldKeys.FORCED_OUTAGES_PERCENT_OUTAGE,
                                            resourceVariableRequiredFieldKeys.FORCED_OUTAGES_OUTAGE_DURATION_MEAN,
                                            resourceVariableRequiredFieldKeys.FORCED_OUTAGES_OUTAGE_STD_DEV,
                                        ],
                                    };
                                    break;
                                }
                                default:
                                    break;
                            }

                            return (
                                <TabPanel key={resource?.id}>
                                    <Heading as="h3" variant="h3" textTransform="capitalize" mt={2}>
                                        <FormattedMessage id={resource?.id} />
                                        {config?.helpAnchor && (
                                            <HelpButtonComponent
                                                helpAnchor={config?.helpAnchor}
                                                itemTypeId={helpPageUrlItemTypeId}
                                            />
                                        )}
                                    </Heading>
                                    {config?.includeBulkUpdate &&
                                        bulkUpdateVisibility[subThermalId]?.[tabs[thermalTabKey]] && (
                                            <BulkUpdate
                                                isLoading={bulkUpdateLoading}
                                                fields={generateFields(subThermalId, metadata, thermalTabKey, options)}
                                                allVariedByDate={allVariedByDate[subThermalId]?.[tabs[thermalTabKey]]}
                                                onFieldChange={(name, value) =>
                                                    onFieldChange(subThermalId, name, value, tabs[thermalTabKey])
                                                }
                                                onToggleVariedByDate={(fieldName) =>
                                                    onToggleVariedByDate(subThermalId, fieldName, tabs[thermalTabKey])
                                                }
                                                onToggleAllVariedByDate={() =>
                                                    onToggleAllVariedByDate(subThermalId, tabs[thermalTabKey])
                                                }
                                            />
                                        )}
                                    <SubItemData
                                        tab={`${idx + 1}`}
                                        name={thermalTabKey}
                                        onLoadingStart={onLoadingStart}
                                        onLoadingEnd={onLoadingEnd}
                                        gridResource={resource}
                                        onCellKeyPress={config?.onCellKeyPress}
                                        columns={config?.columns}
                                        addDataColumns={config?.addDataColumns}
                                        phrasing={config?.phrasing}
                                        multisortPairs={idx > 0 ? multisortPairs : undefined}
                                        onGridDataChange={onGridDataChange}
                                        shouldRefreshGridData={forceGridRefresh}
                                        primaryKeyName={config?.primaryKeyName}
                                    />
                                </TabPanel>
                            );
                        })}
                    </TabPanels>
                </Tabs>
                {noStayZonesModal.isOpen && (
                    <NoStayZonesModal rowId={selectedRowId} subThermalId={subThermalId} onClose={closeModal} />
                )}
            </>
        );
    }
);

const InnerTabList = styled(TabList)`
    margin: 0 -24px;

    @media (min-width: 769px) {
        margin: 0 -30px;
    }
`;

export default ResourceVariables;
