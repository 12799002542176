import React from 'react';
import { format, utcToZonedTime } from 'date-fns-tz';
import { Flex, Text } from '@chakra-ui/react';
import styled from '@emotion/styled/macro';

export default function FilterIndicationHelper({ date }) {
    const formattedDate = format(utcToZonedTime(new Date(date), 'UTC'), 'Pp', { timeZone: 'UTC' });
    return (
        <>
            {date && (
                <Flex alignItems="center">
                    <IndicatorIcon />
                    <Text fontSize="xs" ml={2}>
                        Filtered from {formattedDate}
                    </Text>
                </Flex>
            )}
        </>
    );
}

const IndicatorIcon = styled.div`
    background: var(--chakra-colors-blue-400);
    height: 6px;
    width: 6px;
    border-radius: var(--chakra-radii-md);
`;
